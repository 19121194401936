<template>
  <div class="search-local-list">
    <clip-list
      :items="clips"
      show-delete
      :auto-load="clips.length > 0"
      @loadMore="doQuery('more')"
      :loading="innerLoading || !!loading"
      @tapIndex="tapIndex"
      @deleteIndex="deleteIndex"
      @editDescIndex="editDescIndex"
    />
  </div>
</template>

<script>
import ClipList from "../../components/ClipList.vue";
import _ from "lodash";

import {
  deleteClips,
  popupSelectClips,
  queryClips,
  editClip
} from "../../model/clip/common";

export default {
  components: { ClipList },
  name: "ClipListSearch",
  props: {
    query: String,
  },
  data() {
    return {
      clips: [],
      loading: 0,
      innerLoading: false
    };
  },
  watch: {
    query(v) {
      this.doQuery()
    },
    loading (v) {
      this.$emit('showLoading', !!v)
    }
  },
  activated () {
    this.clips.splice(0)
  },
  deactivated () {
    this.$emit('showLoading', false)
    this.clips.splice(0)
  },
  methods: {
    tapIndex(i) {
      popupSelectClips([this.clips[i].id]);
    },
    deleteIndex(i) {
      deleteClips([this.clips[i]]);
      this.clips.splice(i, 1)
    },
    editDescIndex ({index, desc}) {
      let clip = this.clips[index]
      editClip(clip, { desc })
      clip.desc = desc
      this.clips.splice(index, 1, clip)
    },
    doQuery (more) {
      this.innerLoading = true
      this.onQuery(more)
    },
    onQuery: _.debounce(function (more) {
      this.loading += 1
      queryClips({
        query: this.query,
        skip: more && this.clips.length || 0,
      }).then((res) => {
        let base = more ? this.clips : []
        this.clips = [].concat(base, res)
        console.log('loading res: ', res)
        setTimeout(() => {
          this.loading -= 1
          if (this.loading === 0) this.innerLoading = false
        }, 500);
      });
    }, 300),
  },
};
</script>