<template>
    <div class="d-block flex-row-reverse pl-4 pr-2" style="padding-top: 14px;">

        <v-tooltip left color="cblack" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
        <v-btn class="pl-4 pr-4" plain small v-if="!hideCopy" :ripple="false" @click.stop="$emit('copyIndex', index)" v-bind="attrs" v-on="on">
            <v-icon small class="grey--text text--darken-1">mdi-clipboard-outline</v-icon>
        </v-btn>
        </template>
        <span>选择 [↑↓]<br/>复制 [Enter]</span>
        </v-tooltip>
        
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                <v-btn small plain :ripple="false" v-bind="attrs" v-on="on" :class="{
                    'white': hover
                }">
                    <v-icon small v-if="vuex_clipsUploaded.indexOf(item.id) > -1" class="primary--text">mdi-dots-vertical-circle</v-icon>
                    <v-icon small class="grey--text" v-else>mdi-dots-vertical</v-icon>
                </v-btn>
                </v-hover>
            </template>
            <v-list>
                <!-- 云同步 -->
                <v-list-item v-if="!hideCloud" @click="$emit('cloudIndex', index)">
                    <v-list-item-title v-if="vuex_clipsUploaded.indexOf(item.id) > -1" class="primary--text">已同步</v-list-item-title>
                    <v-list-item-title v-else-if="vuex_clipsUploading.indexOf(item.id) > -1">同步中...</v-list-item-title>
                    <v-list-item-title v-else>云同步[U]</v-list-item-title>
                </v-list-item>
                <!-- 修改备注 -->
                <v-list-item v-if="!hideEditDesc" @click="editDescPopup = true; desc = item.desc">
                    <v-list-item-title>修改备注</v-list-item-title>
                </v-list-item>
                <!-- 删除 -->
                <v-list-item v-if="showDelete" @click="$emit('deleteIndex', index)">
                    <v-list-item-title>删除[Del]</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        

        <v-dialog
            v-model="editDescPopup"
            max-width="500px"
        >
            <v-card>
                <v-card-title>修改备注</v-card-title>
                <v-card-subtitle class="mt-n3">备注可用于搜索云端记录，不会参与搜索本地记录。</v-card-subtitle>
                <v-card-text class="mt-n2 pt-0">
                    <v-form @submit.prevent="submitDesc">
                    <v-text-field
                        v-model="desc"
                        autocomplete="off"
                        persistent-placeholder
                        prepend-inner-icon="mdi-pound-box"
                        hide-details=""
                        aria-hidden=""
                        outlined
                    />
                    <div class="d-flex mt-5">
                        <v-spacer/>
                        <v-btn text @click="editDescPopup = false">取消</v-btn>
                        <v-btn class="primary ml-3" @click="submitDesc">保存</v-btn>
                    </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    inject: [
        'hideCopy',
        'hideCloud',
        'showDelete',
        'hideEditDesc',
    ],
    props: {
        index: Number,
        item: Object,
    },
    data () {
        return {
            desc: '',
            editDescPopup: false,
        }
    },
    methods: {
        submitDesc () {
            this.editDescPopup = false
            this.$emit('editDescIndex', {
                index: this.index,
                desc: this.desc,
            })
        },
    }
}
</script>