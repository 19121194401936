import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '../styles/vars.scss';
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
          light: {
            primary: colors.green.darken1,
            warning: colors.orange.darken1,
            success: colors.green.darken1,
            error: colors.red.accent2,
            cblack: colors.grey.darken4,
            grey: colors.grey,
          }
        }
    },
    breakpoint: {
        thresholds: {
            xs: 400,
            sm: 600,
            md: 900,
            lg: 1280,
        },
        scrollBarWidth: 24,
        mobileBreakpoint: 380,
    }
});
