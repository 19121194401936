import { CONTENT_TP, CLIP_TP } from "../constants"
import $fs from "@/store/fast";
import _ from 'lodash'
import Mime from "mime-types";

let db = $fs.db.db

// 筛选掉不添加的
async function filterToSave(clips) {
    let res = []
    for (let index = clips.length - 1; index > -1; index--) {
        const c = clips[index];
        // LOG 不保存
        if (c.tp == CLIP_TP.LOG) continue
        // 能保存的格式
        c.contentTp = c.contentTp || CONTENT_TP.TEXT
        if ([
            CONTENT_TP.TEXT,
            CONTENT_TP.HTML,
            CONTENT_TP.IMAGE,
        ].indexOf(c.contentTp) < 0) continue
        // 修改
        let o = JSON.parse(JSON.stringify(c))
        delete o.logTp
        // 图片的保存操作
        if (o.contentTp == CONTENT_TP.IMAGE) {
            let filename = await saveFileLocal(o)
            if (!filename) continue
            o.fileUrl = filename
            o.data = []
            o.raw = ''
        }
        res.push(o)
    }
    return res
}

function getFileUrl(clip) {
    let matches = clip.raw.match(/^data:([A-Za-z-+/]+);base64,(.+)$/)
    let mt = matches[1] || ''
    let ext = Mime.extension(mt)
    return clip.id + '.' + ext
}

async function saveFileLocal (clip) {
    if (!$fs.desk) return
    let matches = clip.raw.match(/^data:([A-Za-z-+/]+);base64,(.+)$/)
    let mt = matches[1] || ''
    let b64data = matches[2]
    let ext = Mime.extension(mt)
    let filename = clip.id + '.' + ext
    let saveResError = await $fs.desk.writeClipFile(filename, b64data)
    if (saveResError) return ''
    return filename
}

// 修改clip
async function editClip(clip, d) {
    // 目前只改clip
    let saved = await db.get(clip.id)
    let change = Object.assign({}, saved, d)
    await db.put(change)
}

// 删除Clip
async function deleteClip(clip, errors) {
    errors = errors || []
    let cid = clip.id
    try {
        let saved = await db.get(cid)
        let delres = await db.remove(saved)
        if (delres.error) errors.push(delres)
        // 如果有客户端，试着强行删除
        if ($fs.desk) {
            try {
                let fileUrl = getFileUrl(clip)
                $fs.desk.deleteClipFile(fileUrl)
            } catch (err) {/**/}
        } 
    } catch (err) {
        errors.push({
            name: 'delete error',
            message: err,
            clipId: cid
        })
    }
}

// 删除多个Clip
async function deleteClips(clips) {
    let errors = []
    await Promise.allSettled(clips.map(x=>deleteClip(x, errors)))
    return errors
}

async function deleteAllClips() {
    let resget = await db.allDocs({
        startkey: 'clip-',
        endkey: 'clip-z',
    })
    await Promise.allSettled(resget.rows.map(x=>db.remove(x.id, x.value.rev)))
    if ($fs.desk) $fs.desk.clearClipFiles()
}

async function addClips(cps) {
    let clips =  await filterToSave(cps)
    clips = clips.map(x=>{
        x._id = x.id
        return x
    })
    let errors = []
    try {
        let res = await db.bulkDocs(clips)
        for (const x of res) if (x.error) errors.push(x)
    } catch (error) {
        errors.push({
            name: 'clips add error',
            message: error
        })
    }
    printDb(errors)
    return errors
}

async function printDb(errors) {
    let res = await db.allDocs({
        startkey: 'clip-z',
        endkey: 'clip-',
        descending: true,
        include_docs: true,
    })
    if (errors && errors.length > 0) console.log('errors: ', errors)
    console.log('db: ', res.rows)
    return res.rows
}

async function loadClips(onClip) {
    // id去掉最后的z
    let k = onClip && onClip.id && onClip.id.substr(0, onClip.id.length -1)
    let res = await db.allDocs({
        startkey: k,
        endkey: 'clip-',
        limit: 12,
        descending: true,
        include_docs: true,
    })
    return res.rows.map(x=>x.doc)
}

async function queryClips({query, skip}) {
    if (!query || query.length < 2) return []
    let res = await db.find({
        selector: {
            text: { $regex: RegExp(_.escapeRegExp(query), "i") },
            _id: { $gt: 'clip-', $lt: 'clip-z' },
        },
        sort: [{'_id': 'desc'}],
        limit: 12,
        skip: skip || 0,
        include_docs: true,
        use_index: 'clip-query-index',
    })
    return res.docs
}

export default {
    deleteClips,
    loadClips,
    addClips,
    deleteAllClips,
    queryClips,
    editClip,
}