import { mapGetters, mapState } from "vuex";
import fstore from '../store/fast'

let $mgbStoreKey = []
try {
    $mgbStoreKey = fstore.state ? Object.keys(fstore.state) : []
} catch (error) {
    // 
}

let $mgbGetterKey = []
try {
    $mgbGetterKey = fstore.getters ? Object.keys(fstore.getters) : []
} catch (error) {
    // 
}

export default {
    computed: {
        ...mapState($mgbStoreKey),
        ...mapGetters($mgbGetterKey),
    },
}