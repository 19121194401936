export const initClientStates = (lifeData)=>{
    return {
        // 所在客户端信息
        client_info: {},
    }
}

export const clientGetters = {
    // mac客户端自定义顶部栏，页面向上伸出
    client_insetTitleBar: (state)=>{
        return state.client_info.platform === 'darwin'
    },
}