let Base = require('./common/base')
import { sendAction } from "../../store/query";
import { CONTENT_TP, USER_LEVEL } from "../constants";
import Compressor from "compressorjs";
import { readBase64ToBlob, readBlobToBase64 } from "../clip/base";


class OcrBaidu extends Base.Action {
    constructor () {
        super(
            'ocrBaidu',
            '识别图片中的文字',
            '识别图片中的文字并输出'
        )
        this.inputTps = [CONTENT_TP.IMAGE]
        this.outputTps = [CONTENT_TP.TEXT]
        this.userLevel = USER_LEVEL.LOGIN
    }
    async run({input}) {
        let blob = await readBase64ToBlob(input.raw)
        let blobRes = await compressImage(blob)
        let b64res = await readBlobToBase64(blobRes)
        if (b64res.length > 1024 * 1024 * 3.9) throw '图片过大，请使用小点的图片。'
        let idx64 = b64res.indexOf(';base64,')
        let img64 = b64res.substr(idx64 + 8)
        let res = await sendAction('ocrBaidu', {
            img64,
        }, -1)
        return {
            contentTp: CONTENT_TP.TEXT,
            raw: res.text,
        }
    }
}

function compressImage(blob) {
    return new Promise((resovle, reject)=>{
        let d = {
            quality: 0.8,
            maxHeight: 4000,
            maxWidth: 4000,
            mimeType: 'image/jpeg',
            success: (res)=>{resovle(res)},
            error: (err)=>{reject(err.message)}
        }
        new Compressor(blob, d)
    })
}

module.exports = new OcrBaidu()