let Base = require('./common/base')
import Compressor from "compressorjs";
import { readBase64ToBlob, readBlobToBase64 } from "../clip/base";

class ImageCompress extends Base.Action {
    constructor () {
        super(
            'imageCompress',
            '图片压缩、尺寸修改、格式转换',
            '修改图片的尺寸、压缩大小、修改格式。',
        )
        this.tp = Base.TP.ACTION
        this.inputTps = [Base.CONTENT_TP.IMAGE]
        this.outputTps = [Base.CONTENT_TP.IMAGE]
        this.args = [{
            key: 'compressQuility',
            placeholder: '0.8',
            name: '压缩质量',
            desc: '默认0.8。请输入0-1之间的值，在0.7-0.8之间的压缩性价比最高。',
        }, {
            key: 'exportFormat',
            name: '输出格式',
            placeholder: 'JPEG',
            desc: '默认为JPEG。仅支持PNG和JPEG。PNG格式压缩能力有限，选择JPEG可以压缩得更小。'
        }, {
            key: 'exportMaxWidth',
            name: '限制最大宽度',
            desc: '默认不限制与原图相同',
        }, {
            key: 'exportMaxHeight',
            name: '限制最大高度',
            desc: '默认不限制与原图相同',
        }]
    }
    async run({info, input, gc}) {
        let q = info.args.compressQuility || '0.8'
        try {
            q = parseFloat(q)
            if (q < 0.1) q = 0.1
            if (q > 1) q = 1
        } catch (error) {
            q = 1
        }
        let mw = info.args.exportMaxWidth
        let mh = info.args.exportMaxHeight
        try {
            mw = parseFloat(mw)
            mh = parseFloat(mh)
            if (mw < 0) mw = 0
            if (mh < 0) mh = 0
        } catch (error) {
            mw = 0
            mh = 0
        }
        let format = info.args.exportFormat || 'jpeg'
        let blob = await readBase64ToBlob(input.raw)
        let blobRes = await doCompress({
            blob,
            quality: q,
            maxHeight: mh,
            maxWidth: mw,
            format
        })
        let b64res = await readBlobToBase64(blobRes)
        return {
            contentTp: Base.CONTENT_TP.IMAGE,
            raw: b64res,
            text: input.text || ''
        }
    }
}

function doCompress({blob, quality, maxHeight, maxWidth, format}) {
    return new Promise((resovle, reject)=>{
        let d = {quality: quality || 1}
        if (maxHeight) d.maxHeight = maxHeight
        if (maxWidth) d.maxWidth = maxWidth
        // 图片格式，默认为JPEG
        format = (format || '').toLowerCase()
        if (format.indexOf('png') >= 0) format = 'image/png'
        else if ((format.indexOf('jpeg') >= 0) || (format.indexOf('jpg') >= 0)) format = 'image/jpeg'
        else format = 'image/jpeg'
        if (format) d.mimeType = format
        // 输出
        d.success = (res)=>{
            resovle(res)
        }
        d.error = (err)=>{
            reject(err.message)
        }
        new Compressor(blob, d)
    })
}

module.exports = new ImageCompress()