let Base = require('./common/base')
let Utils = require('./common/utils')
import { addNote } from "../clip/common";

class WriteNoteHtml extends Base.Action {
    constructor () {
        super(
            'writeNoteHtml',
            '输出HTML笔记',
            '将文字或HTML输出为HTML笔记。'
        )
        this.inputTps = [Base.CONTENT_TP.TEXT, Base.CONTENT_TP.HTML, Base.CONTENT_TP.ARRAY]
        this.outputTps = [Base.CONTENT_TP.HTML]
        this.tp = Base.TP.NOTE
    }
    async run({input, originInput}) {
        let raw = Utils.inputToArray(input)
        for (const x of raw) runOne(x, originInput)
        return input
    }
}

function runOne (input, originInput) {
    let raw = input.raw || input.text || ''
    addNote({
        targetClip: originInput,
        raw: raw,
        contentTp: Base.CONTENT_TP.HTML,
        text: input.text
    })
}

module.exports = new WriteNoteHtml()