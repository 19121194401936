import $fs from "../../store/fast";
import $router from "../../router";
import { getFlows } from "../../store/query";
import { USER_LEVEL } from "../../model/constants";

function isMine(f) {
    let fid = f.id
    let cid = f.creator_id
    // 如果没有创建id，或者没有创建者信息的，就肯定是自己的
    if (!fid || !cid) return true
    let myid = $fs.getters.vuex_login
    // 有我的id且与创建者相同时，是自己的
    return myid && myid == cid
}

function isEnableEdit(flow) {
    // 如果是新创建的，也能改
    if (!flow.id) return true
    let configs = flow.configs || {}
    let p = configs.isPrivate
    // 没有设置隐私的能改
    if (!p) return true
    // 必须自己的才能改
    if (!isMine(flow)) return false
    let myid = $fs.getters.vuex_login
    // 设置隐私，且隐私设置者是自己，才能改。
    return myid && myid === p.usero
}

// 检查是否可以保存
async function isEnableSave (f, isSaveAs) {
    if (!$fs.getters.vuex_login) {
        $fs.toast('请先登录。', {
            color: 'error'
        })
        return false
    }
    // 名称不可太短
    if (!f.name || f.name.length < 2) {
        $fs.toast('名称过短，请修改。', {
            color: 'error'
        })
        return false
    }
    if (f.actions.length === 0) {
        $fs.toast('请添加至少1个动作', {
            color: 'error'
        })
        return false
    }
    if (isSaveAs) {
        // 另存为时，不得与原来的同名
        let fold = await getFlows([f.id])
        fold = fold[0]
        if (f.name === fold.name) {
            $fs.toast('请先修改名称，另存为时，不可与原名称相同。', {
                color: 'error'
            })
            return false
        }
    }
    // 如果是自己的而且已经储存，那直接可以保存
    // 新创建的，或者要另存为的
    if (!f.id || !isMine || isSaveAs) {
        let flows = await getFlows($fs.state.vuex_flowHistory)
        let myid = $fs.getters.vuex_login
        let myFlows = flows.filter(x=>!x.creator_id || (x.creator_id == myid))
        let maxCount = 50
        // todo
        switch ($fs.getters.vuex_userLevel) {
            case USER_LEVEL.GUEST:
                maxCount = 3
                break
            case USER_LEVEL.LOGIN:
                maxCount = 10
                break
            case USER_LEVEL.BASIC:
                maxCount = 30
                break
            default:
                maxCount = 50
        }
        let isEnable = myFlows.length < maxCount
        if (!isEnable) {
            $fs.modal({
                title: '权限不足',
                text: '你已经无法拥有更多工作流，请提升权限。',
                confirm: ()=>{this.$router.push('/user/plan')},
                confirmText: '查看权限',
                color: 'error',
            })
            return false
        }
    }
    return true
}

async function isAccess(flowId) {
    let flow = null
    try {
        let flows = await getFlows([flowId])
        if (flows[0]) flow = JSON.parse(JSON.stringify(flows[0]))
    } catch (err) {/**/}
    // 没有flow
    if (!flow
        // flow没作者，且不在我的历史列表里
        || (!flow.creator_id && $fs.state.vuex_flowHistory.indexOf(flow.id) === -1)
        // flow私密，且作者不是我
        || ((flow.status == 0) && (flow.creator_id && flow.creator_id != $fs.getters.vuex_login))
    ) {
        $fs.modal({
            title: '无法访问',
            text: '没有找到此条工作流，或者没有权限访问，请返回。',
            confirm: ()=>{ $router.go(-1) },
            hideCancel: true,
        })
        return null
    }
    return flow
}

async function dangerCheck (fid) {
    let flow = await getFlows([fid])
    flow = flow[0]
    // 自己的安全
    if (isMine(flow)) return
    // 组织验证过的安全
    if ((flow.author && flow.author.pro && flow.author.pro.level || 0) >= 100) return console.log(10088)
    // 如果曾经通过的时间 > 修改的时间，则通过
    let passedTs = $fs.state.vuex_flowDangerPass[flow.id]
    if (passedTs > (flow.updated_at_ts || 0)) return
    let dangers = []
    for (const index in flow.actions) {
        let action = flow.actions[index]
        if (action.aid === 'runEval') {
            let code = action.args.jsEvalCode
            dangers.push({
                title: '自定义JavaScript脚本',
                desc: '请检查作者对此动作的备注和脚本内容，若你无法信任，请点击停止。',
                dangerDesc: action.desc,
                content: code,
                actionIndex: index
            })
        }
        if (action.aid === 'urlPost') {
            dangers.push({
                title: '上传至服务器',
                dangerDesc: action.desc,
                desc: '动作将数据上传至作者服务器进行处理，请检查作者对此动作的说明，若你无法信任，请点击停止。',
                actionIndex: index
            })
        }
    }
    if (dangers.length === 0) return
    return await showDangerPopup (flow, dangers)
}

// 弹出风险提示弹窗
function showDangerPopup (flow, dangers) {
    return new Promise((resolve, reject)=>{
        $fs.vuex('vuex_flowDangerPopup', {
            flowId: flow.id,
            flow: flow,
            dangers: dangers,
            confirm: ()=>{
                $fs.vuex('vuex_flowDangerPopup', false)
                $fs.vuex('vuex_flowDangerPass.' + flow.id, Date.now() / 1000)
                resolve()
            },
            reject: ()=>{
                $fs.vuex('vuex_flowDangerPopup', false)
                reject('你在提示工作流的风险后，选择了立即停止。')
            }
        })
    })
}




export default {
    isEnableSave,
    isMine,
    isAccess,
    isEnableEdit,
    dangerCheck,
}