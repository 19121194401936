let Base = require('./common/base')
let Utils = require('./common/utils')
import { connectMake } from "../../store/query";
import { CONTENT_TP, USER_LEVEL } from "../constants";

class ConnectNotion extends Base.Action {
    constructor () {
        super(
            'connectNotion',
            '发送到Notion',
            '将文字发送到Notion.so',
        )
        this.descHtml = `· 输出内容格式为{
            url: 添加到页面的链接地址
        }`
        this.tp = Base.TP.CONNECT
        this.inputTps = [Base.CONTENT_TP.HTML, Base.CONTENT_TP.TEXT]
        this.outputTps = [Base.CONTENT_TP.OBJECT]
        this.userLevel = USER_LEVEL.LOGIN
        this.importConnect('notion')
        this.args = [{
            key: 'notionPageQuery',
            name: '添加到页面的标题',
            required: true,
            desc: '在你授权的页面中，查询标题包含此字段的页面，将内容追加在此页面最后。可以通过花括号直接使用变量，如{{notionPageTitle}}'
        }]
    }
    async run({info, input, gc}) {
        await this.loadConnectStatus(info)
        try {
            let raw = input.raw
            if (input.contentTp === Base.CONTENT_TP.HTML) raw = Utils.html2text(raw)
            let res = await connectMake(this.connectId, info.args.connectUserKey, 'addblock', {
                input: {
                    contentTp: Base.CONTENT_TP.TEXT,
                    raw: raw,
                },
                pageQuery: Utils.replaceBracketValues(info.args.notionPageQuery, gc),
            })
            return {
                raw: res.output,
                contentTp: CONTENT_TP.OBJECT
            }
        } catch (error) {
            throw error.error
        }
    }
}

module.exports = new ConnectNotion()