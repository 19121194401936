import Vue from 'vue'
import Vuex from 'vuex'
import { USER_LEVEL } from "../model/constants";
import { clientGetters, initClientStates } from "./client";
import Cdb from "../db/db";
import deskApi from "../client/desk";

Vue.use(Vuex)

const getLifeData = function () {
  return JSON.parse(localStorage.getItem('lifeData') || '{}')
}

const setLifeData = function (v) {
  localStorage.setItem('lifeData', JSON.stringify(v || {}))
}

let lifeData = {}
try {
  lifeData = getLifeData()
} catch (error) {
  lifeData = {}
}

let savedKeys = [
  'vuex_userinfo',
  'vuex_flowHistory',
  'vuex_flowDangerPass',
  'vuex_clipEditMode',
  'vuex_clipLastRaw',
]

const saveLifeValue = function (key, value) {
  if (savedKeys.indexOf(key) != -1) {
    let tmp = getLifeData()
    tmp = tmp ? tmp : {}
    tmp[key] = value
    setLifeData(tmp)
  }
}

export default new Vuex.Store({
  state: {
    vuex_desk: deskApi,
    vuex_db: Cdb,
    vuex_userinfo: lifeData.vuex_userinfo || null,
    vuex_loading: false,
    vuex_loadingMask: false,
    vuex_metaPageTitle: '',  // 特制的页面标题
    // null 初始化未设置，false 已允许，prompt 对话框正在询问，support 浏览器不支持，denied 用户拒绝
    vuex_clipboardDenied: null,
    // null focus 聚焦时读取，forever一直读取，stop不读取
    vuex_clipboardReadType: 'focus',
    // 最近的剪贴板内容用于对比
    vuex_clipLastRaw: lifeData.vuex_clipLastRaw || '',
    // 剪贴板数据
    vuex_clips: [],
    vuex_clipsLoading: false,
    // 选中的项目
    vuex_clipsSelected: [], 
    // 弹出
    vuex_clipsPopup: [],
    // 上传过的内容，用于按钮展示
    vuex_clipsUploaded: [],
    vuex_clipsUploading: [],
    // 是否在首页，如果在搜索或云端页面执行完工作流后，toast提示让他去首页查看执行结果。
    vuex_clipsIsOnBaseBoard: true,
    // 手动设置默认列表的index
    vuex_clipsLocalSetActiveIndex: -1,
    // 手动首页跳转到默认本地列表
    vuex_clipsBoardToLocal: 0,
    // 列表是否显示输入框，输入模式
    vuex_clipEditMode: lifeData.vuex_clipEditMode || '',
    // 最近使用的flow的id列表
    vuex_flowHistory: lifeData.vuex_flowHistory && lifeData.vuex_flowHistory.length && lifeData.vuex_flowHistory || [],
    // 剩余进行的flow数量
    vuex_runFlowsCountLeft: 0,
    // 最近用过的flows
    vuex_flows: {},
    // 连接的服务的信息
    vuex_connects: {},
    // 通过危险检测的flowId
    vuex_flowDangerPass: lifeData.vuex_flowDangerPass || {},
    // 危险检测的弹窗是否展示
    vuex_flowDangerPopup: false,
    // 基础特性
    mgb_modals: [],
    mgb_modalShow: false,
    mgb_toastShow: false,
    mgb_toasts: [],
    // 客户端
    ...initClientStates(lifeData),
  },
  getters: {
    vuex_login: (state)=>{
      return state.vuex_userinfo && state.vuex_userinfo.id
    },
    // 导航栏是否缩紧，只有mac
    vuex_userLevel: (state)=>{
      let info = state.vuex_userinfo
      let uid = info && info.id
      let pro = info && info.pro && info.pro.level
      if (!uid) return USER_LEVEL.GUEST
      if (!pro || pro <= 0) return USER_LEVEL.LOGIN
      if (pro == 1) return USER_LEVEL.BASIC
      if (pro >= 100) return USER_LEVEL.BUSINESS
      return USER_LEVEL.PRO
    },
    // 客户端的getters
    ...clientGetters,
  },
  mutations: {
    $mgbSet(state, payload) {
      let nameArr = payload.name.split('.');
      let saveKey = '';
      let len = nameArr.length;
      if (nameArr.length >= 2) {
        let obj = state[nameArr[0]];
        for (let i = 1; i < len - 1; i++) {
          obj = obj[nameArr[i]];
        }
        obj[nameArr[len - 1]] = payload.value;
        saveKey = nameArr[0];
      } else {
        // 单层级变量，在state就是一个普通变量的情况
        state[payload.name] = payload.value;
        saveKey = payload.name;
      }
      saveLifeValue(saveKey, state[saveKey])
    },
  },
  actions: {
  },
  modules: {
  }
})
