<template>
    <v-container>
        <v-row class="">
            <template v-for="c, i in allConnects">
                <v-col :key="c.id" class="d-flex">
                    <v-card ripple outlined class="d-flex flex-grow-1"
                        @click.stop="enterConnect(c)"
                    >
                        <v-card-text class="d-flex flex-column flex-grow-1">
                            <div class="text-h6 font-weight-black grey--text text--darken-4">{{c.name}}</div>
                            <div class="text-caption">{{c.desc}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-responsive
                    :key="`width-${i}`"
                    v-if="(i + 1) % countInRow === 0"
                    width="100%"
                />
            </template>
        </v-row>
        <v-row>
            <p class="ma-3 text-caption grey--text text--darken-1">
                * 更多连接，敬请期待...</p>
        </v-row>
    </v-container>
</template>

<script>
import AllConnect from "../../model/connect/index";

export default {
    data () {
        return {}
    },
    computed: {
        allConnects () {
            let res = []
            for (const cid of AllConnect.ids) {
                let c = AllConnect.on(cid)
                if (!c || c.hide) continue
                res.push(c)
            }
            return res
        },
        countInRow () {
            switch (this.$vuetify.breakpoint.name) {
                case 'sm': return 1
                case 'md': return 2
                case 'lg': return 2
                case 'xl': return 2
                default: return 1
            }
        }
    },
    methods: {
        enterConnect (c) {
            this.$router.push('/integration/page?id=' + c.id)
        }
    }
}
</script>