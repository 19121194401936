let Base = require('./common/base')
let Utils = require('./common/utils')
import axios from "axios";

class ConnectOnenote extends Base.Action {
    constructor () {
        super(
            'connectOnenote',
            '发送到OneNote',
            '将文字或HTML发送到Microsoft OneNote。',
        )
        this.descHtml = `· 运行者需要登录并完成OneNote的授权才能正常运行。`
        this.tp = Base.TP.CONNECT
        this.inputTps = [Base.CONTENT_TP.HTML, Base.CONTENT_TP.TEXT]
        this.outputTps = [Base.CONTENT_TP.ORIGIN]
        this.userLevel = Base.USER_LEVEL.LOGIN
        this.importConnect('onenote')
        this.args = [{
            key: 'onenotePagePrefix',
            name: '目标页面名称需要包含的词缀',
            required: true,
            desc: '将在你最近更新的20个页面中寻找，如果页面的名称包含此词缀，则将输入的内容添加进去。可以通过花括号直接使用变量，如{{onenotePageTitle}}'
        }]
    }
    async run({info, input, gc}) {
        let access = await this.loadConnectStatus(info)
        let accessToken = access.access_token
        // 
        let http = axios.create({
            timeout: 15 * 1000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        // 
        let pagePrefix = info.args.onenotePagePrefix
        pagePrefix = Utils.replaceBracketValues(pagePrefix, gc)
        let msBaseUrl = `https://graph.microsoft.com/v1.0`
        let resPages = await http.get(msBaseUrl + '/me/onenote/pages')
        let pageids = resPages.data.value.filter(x=>{
            return x.title.indexOf(pagePrefix) > -1
        })
        let pageid = pageids.length > 0 && pageids[0].id
        if (!pageid) throw `没有找到标题包含此词缀的页面：${pagePrefix}`
        let pageUrl = msBaseUrl + `/me/onenote/pages/${pageid}/content`
        let patchData = [{
            target: 'body',
            action: 'append',
            content: `<br/>${input.raw}`
        }]
        await http.patch(pageUrl, patchData)
        return input
    }
}

module.exports = new ConnectOnenote()