var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info && _vm.info.action.args.length > 0)?_c('div',[_c('h3',{staticClass:"mb-2"},[_vm._v("配置项:")]),_vm._l((_vm.info.action.args),function(arg){return [(!arg.hide && !_vm.isHide(arg))?_c('div',{key:arg.key,staticClass:"mb-4"},[(arg.tp === 'textarea')?_c('v-textarea',{attrs:{"label":arg.name || arg.key,"messages":arg.desc,"aria-hidden":"","outlined":"","dense":"","auto-grow":"","rows":"1","placeholder":arg.placeholder || '',"persistent-placeholder":!!arg.placeholder},scopedSlots:_vm._u([(arg.required || _vm.isPrivateKey(arg))?{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error","small":""}},on),[_vm._v("mdi-hexagram")])]}}],null,true)},[_vm._v(" 必填项 ")]),(_vm.isPrivateKey(arg))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2 mr-2",attrs:{"color":"error","small":""}},on),[_vm._v("mdi-lock")])]}}],null,true)},[_vm._v(" 此数据被保护，仅创建者可获取可见。他人即使存为副本后也不可见。 ")]):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.info.args[arg.key]),callback:function ($$v) {_vm.$set(_vm.info.args, arg.key, $$v)},expression:"info.args[arg.key]"}}):_c('v-text-field',{attrs:{"outlined":"","dense":"","autocomplete":"off","label":arg.name || arg.key,"messages":arg.desc,"placeholder":arg.placeholder || '',"persistent-placeholder":!!arg.placeholder},scopedSlots:_vm._u([(arg.required || _vm.isPrivateKey(arg))?{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error","small":""}},on),[_vm._v("mdi-hexagram")])]}}],null,true)},[_vm._v(" 必填项 ")]),(_vm.isPrivateKey(arg))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2 mr-2",attrs:{"color":"error","small":""}},on),[_vm._v("mdi-lock")])]}}],null,true)},[_vm._v(" 此数据被保护，仅创建者可获取可见。他人即使存为副本后也不可见。 ")]):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.info.args[arg.key]),callback:function ($$v) {_vm.$set(_vm.info.args, arg.key, $$v)},expression:"info.args[arg.key]"}})],1):_vm._e()]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }