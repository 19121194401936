let Base = require('./common/base')
let Utils = require('./common/utils')

class VarJson extends Base.Action {
    constructor () {
        super(
            'varJson',
            '写入复杂变量',
            '通过JSON格式，可一次传写入多个复杂的变量。'
        )
        this.tp = Base.TP.ACTION
        this.outputTps = ['origin']
        this.args = [{
            key: 'varJson',
            name: 'JSON Key-Value Object',
            required: true,
            tp: 'textarea',
            desc: '可使用作为输入变量{{input}}'
        }]
        this.descHtml = `· 由于格式要求是JSON，使用变量的规则有所不同。变量只能单独使用，且不要带引号。
        · 可用的写法：{"myText": {{input}}, "herText": [{"name": "Ivan"}]}
        · 不可用的写法（变量带引号）{"myText": "{{input}}"}
        · 不可用的写法（混合使用）：{"myText": "someText in {{input}}"}
        `
    }
    async run({info, input, gc}) {
        let value = info.args.varJson || '{}'
        value = Utils.replaceBracketValues(value, {input: input.raw}, 'json')
        value = Utils.replaceBracketValues(value, gc, 'json')
        try {
            value = JSON.parse(value)
        } catch (error) {
            throw 'JSON格式错误，请依照动作【写入复杂变量】的规则填写。'   
        }
        if (typeof(value) !== 'object') throw '输入格式错误，解析后不是Key-Value Object.'
        for (const key in value) {
            gc[key] = value[key]
        }
        return input
    }
}

module.exports = new VarJson()