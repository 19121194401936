<template>
    <div>
        <v-list dense nav>
            <v-list-item
                v-for="item in recordMenu"
                :key="item.title"
                link
                @click="clickMenu(item)"
            >
                <v-list-item-content class="">
                    {{item.title}}
                </v-list-item-content>
            </v-list-item>
            
        </v-list>

        <!-- <v-divider class="mt-3 mb-3" /> -->
        <v-divider />

        <v-list dense nav>
            <v-list-item
                v-for="item in userMenu"
                :key="item.title"
                link
                @click="clickMenu(item)"
            >
                <v-list-item-content>
                    {{item.title}}
                </v-list-item-content>
            </v-list-item>
            
        </v-list>

        
        <!-- <v-divider class="mt-3 mb-3"/> -->
        <v-divider />

        <v-list dense nav>
            <v-list-item
                v-for="item in normalMenu"
                :key="item.title"
                link
                @click="clickMenu(item)"
            >   
                <v-list-item-icon v-if="item.icon" class="mr-1"><v-icon small>{{item.icon}}</v-icon></v-list-item-icon>
                <v-list-item-content class="text-caption">
                    {{item.title}}
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-dialog
            v-model="showContactPopup"
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    请在公众号留言，与我们联系
                </v-card-title>
                <v-card-text>
                    <img :src="qrWeixinImage" width="100%"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { userLogout } from "../../store/query";
import QRweixinImage from "../../assets/qrweixinlogin.png";
import { deleteAllClips } from "../../model/clip/common";


export default {
    data () {
        return {
            // 用户记录菜单
            recordMenuRaw: [{
                title: '清除剪贴板记录',
                click: ()=>{
                    this.$fs.modal({
                        title: '清除剪贴板记录',
                        text: '清除所有剪贴板记录，还包括日志和笔记',
                        confirm: ()=>{
                            deleteAllClips()
                        }
                    })
                }
            },{
                title: '清除本地记录',
                if: ()=>!this.vuex_userinfo,
                click: ()=>{
                    this.$fs.modal({
                        title: '清除所有本地记录',
                        text: '清除所有，包含剪贴板记录和工作流列表等。',
                        confirm: ()=>{
                            this.$fs.toast('Clear successfully.')
                            userLogout()
                        }
                    })
                }
            }],
            // 通用菜单
            normalMenuRaw: [{
                title: '退出登录',
                if: ()=>this.vuex_userinfo,
                click: ()=>{
                    this.$fs.modal({
                        title: '退出登录',
                        text: '同时清除所有本地记录。',
                        confirm: ()=>{
                            userLogout().then(()=>{
                                if (this.$route.path != '/') this.$router.replace('/')
                            })
                        }
                    })                    
                }
            // }, {
            //     title: '帮助中心',
            //     click: ()=>{
            //         window.open('https://help.copyauto.cn', '_blank')
            //     },
            }, {
                title: '帮助中心 & 用户协议',
                icon: 'mdi-help-circle-outline',
                click: ()=>{
                    window.open('https://help.copyauto.cn', '_blank')
                }
            }, {
                title: '联系我们',
                icon: 'mdi-wechat',
                click: ()=>{
                    // window.open('https://support.qq.com/products/341422', '_blank')
                    this.showContactPopup = true
                }
            }, {
                title: 'Windows客户端',
                icon: 'mdi-microsoft-windows',
                click: ()=>{
                    this.$fs.clientDownloadModal('win')
                }
            }, {
                title: 'Mac客户端',
                icon: 'mdi-apple-finder',
                click: ()=>{
                    this.$fs.clientDownloadModal('mac')
                }
            }],
            // 用户相关菜单
            userMenuRaw: [{
                title: '注册',
                path: '/user/signup',
                if: ()=>!this.vuex_userinfo
            }, {
                title: '登录',
                path: '/user/login',
                if: ()=>!this.vuex_userinfo
            }, {
                title: '管理工作流',
                path: '/flow/manage',
                if: ()=>this.vuex_userinfo,
            }, {
                title: '管理连接',
                path: '/integration/manage',
                if: ()=>this.vuex_userinfo
            // }, {
            //     title: '会员计划',
            //     path: '/user/plan',
            //     if: ()=>this.vuex_userinfo
            // }, {
            //     // todo
            //     title: '设置',
            //     path: '/user/settings',
            //     if: ()=>this.vuex_userinfo
            }],
            showContactPopup: false,
        }
    },
    computed: {
        qrWeixinImage () {
            return QRweixinImage
        },
        userMenu () {
            return this.userMenuRaw.filter(x=>{
                return !x.if || x.if()
            })
        },
        normalMenu () {
            return this.normalMenuRaw.filter(x=>{
                return !x.if || x.if()
            })
        },
        recordMenu () {
            return this.recordMenuRaw.filter(x=>!x.if || x.if())
        }
    },
    methods: { 
        clickMenu (v) {
            this.$emit('close')
            if (v.path) return this.$router.push(v.path)
            if (v.click) return v.click()
        }
    }
}
</script>