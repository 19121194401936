<template>
    <div class="mgb-toast">
        <v-snackbar
            v-model="toastShow"
            :timeout="x.time || 2000"
            :color="x.color || 'primary'"
            text
            top
        >
            {{x.text}}
            <template v-slot:action="{ attrs }">
                <v-btn icon @click.native="toastShow = false"
                    v-bind="attrs"
                    :color="x.color || 'primary'"
                    
                >
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </template>
            
        </v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        x () {
            if (this.mgb_toasts.length > 0) return this.mgb_toasts[0]
            return {}
        },
        toastShow: {
            get () {
                return this.mgb_toastShow
            },
            set (v) {
                this.$fs.vuex('mgb_toastShow', v)
                if (!v) setTimeout(() => {
                    let old = this.mgb_toasts
                    old.splice(0, 1)
                    this.$fs.vuex('mgb_toasts', old)
                    if (old.length > 0) this.$fs.vuex('mgb_toastShow', true)
                }, 500);
            }
        }
    }
}
</script>