import { runsFlow, runFlow, runAction } from "./common/common.js";
import Utils from "./common/utils";

let actionIds = [
    'variable',
    'varRequired',
    'varJson',
    'varRead',
    'writeNoteText',
    'writeNoteImage',
    'writeNoteHtml',
    'writeLog',
    'htmlToText',
    'textReplace',
    'fileDownload',
    'openUrl',
    'urlPost',
    'runEval',
    'flowRun',
    'skipFlow',
    'ocrBaidu',
    'base64Encode',
    'base64Decode',
    'fanyiBaidu',
    'imageCompress',
    'connectOnedrive',
    'connectOnenote',
    'connectNotion',
    'connectQiniu',
    'hiddenAction',
    'undefinedAction',
]

export default {
    actionIds,
    actionOn: Utils.actionOn,
    runsFlow,
    runFlow,
    runAction,
}