<template>
    <div class="d-inline-block tag-list" v-if="chips.length > 0">
        <div class="mr-n2 mb-n2 d-flex flex-wrap">
            <template v-for="chip in chips">
                <v-tooltip v-model="show[chip.title]" :disabled="!chip.tip" :key="chip.title" bottom
                    color="cblack" open-delay="300" close-delay="100"
                >
                    <template v-slot:activator="{on, attrs}">
                        <v-chip label small class="mr-2 mb-2 pl-3 pr-3 tag-chip rounded-pill"
                            @click.stop="show[chip.title] = true"
                            :class="chip.clazz || 'grey lighten-3'" :style="chip.style"
                            v-bind="attrs"
                            v-on="chip.tip && on"
                            :ripple="false"
                        >
                            <v-icon size="16" v-if="chip.icon" :color="chip.iconColor || 'white'" :class="{
                                'mr-2': !chip.hideTitle,
                            }">{{chip.icon}}</v-icon>
                            <span v-if="!chip.hideTitle">{{chip.title}}</span>
                        </v-chip>
                    </template>
                    <span v-if="chip.tip">{{chip.tip}}</span>
                </v-tooltip>
            </template>
        </div>
    </div>
</template>

<script>
import { FLOW_STATUS_TP, CONTENT_TP, CLIP_TP, ACTION_TP } from "../model/constants";

export default {
    props: {
        tags: Array,
        any: Boolean,
        /*  
            type    特殊类的名称       
            title   名称
            clazz   特殊样式类
            style   样式
            icon    是否有mid图标
            iconColor   图标的颜色
            hideTitle   隐藏title只显示图标
        */
    },
    data () {
        return {
            show: {},
        }
    },
    computed: {
        chips () {
            let res = (this.tags || []).map(x=>{
                // 如果是文字，直接对应类型
                if (typeof(x) === 'string') x = {type: x}
                if (x.type) return this.type2chip(x.type)
                if (!x.title) x.title = '?'
                return x
            })
            if (this.any && res.length === 0) res = [this.type2chip('any')]
            return res
        },
    },
    methods: {
        type2chip (t) {
            switch (t) {
                case 'userLevel_1': return {
                    title: '登录',
                    icon: 'mdi-draw',
                    iconColor: 'cblack',
                    tip: '请登录账号',
                }
                case 'userLevel_10': return {
                    title: '会员',
                    icon: 'mdi-badge-account',
                    tip: '至少需要会员权限',
                    iconColor: 'cblack',
                }
                case 'userLevel_100': return {
                    title: '特殊会员',
                    icon: 'mdi-lock',
                    iconColor: 'cblack'
                }
                case 'any': return {
                    title: '任意',
                    icon: 'mdi-help-circle-outline',
                    tip: '不限制输入类型',
                    iconColor: 'cblack',
                }
                case CONTENT_TP.TEXT: return {
                    title: '文本',
                    icon: 'mdi-format-text-variant',
                    iconColor: 'cblack',
                }
                case CONTENT_TP.HTML: return {
                    title: 'HTML',
                    icon: 'mdi-language-html5',
                    iconColor: 'cblack',
                }
                case CONTENT_TP.IMAGE: return {
                    title: '图片',
                    icon: 'mdi-image-outline',
                    tip: '不缓存，关闭或刷新页面就移除',
                    iconColor: 'cblack',
                }
                case FLOW_STATUS_TP.ERROR: 
                case 'ERROR': return {
                    title: '错误',
                    clazz: 'error'
                }
                case FLOW_STATUS_TP.OK: return {
                    title: '正常',
                    clazz: 'success'
                }
                case FLOW_STATUS_TP.SAMPLE: return {
                    title: '草稿',
                    tip: '请另存为副本后运行',
                    clazz: 'grey white--text',
                }
                case CLIP_TP.LOG: return {
                    title: '日志',
                    tip: '不缓存，刷新或关闭页面后移除',
                    clazz: 'success',
                }
                case 'logSuccess': return {
                    title: '日志',
                    clazz: 'success',
                    tip: '不缓存，刷新或关闭页面后移除',
                    icon: 'mdi-check-circle-outline',
                    iconColor: 'white'
                }
                case 'logError': return {
                    title: '日志',
                    clazz: 'error',
                    tip: '不缓存，刷新或关闭页面后移除',
                    icon: 'mdi-alert-circle-outline',
                    iconColor: 'white'
                }
                case 'logWarning': return {
                    title: '日志',
                    clazz: 'warning',
                    icon: 'mdi-alert-outline',
                    iconColor: 'white',
                }
                case CLIP_TP.NOTE: return {
                    title: '笔记',
                    tip: '经过工作流处理的内容',
                    icon: 'mdi-note-outline',
                    iconColor: 'info',
                }
                case ACTION_TP.VARIABLE: return {
                    title: '变量',
                    icon: 'mdi-variable',
                    iconColor: 'cblack',
                }
                case ACTION_TP.ACTION: return {
                    title: '动作',
                    icon: 'mdi-clipboard-play-outline',
                    iconColor: 'cblack',
                }
                case ACTION_TP.CONNECT: return {
                    title: '连接',
                    icon: 'mdi-connection',
                    iconColor: 'cblack',
                }
                case CONTENT_TP.ORIGIN: return {
                    title: '原始',
                    tip: '动作会将输入内容原样输出。',
                    icon: 'mdi-import',
                    iconColor: 'cblack',
                }
                case CONTENT_TP.ARRAY: return {
                    title: '数组',
                    icon: 'mdi-code-brackets',
                    iconColor: 'cblack',
                }
                case CONTENT_TP.OBJECT: return {
                    title: '字典',
                    icon: 'mdi-code-json',
                    iconColor: 'cblack',
                }
                // default: return {
                //     title: t
                // }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tag-chip {
}
</style>