<template>
    <div class="white pl-3 pr-3 ml-n3 mr-n3">
    <v-row class="pb-6 pt-2">
    <template v-for="item, i in items">
    <v-col :key="item.id" class="d-flex">
        <v-card
            @click.stop="tapIndex(i)"
            :style="{
                
            }"
            outlined
            class="flex-grow-1 d-flex grey lighten-4 overflow-hidden"
        >
            <div v-if="bgText" class="bg-text">{{bgText}}</div>
            <v-card-text class="d-flex flex-column flex-grow-1" style="z-index:1;">
                <!-- 展示名称 -->
                <div class="flex-grow-1">
                    <div class="text-h6 font-weight-black grey--text text--darken-4">{{item.name}}</div>
                    <div class="d-flex align-center text-caption" v-if="item.creator_id && item.creator_id !== (vuex_userinfo && vuex_userinfo.id)">
                        <v-icon dense class="mr-1" small>mdi-account-outline</v-icon>
                        {{item.author && item.author.nickname || '匿名作者'}}
                    </div>
                </div>
                <div class="d-flex mt-3 align-end">
                    <tag-list :tags="statusTags(item)" />
                    <v-spacer />
                    <v-btn class="mr-n3 mt-n1" style="padding-top: 2px" small depressed plain v-if="showEdit" @click.stop="tapEditIndex(i)">
                        <!-- <v-icon small left>mdi-pencil</v-icon> -->
                        {{editText || '编辑'}} 
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-col>
    <v-responsive
        v-if="(i + 1) % countInRow === 0"
        :key="`width-${i}`"
        width="100%"
    ></v-responsive>
    </template>
    </v-row>
    </div>
</template>

<script>
import TagList from './TagList.vue'
export default {
  components: { TagList },
    props: {
        /*
            oid: id
            statusTp: ok, error, template // 正常，错误，模版
            name  // 名称
            author  // 作者
            hot  // 热度
            color // 自定义边框
        */
        items: Array,
        showEdit: Boolean,
        bgText: String,
        editText: String,
    },
    computed: {
        countInRow () {
            switch (this.$vuetify.breakpoint.name) {
                case 'sm': return 1
                case 'md': return 2
                case 'lg': return 3
                case 'xl': return 3
                default: return 1
            }
        }
    },
    methods: {
        tapIndex (i) {
            this.$emit('tapIndex', i)
        },
        tapEditIndex (i) {
            this.$emit('editIndex', i)
        },
        statusTags (item) {
            let tps = item.inputTps || []
            if (tps.length == 0) tps = ['any']
            let res = tps.map(x=>{
                return {type: x}
            })
            // 输入类型
            // todo 总使用数量
            // todo 最近使用数量
            if (item.id && !item.creator_id) {
                res.unshift({
                    title: 'No Cloud',
                    clazz: 'error',
                    icon: 'mdi-cloud-off-outline',
                    hideTitle: true,
                    tip: '没有保存在云端',
                })
            }
            // 使用权限
            // if (item.userLevel) {
            //     res.unshift({type: `userLevel_${item.userLevel}`})
            // }
            // 状态
            res.unshift({type: item.statusTp})
            return res
        }
    }
}
</script>


<style lang="scss" scoped>
.bg-text {
    position: absolute;
    font-size: 60px;
    line-height: .8;
    // color: rgba($color: #000000, $alpha: .03);
    color: #fff;
    top: 5px;
    right: -8px;
    transform: rotate(15deg);
    -webkit-transform: rotate(15deg);
    font-weight: bold;
    z-index: 0;
}
</style>