export const ACTION_TP = Object.freeze({
    VARIABLE: 'variable',  // 定义变量
    ACTION: 'action',  // 动作
    NOTE: 'note', // 输出笔记
    CONDITION: 'condition',  // 条件判断
    CONNECT: 'connect',  // 连接
})

export const CONTENT_TP = Object.freeze({
    TEXT: 'text',  // 文字
    IMAGE: 'image',  // 图片数据
    HTML: 'html',  // HTML
    OBJECT: 'object',  // object
    ORIGIN: 'origin',  // 原始数据
    ARRAY: 'array',  // 数组
})

export const USER_LEVEL = Object.freeze({
    GUEST: 0,
    LOGIN: 1,
    BASIC: 10,
    PRO: 100,
    BUSINESS: 1000,
    SUPER: 10000
})

export const ARG_TP = Object.freeze({
    TEXT: 'text',
    NUMBER: 'number',
    JSON: 'json',
})

export const CLIP_TP = Object.freeze({
    CLIP: 'clip',
    NOTE: 'note',
    LOG: 'log',
})

export const LOG_TP = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
})

export const FLOW_STATUS_TP = Object.freeze({
    OK: 'ok',
    ERROR: 'error',
    SAMPLE: 'sample',
})