let Base = require('./common/base')

class Qiniu extends Base.Connect {
    constructor () {
        super(
            'qiniu',
            '七牛云储存',
            '连接七牛云储存，授权以便使用文件上传管理的相关工作流。'
        )
    }
}

module.exports = new Qiniu()