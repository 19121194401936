let Base = require('./common/base')
let Utils = require('./common/utils')

class Variable extends Base.Action {
    constructor (required) {
        super(
            required ? 'varRequired' : 'variable',
            required ? '写入必填变量' : '写入变量',
            required ? '供后续动作读取，变量的值必填，否则报错。': '供后续动作读取，变量的值可以为空。'
        )
        this.tp = Base.TP.VARIABLE
        this.descHtml = `· 变量的值（Value）可以直接写入，比如：我是一个字符串
        · 可以通过双花括号获取已经写入变量，比如：{{savedText}}
        · 可以通过{{input}}获取输入数据，比如：{{input}}
        · 如果通过双花括号获取的值是字典类型，可以通过点号获取更深的数据，比如：{{input.theText}}
        · 上述可以混合使用，比如：我是一个{{input.theText}}的字符串
        `
        this.outputTps = ['origin']
        this.args = [{
            key: 'varKey',
            required: true,
            desc: '* 后续动作需要使用时，用双花括号包裹这个填入key。',
            name: '变量的键（Key）'
        }, {
            key: 'varValue',
            name: '变量的值（Value）',
            required: required,
            tp: 'textarea',
        }]
    }
    async run ({info, input, gc}) {
        let key = info.args.varKey
        let value = info.args.varValue || ''
        value = Utils.replaceBracketValues(value, {input: input.raw})
        value = Utils.replaceBracketValues(value, gc)
        gc[key] = value
        return input
    }
}

module.exports = Variable