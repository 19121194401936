var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{staticClass:"tool-bar",class:{
    'tp-ondesktop': _vm.client_insetTitleBar
  },attrs:{"dense":"","flat":""}},[_c('v-btn',{staticClass:"flex-shrink-0",attrs:{"icon":""},on:{"click":function($event){return _vm.closePopup()}}},[(_vm.vuex_loading)?_c('v-progress-circular',{attrs:{"size":"24","width":"3","indeterminate":""}}):_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-down")])],1),_c('v-toolbar-title',{staticClass:"text-h6 text-truncate"},[_vm._v("记录")]),_c('v-spacer'),_c('div',[(_vm.showDownload)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.tapDownload}},[_vm._v(" 下载 ")]):_vm._e(),(_vm.showOpenUrl)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.tapOpenUrl}},[_vm._v(" 打开链接 ")]):_vm._e(),(_vm.showPlainText)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.tapPlainText}},[_vm._v(" 转纯文本 ")]):_vm._e(),(_vm.showSetClipboardButton)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.tapSetClipboard}},[_vm._v(" 复制 ")]):_vm._e(),(_vm.showMergeButton)?_c('v-menu',{attrs:{"close-on-content-click":"","nudge-left":"100","nudge-bottom":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2663561048)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.mergeClipsToArray}},[_vm._v(" 合并为数组 ")])],1)],1)],1):_vm._e(),(_vm.showMergeButton)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.mergeClips}},[_vm._v(" 合并 ")]):_vm._e()],1)],1),_c('v-toolbar',{staticClass:"toolbar-placeholder",class:{
    'mt-ondesktop': _vm.client_insetTitleBar
  },attrs:{"flat":"","dense":""}}),_c('v-container',{staticClass:"pt-0"},[_c('flow-run-mask'),_c('div',{staticClass:"clips-selected mb-6 ml-n3 mr-n3"},[_c('clip-list',{attrs:{"hide-cloud":"","hide-copy":"","hide-edit-desc":"","disable-short-key":"","items":_vm.clips,"enable-drag":_vm.clips.length > 1},on:{"tapIndex":_vm.tapClipIndex,"dragItems":_vm.dragClips}})],1),_c('div',{staticClass:"flows-list"},[_c('div',{staticClass:"flows-actions align-center ma-3 mb-0 d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"mr-n3",attrs:{"text":""},on:{"click":function($event){_vm.$router.push('/flow/edit'); _vm.closePopup(1)}}},[_vm._v(" 创建 ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$router.push('/flow/manage'); _vm.closePopup(1)}}},[_vm._v(" 管理 ")]),_c('div',{staticClass:"flex-grow-1 text-h6"},[_vm._v("运行：")])],1),_c('div',{staticClass:"pl-3 pr-3 pt-3"},[_c('v-text-field',{staticClass:"ca-solo-input ca-search-bar",attrs:{"solo":"","flat":"","dense":"","placeholder":"搜索工作流","autocomplete":"off","persistent-placeholder":"","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify"},on:{"click:clear":_vm.tapClearSearching},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}})],1),_c('flow-list',{staticClass:"mt-2",attrs:{"show-edit":"","items":_vm.flows},on:{"tapIndex":_vm.tapFlowIndex,"editIndex":_vm.editFlowIndex}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }