let Base = require('./common/base')
let Utils = require('./common/utils')
import { readBase64ToBlob } from "../../model/clip/base";
import axios from "axios";
var mime = require('mime-types')

class ConnectOnedrive extends Base.Action {
    constructor () {
        super(
            'connectOnedrive',
            '发送到Onedrive',
            '将图片或文件发送到Microsoft OneDrive。',
        )
        this.descHtml = `· 运行者需要登录并完成Onedrive的授权才能正常运行。
        · 输出内容为Onedrive返回的详细信息，具体可通过【输出内容为日志】进行查看。
        `
        this.tp = Base.TP.CONNECT
        this.inputTps = [Base.CONTENT_TP.IMAGE]
        this.outputTps = [Base.CONTENT_TP.OBJECT]
        this.userLevel = Base.USER_LEVEL.LOGIN
        this.importConnect('onedrive')
        this.args = [{
            key: 'onedriveFileName',
            name: '自定义文件名',
            desc: '可选。支持用于命名的随机参数：{{rand}}，比如：filenname_{{rand}}',
        }, {
            key: 'onedriveFolderName',
            name: '自定义的文件夹路径',
            desc: '可选。比如：/myFolder/mySubFolder'
        }]
    }
    async run({info, input, gc}) {
        // 获取accessToken
        let access = await this.loadConnectStatus(info)
        let accessToken = access.access_token
        // 获取文件
        let blob = await readBase64ToBlob(input.raw)
        // 文件名
        let filename = info.args.onedriveFileName || ''
        if (filename) {
            filename = Utils.replaceBracketRand(filename)
            filename = Utils.replaceBracketValues(filename, gc)
        }
        if (!filename) filename = input.text || Utils.replaceBracketRand('file_from_copyauto_{{rand}}')
        // 增加扩展名
        if (filename.indexOf('.') === -1) {
            let exten = mime.extension(blob.type)
            filename = filename + '.' + exten
        }
        // 文件夹的名称
        let foldername = info.args.onedriveFolderName || ''
        if (foldername) foldername = Utils.replaceBracketValues(foldername, gc)
        if (foldername) {
            if (foldername[0] != '/') foldername = '/' + foldername
            if (foldername.slice(-1) != '/') foldername = foldername + '/'
        }
        // 发起请求
        let msBaseUrl = `https://graph.microsoft.com/v1.0`
        let http = axios.create({
            // 60秒超时
            timeout: 60 * 1000,
        })
        // uploadSession
        let uploadSessionUrl = msBaseUrl + `/me/drive/items/root:${foldername + filename}:/createUploadSession`
        let uploadSessionRes = await http.post(uploadSessionUrl, {item: {
            "name": filename,
            "@microsoft.graph.conflictBehavior": "rename",
            "@odata.type": "microsoft.graph.driveItemUploadableProperties",
        }}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        // doUpload
        let uploadUrl = uploadSessionRes.data.uploadUrl
        let uploadRes = await http.put(uploadUrl, blob, {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Length': blob.size,
        })
        return {
            contentTp: Base.CONTENT_TP.OBJECT,
            text: JSON.stringify(uploadRes.data),
            raw: {
                data: uploadRes.data,
                url: uploadRes.data.webUrl,
                name: uploadRes.data.name,
                size: uploadRes.data.size,
            },
            
        }
    }
}

module.exports = new ConnectOnedrive()