import { Base64 } from "js-base64";
import { CLIP_TP, CONTENT_TP } from "../constants";
import $fs from "@/store/fast";
import Mime from "mime-types";


function readBlobToBase64 (blob) {
    return new Promise((resovle)=>{
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
            let b64data = reader.result
            return resovle(b64data)
        }
        reader.onerror = function () {
            return resovle('')
        }
    })
}

function readImageMeta (base64) {
    return new Promise((resovle)=>{
        let i = new Image()
        i.onload = ()=>{
            let mt
            try {
                let matches = base64.match(/^data:([A-Za-z-+/]+);base64,(.+)$/)
                mt = matches[1]
            } catch (error) {
                mt = ''
            }
            resovle({
                mimetype: mt,
                width: i.width,
                height: i.height,
            })
        }
        i.onerror = ()=>resovle()
        i.onabort = ()=>resovle()
        i.src = base64
    })
}

function readBase64ToBlob (b64) {
    return new Promise((resovle)=>{
        fetch(b64)
        .then(res=>res.blob())
        .then(blob=>resovle(blob))
        .catch(()=>resovle())
    })
}


class Clip {
    constructor ({
        id,
        raw,
        text,
        tp,
        contentTp,
        data,
        logTp,
        meta,
        create_at_ts,
        updated_at_ts,
        url,
        desc,
        sourceUrl,
        fileUrl,
    }) {
        this.id = id || this.createId()
        this.createdTs = create_at_ts && create_at_ts * 1000 || Date.now()
        this.updatedTs = updated_at_ts && updated_at_ts * 1000 || Date.now()
        this.tp = tp || CLIP_TP.CLIP
        this.contentTp = contentTp || ''
        this.raw = raw || ''
        this.desc = desc || ''
        this.logTp = logTp || ''
        this.data = []
        this.sourceUrl = sourceUrl || ''
        this.fileUrl = fileUrl || ''
        // 信息属性
        if (meta) this.meta = meta
        // 文字，如果是html且没有
        if (text) {
            this.text = text
            this.data.push('data:text/plain;base64,' + Base64.encode(text))
        } 
        else if (contentTp == 'html') this.text = raw.replace(/<[^>]+>/gm, '')
        // 数据
        // 从url读取image
        if (url && contentTp == CONTENT_TP.IMAGE) {
            fetch(url).then(res=>res.blob()).then(blob=>readBlobToBase64(blob)).then(b64=>{
                this.data.push(b64)
                this.raw = b64
                return readImageMeta(b64)
            }).then(imgmeta=>{
                this.meta = imgmeta
            }).catch(err=>{
                console.log('url to b64 error:', err)}
            )
        }
        // 从本地url中读取
        else if (fileUrl && $fs.desk && contentTp == CONTENT_TP.IMAGE) {
            let mt = Mime.lookup(fileUrl)
            $fs.desk.readClipFile(fileUrl).then(res=>{
                let resRaw = `data:${mt};base64,` + res
                this.data.push(resRaw)
                this.raw = resRaw
            })
        }
        // 如果有data直接存data
        else if (data) this.data = data
        // 否则自动识别
        else {
            if (contentTp == 'html') this.data.push('data:text/html;base64,' + Base64.encode(raw))
            if (contentTp == 'image') this.data.push(raw)
        }
    }
    createId (targetClip, subadd) {
        // subadd 如果同时生成多个，为防止序号相同，在时间戳上再额外加上序号
        // 如果是追加在谁的后面
        let ts = Date.now() + (subadd || 0)
        if (targetClip) {
            let newid = targetClip.id.substr(0, targetClip.id.length - 1)
            return newid + 'a' + ts.toString() + 'z'
        }
        return 'clip-' + ts.toString() + 'z'
    }
    isCloud () {
        return this.id && this.id.indexOf('clip-') === -1
    }
}

export {
    Clip,
    readBlobToBase64,
    readImageMeta,
    readBase64ToBlob,
}