<template>
    <v-dialog :value="vuex_flowDangerPopup"
        persistent
        scrollable
        transition="dialog-bottom-transition"
        max-width="800px"
    >
        <v-card v-if="vuex_flowDangerPopup">
            <v-card-title class="error--text">工作流-包含可能有风险的动作</v-card-title>
            <v-divider />
            <v-card-text>
                <div class="mt-3 mb-3 ">
                    <div class="text-body-1">名称：{{flow.name}}</div>
                    <div class="text-caption">作者：{{flow.author && flow.author.nickname || '匿名用户'}}</div>
                    <div class="text-caption font-weight-bold cblack--text" style="white-space: pre-line;">说明：{{flow.desc || '没有写说明。'}}</div>
                </div>
                <v-divider />
                <div class="">
                    <div v-for="d in dangers" :key="d.actionIndex" class="mt-3 mb-3">
                        <div class="text-body-1">第{{parseInt(d.actionIndex) + 1}}个动作：{{d.title}}</div>
                        <div class="text-caption">{{d.desc}}</div>
                        <div class="text-caption font-weight-bold cblack--text">作者对此动作的备注：{{d.dangerDesc || '没有写备注。'}}</div>
                        <div class="danger-content pa-2 mt-2 error lighten-5 rounded" v-if="d.content" style="white-space: pre-line;">{{d.content}}</div>
                        <v-divider class="mt-5"/>
                    </div>
                </div>
                
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between pb-4">
                <v-btn text class="primary" @click.stop="tapConfirm">信任并继续</v-btn>
                <v-btn text class="error" @click.stop="tapReject">立即停止</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    computed: {
        flow () {
            return this.vuex_flowDangerPopup.flow
        },
        dangers () {
            return this.vuex_flowDangerPopup.dangers
        },
    },
    methods: {
        tapConfirm () {
            this.vuex_flowDangerPopup.confirm()
        },
        tapReject () {
            this.vuex_flowDangerPopup.reject()
        }
    }
}
</script>