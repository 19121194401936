

const names = [{
    // 用户登录
    name: 'userLoginEmail',
    url: '/user/login/email',
    method: 'post',
}, {
    // 用户登出
    name: 'userLogout',
    url: '/user/logout',
}, {
    // 用户email注册
    name: 'userRegEmail',
    url: '/user/reg/email',
    method: 'post',
}, {
    // 用户email忘记密码
    name: 'userForgotEmail',
    url: '/user/forgot/email',
    method: 'post',
}, {
    // 获取用户信息
    name: 'userInfo',
    url: '/user/info',
}, {
    // 上报前端错误信息
    name: 'reportException',
    url: '/user/reportException',
    method: 'post',
}, {
    // 获取对应会员计划的支付二维码
    name: 'planQrcode',
    url: '/user/plan/qrcode'
}, {
    // 根据ids获取工作流
    name: 'getFlows',
    url: '/flow/list/ids',
    method: 'post',
}, {
    // 根据用户获取工作流
    name: 'reloadUserFlows',
    url: '/flow/list/user'
}, {
    // 修改工作流
    name: 'editFlow',
    url: '/flow/edit',
    method: 'post'
}, {
    // 删除工作流
    name: 'deleteFlow',
    url: '/flow/delete',
    method: 'post',
}, {
    // 传输动作
    name: 'sendAction',
    url: '/action/send',
    method: 'post'
}, {
    // 获取用户在某个服务上默认项的连接状态
    name: 'connectStatus',
    url: '/connect/status',
}, {
    // 获取用户在某个连接上全部user_key的状态
    name: 'connectStatusAll',
    url: '/connect/status/all'
}, {
    name: 'connectSetDefault',
    url: '/connect/setDefault',
}, {
    name: 'connectRename',
    url: '/connect/rename'
}, {
    // 发起权限连接
    name: 'connectStart',
    url: '/connect/start',
    method: 'post'
}, {
    // 删除权限连接
    name: 'connectDelete',
    url: '/connect/delete',
    method: 'post'
}, {
    // 后端执行操作
    name: 'connectMake',
    url: '/connect/make',
    method: 'post'
}, {
    // 获取最近更新的云clips
    name: 'cloudClipPrevs',
    url: '/clip/cloud/prevs',
}, {
    // 获取云clips翻页
    name: 'cloudClipNexts',
    url: '/clip/cloud/nexts',
}, {
    // 创建新的
    name: 'cloudClipAdd',
    url: '/clip/cloud/add',
    method: 'post'
}, {
    // 删除
    name: 'cloudClipDelete',
    url: '/clip/cloud/delete',
    method: 'post',
}, {
    // 修改
    name: 'cloudClipEdit',
    url: '/clip/cloud/edit',
    method: 'post'
}, {
    // 搜索
    name: 'cloudClipSearch',
    url: '/clip/cloud/search',
}, {
    // 上传文件的token获取
    name: 'cloudClipUploadToken',
    url: '/clip/cloud/upload_token'
}]

export default {
    names,
}