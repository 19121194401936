<template>
    <v-card tile>
        <v-toolbar class="tool-bar" dense flat :class="{
            'tp-ondesktop': client_insetTitleBar
        }">
            <v-btn icon @click="$emit('closePopup')" class="flex-shrink-0">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-text-field
                class="mt-0 pt-0 ml-3"
                autocomplete="off"
                placeholder="搜索"
                v-model="searching"
                prepend-inner-icon="mdi-magnify"
                @click:clear="tapClearSearching"
                clearable
                hide-details=""
            />
        </v-toolbar>
        <v-toolbar flat dense :class="{
            'mt-ondesktop': client_insetTitleBar
        }"></v-toolbar>
        <v-container>
            <action-list
                :items="items"
                @tapIndex="tapActionIndex"
            />
        </v-container>
    </v-card>
</template>

<script>
import ActionList from '../../components/ActionList.vue'
import AllAction from "../../model/action";

export default {
  components: { ActionList },
  props: {
      open: null,
  },
  data () {
      const allActions = (()=>{
          let res = []
          for (const aid of AllAction.actionIds) {
              let action = AllAction.actionOn(aid)
              if (!action || action.hide) continue
              res.push({
                  aid: aid,
                  desc: '',
                  action: action,
                  args: {},
              })
          }
          return res
        })()
      return {
          searching: null,
          items: allActions,
          allActions: allActions,
      }
  },
  watch: {
      open (v) {
          this.searching = ''
      },
      searching (val) {
          if (val === '') return this.tapClearSearching()
          val && this.doSearch(val)
      }
  },
    methods: {
        tapActionIndex (i) {
            this.$emit('addAction', JSON.parse(JSON.stringify(this.items[i])))
        },
        doSearch (val) {
            this.items = this.allActions.filter(e=>{
                let txt = `${e.action.name} ${e.action.desc}`
                return txt.toLowerCase().indexOf((val || '').toLowerCase()) > -1
            })
        },
        tapClearSearching () {
            this.items = this.allActions
        }
    }
}
</script>
