<template>
    <v-card tile v-if="info">
        <v-toolbar dense flat :class="{
            'mt-ondesktop': client_insetTitleBar
        }">
            <v-btn outlined class="primary--text mr-4" @click="$emit('closePopup')">
                <v-icon left>mdi-check</v-icon>
                确定
            </v-btn>
            <v-toolbar-title class="text-h6 font-weight-black">配置动作：{{info.action.name}}</v-toolbar-title>
        </v-toolbar>

        <v-container style="max-width: 600px;">
            <!-- action的基本信息展示和修改 -->
            <field-info :info="info" :editable="editable"/>
            <!-- action输入项的展示和修改 -->
            <field-args :info="info" :editable="editable"/>
            <!-- action的特殊内容模块 -->
            <component v-if="info.action.component"
                :is="info.action.component"
                :info="info"
            />
        </v-container>
    </v-card>
</template>

<script>
import FieldInfo from '../../components/actions/field-info.vue';
import FieldArgs from '../../components/actions/field-args.vue';

export default {
    components: {
        FieldInfo,
        FieldArgs,
    },
    props: {
        info: Object,
        editable: Boolean,
    }
}
</script>