<template>
    <div v-if="info && info.action">
        <template v-if="editable">
        <h3 class="mb-3">基础信息:</h3>
        <div class="mb-4">
            <p class="d-flex align-start mb-2">
                <v-icon left dense class="d-inline">mdi-shape-outline</v-icon>
                <span class="font-weight-bold flex-shrink-0">类型：</span><tag-list :tags="[info.action.tp]" /></p>
            <p class="d-flex align-start mb-2">
                <v-icon left dense class="d-inline">mdi-import</v-icon>
                <span class="font-weight-bold flex-shrink-0">输入：</span><tag-list any :tags="info.action.inputTps" /></p>
            <p class="d-flex align-start mb-2">
                <v-icon left dense class="d-inline">mdi-export</v-icon>
                <span class="font-weight-bold flex-shrink-0">输出：</span><tag-list any :tags="info.action.outputTps" /></p>
            <div class="d-flex align-start mb-1">
                <v-icon left dense class="d-inline">mdi-image-text</v-icon>
                <span class="font-weight-bold flex-shrink-0">说明：</span><span>{{info.action.desc}}</span>
            </div>
            <div v-if="info.action.descHtml" class="mb-2 ml-7 text-caption" style="white-space: pre-line;" v-html="info.action.descHtml"></div>
        </div>
        </template>

        <h3 class="mb-3">作者说明:</h3>
        <v-textarea
            class="field-info-desc"
            label="备注"
            outlined
            dense
            v-model="info.desc"
            :disabled="!editable"
            aria-hidden=""
            auto-grow
            rows="1"
        />
    </div>
</template>

<script>
import TagList from '../../components/TagList.vue';

export default {
    components: {
        TagList,
    },
    props: {
        info: Object,
        editable: Boolean,
    },
}
</script>

<style lang="scss">
.field-info-desc.v-input--is-disabled{

     input, textarea {
         color: var(--v-cblack-lighten2) !important;
     }
    
}
</style>