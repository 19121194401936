import $http from "../utils/http";
import $fs from "./fast";
import { deleteAllClips } from "../model/clip/common";


export function loginEmail (d) {
    return new Promise((resolve, reject)=>{
        $http.userLoginEmail(d, {
            loading: 2
        }).then(res=>{
            $fs.vuex('vuex_userinfo', res.data.userinfo)
            return resolve(res.data)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function userLogout () {
    return new Promise((resolve, reject)=>{
        $fs.vuex('vuex_userinfo', null)
        $fs.vuex('vuex_flowHistory', [])
        $fs.vuex('vuex_flows', {})
        $fs.vuex('vuex_flowDangerPass', {})
        deleteAllClips()
        $fs.db.clearAll()
        $http.userLogout().then(()=>{
            return resolve()
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function userRegEmail (d) {
    return new Promise((resovle, reject)=>{
        $http.userRegEmail(d, {
            loading: 2
        }).then(res=>{
            $fs.vuex('vuex_userinfo', res.data.userinfo)
            return resovle(res.data)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function userForgotEmail (d) {
    return new Promise((resolve, reject)=>{
        $http.userForgotEmail(d, {
            loading: 2
        }).then(res=>{
            return resolve(res)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function getUserInfo (loading) {
    return new Promise((resovle, reject)=>{
        $http.userInfo({}, {
            loading: loading
        }).then(res=>{
            $fs.vuex('vuex_userinfo', res.data.userinfo)
            return resovle(res.data)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function getPlanQrcode(d) {
    return new Promise((resovle, reject)=>{
        $http.planQrcode(d).then(res=>{
            return resovle(res.data.imgb64)
        }).catch(err=>{
            return reject(err)
        })
    })
}

const flowsInStore = function (ids) {
    let flows = []
    let saved = $fs.state.vuex_flows
    ids.forEach(x=>{
        if (saved[x]) flows.push(saved[x])
    })
    return flows
}

export function reloadUserFlows() {
    return new Promise((resovle, reject)=>{
        $http.reloadUserFlows({}, {
            loading: -1
        }).then(res=>{
            let historyIds = $fs.state.vuex_flowHistory
            let newIds = res.data.items.map(x=>{
                $fs.vuex('vuex_flows.' + x.id, x)
                return x.id
            })
            for (const hid of historyIds) {
                if (newIds.indexOf(hid) == -1) {
                    newIds.push(hid)
                }
            }
            $fs.vuex('vuex_flowHistory', newIds)
            return resovle()
        }).catch(err=>{
            return reject(err)
        })
    })
}

// 清理掉不是自己的，和不在当前推荐里的，避免数量过多，下次时间太久
export function resetRecommandFlows () {
    let recids = $fs.getRecommandFlowIds()
    let fids = $fs.state.vuex_flowHistory
    let userid = $fs.getters.vuex_login
    let delids = []
    // 去除
    for (const flow of flowsInStore(fids)) {
        if (!flow.creator_id || (flow.creator_id == userid)) continue
        if (recids.indexOf(flow.id) > -1) continue
        delids.push(flow.id)
    }
    // 增补
    fids = fids.filter(x=>delids.indexOf(x) == -1)
    for (const recid of recids) {
        if (fids.indexOf(recid) > -1) continue
        fids.push(recid)
    }
    $fs.vuex('vuex_flowHistory', [].concat(fids))
}

export function getFlows (ids, reload) {
    return new Promise((resovle, reject)=>{
        let olds = flowsInStore(ids)
        let oldIds = olds.map(x=>x.id)
        if (reload) oldIds = []
        let leftIds = ids.filter(x=>oldIds.indexOf(x) == -1)
        if (leftIds.length == 0) return resovle(olds)
        // 网络更新
        $http.getFlows({
            ids: leftIds.join(',')
        }).then(res=>{
            res.data.items.forEach(x=>{
                $fs.vuex('vuex_flows.' + x.id, x)
            })
            return resovle(flowsInStore(ids))
        }).catch(err=>{
            return reject(err)
            // return resovle([])
        })
    })
}

export function editFlow (f, loading) {
    return new Promise((resovle, reject)=>{
        $http.editFlow({
            flow: f
        }, {
            loading: loading || 2
        }).then(res=>{
            let flow = res.data.flow
            // 更新flows
            $fs.vuex('vuex_flows.' + flow.id, flow)
            // 更新flowHistory
            let flowHistory = $fs.state.vuex_flowHistory
            flowHistory = flowHistory.filter(x=>x != f.id)
            flowHistory.unshift(flow.id)
            $fs.vuex('vuex_flowHistory', flowHistory)
            return resovle(flow)
        }).catch((err)=>{
            return reject(err)
        })
    })
}

function deleteFlowLocal (f) {
    let flowHistory = $fs.state.vuex_flowHistory
    flowHistory = flowHistory.filter(x=>x != f.id)
    $fs.vuex('vuex_flowHistory', flowHistory)
    $fs.vuex('vuex_flows.' + f.id, null)
}

export function deleteFlow (f) {
    return new Promise((resovle, reject)=>{
        // 是否是我的
        let isMine = false
        let id = f.id
        let cid = f.creator_id
        let myid = $fs.state.vuex_userinfo && $fs.state.vuex_userinfo.id
        if (!id || !cid) isMine = true
        else isMine = myid && myid == cid
        // 弹出的消息
        let msg = {
            title: '从工作流列表中移除',
            text: '这工作流是他人创建。',
            confirm: ()=>{
                return resovle(deleteFlowLocal(f))
            }
        }
        if (isMine) {
            msg.title = '删除工作流'
            msg.text = '警告：确保此工作流没有被设置为任何其他的子工作流，否则运行那些主工作流时会出现错误。'
            msg.confirm = ()=>{
                $http.deleteFlow({
                    flow: f,
                }, {
                    loading: 2
                }).then(()=>{
                    deleteFlowLocal(f)
                    return resovle()
                }).catch((err)=>{
                    return reject(err)
                })
            }
        }
        $fs.modal(msg)
    })
}

export function sendAction (name, data, loading) {
    return new Promise((resovle, reject)=>{
        $http.sendAction({
            name, data
        }, {
            loading: loading
        }).then(res=>{
            return resovle(res.data)
        }).catch(err=>{
            return reject(err.error)
        })
    })
}

export function connectStatus (source, user_key) {
    return new Promise((resovle, reject)=>{
        let key = source + (user_key || '')
        let value = $fs.state.vuex_connects[key]
        if (value) return resovle(value)
        $http.connectStatus({
            source, user_key
        }).then(res=>{
            $fs.vuex('vuex_connects.' + key, res.data.result)
            return resovle(res.data.result)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function connectStatusAll (source, loading) {
    return new Promise((resovle, reject)=>{
        $http.connectStatusAll({
            source
        }, {
            loading: loading
        }).then(res=>{
            let uks = []
            for (const item of res.data.items) {
                let uk = item.user_key
                let k = source + uk
                $fs.vuex('vuex_connects.' + k, item)
                if (uk) uks.push(uk)
            }
            return resovle(uks)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function connectRename(source, user_key, newName) {
    return new Promise((resovle, reject)=>{
        $http.connectRename({
            source, user_key, newName
        }).then(res=>{
            $fs.vuex('vuex_connects.' + source + user_key, res.data.result)
            return resovle(res.data.result)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function connectSetDefault(source, user_key) {
    return new Promise((resovle, reject)=>{
        $http.connectSetDefault({
            source, user_key
        }, {
            loading: 2
        }).then(res=>{
            return resovle()
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function connectStart (source, user_key, d) {
    return new Promise((resovle, reject)=>{
        $http.connectStart({
            source, user_key, data: d
        }, {
            loading: 2
        }).then(res=>{
            return resovle(res.data)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function connectDelete (source, user_key, d) {
    return new Promise((resovle, reject)=>{
        $http.connectDelete({
            source, user_key, data: d
        }, {
            loading: 2,
        }).then(res=>{
            // 删除暂存的状态信息
            let key = source + (user_key || '')
            $fs.vuex('vuex_connects.' + key, null)
            return resovle(res.data)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function connectMake (source, user_key, make_name, make_data) {
    return new Promise((resovle, reject)=>{
        $http.connectMake({
            source, user_key, make_name, make_data
        }).then(res=>{
            return resovle(res.data)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function reportException(errinfo) {
    try {
        errinfo.ua = navigator.userAgent
        errinfo.userinfo = $fs.state.vuex_userinfo
    } catch (error) {/**/}
    try {
        $http.reportException(errinfo, {loading: -1})
    } catch (error) {
        console.log('reportExceptionError: ', errinfo, '||| catch: ', error)
    }
}
