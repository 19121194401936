<template>
    <v-container style="max-width: 500px;">
        <form v-if="isWeixin">
            <v-text-field
                v-model="email"
                :error-messages="emailErr"
                label="Email"
                required
                outlined
                placeholder=" "
                persistent-placeholder
                @blur="$v.email.$touch()"
                autocomplete="off"
            />
            <v-text-field
                v-model="password"
                type="password"
                :error-messages="passwordErr"
                label="密码"
                required
                outlined
                placeholder=" "
                persistent-placeholder
                @blur="$v.password.$touch()"
                autocomplete="off"
            />
            <v-checkbox
                class="mt-0 mb-4"
                v-model="checkbox"
                :error-messages="checkboxErr"
                @change="$v.checkbox.$touch()"
                @blur="$v.checkbox.$touch()"
            >
                <template v-slot:label>
                    同意<a href="#" class="ml-2" @click="enterTerms">用户协议</a>
                </template>
            </v-checkbox>
            <v-btn
                class="primary mr-2"
                @click="submit"
            >注册</v-btn>

            <v-btn
                to="/user/login"
                text
            >
                去登录
            </v-btn>
        </form>
        <div v-else>
            <div class="text-h6">请进入公众号获取测试资格</div>
             <img :src="qrWeixinImage" width="100%" max-width="600px"/>
        </div>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
import { userRegEmail } from "../../store/query";
import QRweixinImage from "../../assets/qrweixinlogin.png";


export default {
    mixins: [validationMixin],
    validations: {
        email: {required, email},
        password: {required, minLength: minLength(8), maxLength: maxLength(20)},
        checkbox: {
            checked: (v)=>v
        }
    },
    mounted () {
        if (this.vuex_userinfo) return this.$router.replace('/')
    },
    data () {
        return {
            email: '',
            password: '',
            checkbox: false,
            qrWeixinImage: QRweixinImage,
        }
    },
    computed: {
        isWeixin () {
            return true
            // try {
            //     let ua = navigator.userAgent.toLowerCase()
            //     return ua.indexOf('weixin') > -1 || ua.indexOf('wechat') > -1
            // } catch (error) {
            //     return false
            // }
        },
        emailErr () {
            const err = []
            if (!this.$v.email.$dirty) return err
            !this.$v.email.email && err.push('Must be valid email.')
            !this.$v.email.required && err.push('Email is required.')
            return err
        },
        passwordErr () {
            const err = []
            if (!this.$v.password.$dirty) return err
            !this.$v.password.required && err.push('Password is required.')
            !(this.$v.password.minLength && this.$v.password.maxLength) && err.push('Password length must be 8~20.')
            return err
        },
        checkboxErr () {
            const err = []
            if (!this.$v.checkbox.$dirty) return err
            !this.$v.checkbox.checked && err.push('Must agree to continue.')
            return err
        }
    },
    methods: {
        enterTerms () {
            window.open('https://help.copyauto.cn/copyauto-cn-terms', '_blank')
        },
        submit () {
            this.$v.$touch()
            for (const err of [this.emailErr, this.passwordErr, this.checkboxErr]) {
                if (err.length > 0) return
            }
            userRegEmail({
                email: this.email,
                password: this.password,
            }).then(()=>{
                this.$router.replace('/')
            }).catch(err=>{
                this.$fs.toast(err.toast)
            })
        }
    }
}
</script>