<template>
    <div class="white">
        <draggable v-if="flow" v-model="dragItems" handle=".draghandle" :sort="!!enableDrag">
            <template v-for="item, i in items">
                <action-card
                    :item="item"
                    :key="item.id"
                    :showArrow="showArrow"
                    :showDelete="showDelete"
                    :enableDrag="enableDrag"
                    @delete="deleteIndex(i)"
                    @click.native="tapIndex(i)"
                />
            </template>
        </draggable>
        <v-row v-else class="pb-6">
            <template v-for="item, i in items">
                <v-col :key="item.id" class="d-flex mb-n5">
                    <action-card
                        class="flex-grow-1"
                        :item="item"
                        @delete="deleteIndex(i)"
                        @click.native="tapIndex(i)"
                        :showArrow="showArrow"
                        :showDelete="showDelete"
                    />
                </v-col>
                <v-responsive
                    v-if="(i + 1) % countInRow === 0"
                    :key="`width-${i}`"
                    width="100%"
                ></v-responsive>
            </template>
        </v-row>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import ActionCard from './ActionCard.vue';

export default {
    components: {
        draggable,
        ActionCard,
    },
    props: {
        items: Array,
        flow: Boolean,
        showArrow: Boolean,
        showDelete: Boolean,
        enableDrag: Boolean,
    },
    computed: {
        dragItems: {
            get () {
                return this.items
            },
            set (value) {
                this.$emit('dragItems', value)
            }
        },
        countInRow () {
            switch (this.$vuetify.breakpoint.name) {
                case 'sm': return 1
                case 'md': return 2
                case 'lg': return 2
                case 'xl': return 2
                default: return 1
            }
        }
    },
    methods: {
        tapIndex (i) {
            this.$emit('tapIndex', i)
        },
        deleteIndex (i) {
            this.$emit('deleteIndex', i)
        },
    }
}
</script>
