const { CONTENT_TP } = require('../constants')
import { Base64 } from "js-base64";
let Base = require('./common/base')
let Utils = require('./common/utils')

class Base64Encode extends Base.Action {
    constructor () {
        super(
            'base64Encode',
            'Base64编码',
            '将内容转为Base64编码',
        )
        this.inputTps = [CONTENT_TP.TEXT, CONTENT_TP.HTML, CONTENT_TP.IMAGE]
        this.outputTps = [CONTENT_TP.TEXT]
        this.args = [{
            key: 'isUrlSafe',
            name: 'URL Safe',
            desc: '填写任意值即要求编码URL Safe'
        }]
    }
    async run({info, input}) {
        let res = input.raw
        let urlsafe = info.args.isUrlSafe
        if (input.contentTp == CONTENT_TP.HTML) res = Utils.html2text(res)
        if (input.contentTp == CONTENT_TP.IMAGE) res = input.raw
        else res = Base64.encode(res, !!urlsafe)
        return {
            contentTp: CONTENT_TP.TEXT,
            raw: res,
            text: res,
        }
    }
}

module.exports = new Base64Encode()