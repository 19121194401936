<template>
  <v-app>
    <main-header />

    <v-main
      :class="{
        'mt-ondesktop': client_insetTitleBar,
      }"
    >
      <div class="desk-app-title-bar" v-if="client_insetTitleBar">
        <div class="bg"></div>
      </div>
      <mgb-loading-mask />
      <mgb-modal />
      <mgb-toast />
      <!-- clip选择flow弹出层 -->
      <v-dialog
        :value="vuex_clipsPopup.length > 0"
        fullscreen
        persistent
        :transition="'dialog-bottom-transition'"
      >
        <keep-alive>
          <clip-select-popup v-if="vuex_clipsPopup.length > 0"/>
        </keep-alive>
      </v-dialog>

      <!-- 主内容 -->
      <v-fade-transition hide-on-leave>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <router-view v-if="!$route.meta.keepAlive" />
      </v-fade-transition>
    </v-main>

    <flow-danger-modal />

    <!-- 站位 -->
    <v-footer padless style="z-index: 0; opacity: 0.01">
      <v-col class="text-caption grey--text" cols="12">
        <div style="opacity: 0">CopyAuto</div>
      </v-col>
    </v-footer>
    <!-- 展现 -->
    <v-footer absolute padless class="white" style="z-index: 0">
      <v-col
        class="text-caption grey--text font-weight-light"
        cols="12"
        :class="{
          'text-right': $vuetify.breakpoint.name != 'sm',
          'text-center':
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'xs',
        }"
      >
        {{ new Date().getFullYear() }} © CopyAuto
        <a
          href="http://beian.miit.gov.cn/"
          class="grey--text"
          style="text-decoration: none; border-bottom: 1px dashed !important"
          target="_blank"
          >鄂ICP备16020550号-7</a
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import mgbModal from "./components/common/mgb-modal.vue";
import MgbToast from "./components/common/mgb-toast.vue";
import MgbLoadingMask from "./components/common/mgb-loading-mask.vue";
import { clipboardChecker, nextClips, popupSelectClips } from "./model/clip/common";
import $fs from "./store/fast";
import FlowDangerModal from "./components/FlowDangerModal.vue";
import { getUserInfo } from "./store/query";
import UmengStats from "./mixins/umeng";
import MainHeader from "./components/MainHeader.vue";
import ClipSelectPopup from "./views/clip/Select.vue";

export default {
  components: {
    mgbModal,
    MgbToast,
    MgbLoadingMask,
    FlowDangerModal,
    MainHeader,
    ClipSelectPopup,
  },
  name: "App",
  mixins: [UmengStats],
  data() {
    return {
        intervalReader: null,
        intervalAlive: null,
    };
  },
  watch: {
    vuex_flowHistory(v) {
      if (v && v.length > 0) return;
      this.$fs.vuex("vuex_flowHistory", this.$fs.getRecommandFlowIds());
    },
  },
  created() {
    document.title = "CopyAuto - 跨平台剪贴板增强工具";
    if (this.$fs.desk) {
      this.$fs.desk.loadClientInfo().then(()=>{
          this.intervalReader = this.initIntervalClipboardReader()
          this.intervalAlive = this.$fs.desk.aliveInterval()
      });
    }
  },
  async mounted() {
    // 重制watch一下vuex_flowHistory
    this.$fs.vuex("vuex_flowHistory", [].concat(this.vuex_flowHistory));
    // 默认获取一次剪贴板
    await nextClips();
    // 全局先读取一次数据库
    await clipboardChecker();
    // 聚焦时，如果有新的内容，则直接弹出选择层
    window.addEventListener("focus", this.focusListener);
    // 失焦时
    window.addEventListener("blur", this.blurListener);
    // 监控页面内的复制行为，也进行剪贴板更新
    window.addEventListener("copy", this.copyListener);
    // 更新用户信息
    if (this.vuex_userinfo) getUserInfo(-1);
  },

  beforeDestroy() {
    if (this.intervalReader) clearInterval(this.intervalReader)
    if (this.intervalAlive) clearInterval(this.intervalAlive)
    window.removeEventListener("focus", this.focusListener);
    window.removeEventListener('blur', this.blurListener)
    window.removeEventListener("copy", this.copyListener);
  },
  methods: {
    async focusListener() {
      let foundNewClip = await clipboardChecker();
      // 发现新的复制，且在首页，且没有进行中的flow
      if (
        foundNewClip &&
        this.$route.path == "/" &&
        $fs.state.vuex_runFlowsCountLeft == 0
      ) {
        popupSelectClips([])
      }
    },
    blurListener () {
      let maxLen = 100
      if (this.vuex_clips.length > maxLen) {
        let newlist = this.vuex_clips.slice(0, maxLen)
        this.$fs.vuex('vuex_clips', newlist)
        this.$nextTick(()=>{
          this.$vuetify.goTo(0)
        })
      }
    },
    copyListener() {
      clipboardChecker();
    },
    initIntervalClipboardReader() {
        if (this.client_info.platform) this.$fs.vuex('vuex_clipboardReadType', 'forever')
        return setInterval(() => {
            if (this.vuex_clipboardReadType == 'forever') clipboardChecker()
        }, 800);
    }
  },
};
</script>

<style lang="scss">
.desk-app-title-bar {
  -webkit-user-select: none;
  -webkit-app-region: drag;
  height: 44px;
  position: fixed;
  left: 0;
  top: 0;
  // right: 12px;
  right: 0px;
  z-index: 500;
  pointer-events: none;

  .bg {
    height: 22px;
    background: #fff;
    left: 0;
    right: 0;
  }
}

.tool-bar {
  position: fixed !important;
  top: 0;
  right: 12px;
  left: 0;
  z-index: 5;
  max-width: 950px !important;
}

.mt-ondesktop {
  margin-top: 22px !important;
}

.tp-ondesktop {
  top: 22px !important;
}

.ca-search-bar {
  height: 36px !important;

  .v-input__control {
      min-height: 36px !important;
    }
}

.ca-solo-input {
  
  margin-right: 6px !important;
  font-size: 14px;

  .v-input__slot {
      background: var(--v-grey-lighten4) !important;
    }

    

    .v-input__icon--prepend-inner {
    margin-left: -4px;
    
  }

  .v-icon {
    font-size: 18px;
    margin-top: 2px;
  }
}

.ca-solo-textarea {
  .v-input__control {
    min-height: 36px !important;
  }

  textarea {
    line-height: 1.5rem !important;
    min-height: 24px;
    margin-bottom: 6px !important;
  }
  
}
</style>