var PouchDB = require('pouchdb-browser').default;
PouchDB.plugin(require('pouchdb-find').default);

class Cdb {
    constructor() {
        console.log('created ca db')
        this.db = new PouchDB('copyautodb', {
            rev_limit: 1,
            auto_compaction: true,
        })
        // clip的搜索index
        this.db.createIndex({
            index: {
                fields: ['_id', 'text'],
                ddoc: "clip-query-index"
            }
        })
    }
    clearAll() {
        let db = this.db
        db.allDocs().then((result)=>{
            return Promise.allSettled(result.rows.map((row)=>{
                return db.remove(row.id, row.value.rev);
            }));
        })
    }
}

module.exports = new Cdb()