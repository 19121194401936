class Connect {
    constructor (id, name, desc) {
        this.id = id
        this.name = name
        this.desc = desc
        this.hide = false
        this.descHtml = desc
    }
}

module.exports = {
    Connect,
}