import { toggleSelectAll } from "../model/clip/common";

export default {
    props: {
        disableShortKey: Boolean,  // 禁止使用快捷键
    },
    data () {
        return {
            activeIndex: -1,
            pageActive: false,
        }
    },
    activated () {
        this.pageActive = true
        // 首页设置了的切换时，不默认消除
        if (this.setActiveIndex === undefined) this.resetActiveCard()
    },
    deactivated () {
        this.pageActive = false
    },
    computed: {
        clipListHotkeyKeymap () {
            return {
                'upArrow': this.shortKeyUpArrow,
                'downArrow': this.shortKeyDownArrow,
                'enter': this.shortKeyEnter,
                'esc': this.shortKeyEsc,
                'u': this.shortKeyUpload,
                'ctrl+a': this.shortKeySelectAll,
                'command+a': this.shortKeySelectAll,
                'delete': this.shortKeyDelete,
                'backSpace': this.shortKeyDelete,
                // 数字键直接复制列表对应内容
                ...(()=>{
                    let nums = {}
                    for (let i = 1; i < 10; i++) {
                        let num = i.toString()
                        nums[num] = this['shortKeyNumber' + num]
                    }
                    return nums
                })(),
            }
        },
    },
    methods: {
        ...(()=>{
            let kv = {}
            for (let i = 1; i < 10; i++) {
                kv['shortKeyNumber' + i.toString()] = function() {
                    if (!!this.disableShortKey ||!this.pageActive) return
                    this.changeActiveIndex(i - 1)
                    this.shortKeyEnter()
                    this.changeActiveIndex(i - 1)
                }
            }
            return kv
        })(),
        shortKeyUpload () {
            if (!!this.disableShortKey ||!this.pageActive || this.hideCloud) return
            if (this.activeIndex < 0) return
            this.cloudIndex(this.activeIndex)
        },
        shortKeyEsc () {
            if (!!this.disableShortKey ||!this.pageActive) return
            this.resetActiveCard()
            this.$vuetify.goTo(0)
        },
        shortKeySelectAll () {
            if (!!this.disableShortKey ||!this.pageActive || !this.multiSelect) return
            toggleSelectAll()
        },
        shortKeyUpArrow () {
            this.changeActiveIndex(this.activeIndex - 1)
        },
        shortKeyDownArrow () {
            this.changeActiveIndex(this.activeIndex + 1)
        },
        changeActiveIndex (v) {
            if (!!this.disableShortKey ||!this.pageActive) return
            let ni = v
            if (ni < 0) ni = this.items.length - 1
            if (ni >= this.items.length) ni = 0
            this.activeIndex = ni
        },
        shortKeyEnter (v) {
            if (!!this.disableShortKey ||!this.pageActive) return
            if (this.activeIndex < 0) return
            this.copyIndex(this.activeIndex)
        },
        shortKeyDelete () {
            if (!!this.disableShortKey ||!this.pageActive) return
            if (this.activeIndex < 0) return
            this.deleteIndex(this.activeIndex)
        },
        resetActiveCard () {
            this.activeIndex = -1
        },
    }
}