<template>
    <v-container class="pt-0"> 
        <v-toolbar flat>
            <v-text-field
                placeholder="搜索我的工作流"
                v-model="searching"
                prepend-inner-icon="mdi-magnify"
                @click:clear="tapClearSearching"
                clearable
                hide-details=""
                autocomplete="off"
            />
            <v-btn @click="$router.push('/flow/edit')" text class="mr-n3 ml-3">
                <v-icon left>mdi-plus</v-icon>
                创建
            </v-btn>
        </v-toolbar>
        <flow-list
            :items="flows"
            show-edit
            @tapIndex="tapFlowEditIndex"
            bg-text="编辑"
            edit-text="移除"
            @editIndex="deleteFlowIndex"
        />
    </v-container>
</template>

<script>
import FlowList from '../../components/FlowList.vue';
import { getFlows, deleteFlow, resetRecommandFlows } from "../../store/query";

export default {
    components: {
        FlowList,
    },
    data () {
        return {
            allFlows: [],
            flows: [],
            searching: null,
        }
    },
    mounted () {
        this.reloadFlows()
    },
    watch: {
      searching (val) {
          if (val === '') return this.tapClearSearching()
          val && this.doSearch(val)
      }
  },
    methods: {
        reloadFlows () {
            getFlows(this.vuex_flowHistory).then((flows)=>{
                this.allFlows = [].concat(flows)
                this.flows = flows
                resetRecommandFlows()
            })
        },
        deleteFlowIndex (i) {
            let flow = this.flows[i]
            deleteFlow(flow).then(()=>{
                this.$fs.toast('Delete successfully.')
                this.reloadFlows()
            }).catch(err=>{
                this.$fs.toast(err.toast)
            })
        },
        tapFlowEditIndex (i) {
            this.$router.push('/flow/edit?id=' + this.flows[i].id)
        },
        doSearch (val) {
            this.flows = this.allFlows.filter(e=>{
                let txt = e.name
                return txt.toLowerCase().indexOf((val || '').toLowerCase()) > -1
            })
        },
        tapClearSearching () {
            this.flows = [].concat(this.allFlows)
        },
    }
}
</script>