import axios from "axios";
import Vue from 'vue'
import fast from '../store/fast'
import apiConfigs from './apis'
import $router from "../router/index";

let config = {
    timeout: 15 * 1000,
    baseURL: '/api1'
}

const http = axios.create(config)

Vue.prototype.axios = http

http.interceptors.request.use((config)=>{
    config.headers['Content-Type'] = 'application/json';
    config.headers['Uid'] = fast.state.vuex_userinfo && fast.state.vuex_userinfo.id || ''
    return config
}, err => {
    return Promise.reject(err)
})

http.interceptors.response.use((res)=>{
    if (res.data.error) {
        return Promise.reject({
            response: res,
            status: res.status,
        })
    }
    return res
})

const req = function (url, method, data, cfg) {
    return new Promise((resolve, reject)=>{
        // LOADING
        cfg = cfg || {}
        if (cfg.loading == 2) fast.showLoading('mask')
        else if (cfg.loading != -1) fast.showLoading()
        // METHOD
        method = method || 'get'
        if (method == 'get') data = {
            params: data
        }
        // START
        http[method](url, data).then(res=>{
            resolve(res)
        }).catch((err)=>{
            let resp = err.response
            let errmsg = resp && resp.data && resp.data.error || err.message
            reject({
                error: errmsg || reqerrmsg,
                response: resp,
                toast: {
                    text: `Error: ${errmsg}`,
                    config: {
                        time: 5 * 1000,
                        color: 'error',
                    }
                }
            })
            let statusCode = resp && resp.status
            if (statusCode === 401) $router.push(`/user/login?force=${resp.status}`)
        }).finally(()=>{
            if (cfg.loading != -1) fast.hideLoading()
        })
    })
}

const buildReqs = (apiCfg)=>{
    let services = {}
    apiCfg.names.forEach(item=>{
        services[item.name] = function (data, cfg) {
            return req(item.url, item.method, data, cfg)
        }
    })
    return services
}

export default {
    ...buildReqs(apiConfigs)
}