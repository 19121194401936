let Base = require('./common/base')

class HtmlToText extends Base.Action {
    constructor () {
        super(
            'htmlToText',
            'HTML转纯文本',
            '去掉文本样式，转换为纯文本',
        )
        this.inputTps = [Base.CONTENT_TP.TEXT, Base.CONTENT_TP.HTML]
        this.outputTps = [Base.CONTENT_TP.TEXT]
    }
    async run({input}) {
        let txt = input.raw.replace(/<[^>]+>/gm, '')
        txt = txt.trim()
        return {
            raw: txt,
            text: txt,
            contentTp: Base.CONTENT_TP.TEXT
        }
    }
}

module.exports = new HtmlToText()