let Base = require('./common/base')
let Utils = require('./common/utils')

class FileDownload extends Base.Action {
    constructor () {
        super(
            'fileDownload',
            '下载文件',
            '下载图片或URL',
        )
        this.args = [{
            key: 'fileName',
            desc: '可选。可使用随机数参数{{rand}}。比如可设置为 image-{{rand}}',
            name: '自定义文件名'
        }]
        this.inputTps = [Base.CONTENT_TP.TEXT, Base.CONTENT_TP.IMAGE, Base.CONTENT_TP.ARRAY]
        this.outputTps = [Base.CONTENT_TP.ORIGIN]
    }
    async run ({info, input, gc}) {
        let arr = Utils.inputToArray(input)
        for (const x of arr) runOne(info, x, gc)
        return input
    }
}

function runOne(info, input, gc) {
    let value = input.raw
    let filename = info.args.fileName
    if (filename) {
        let ts = Date.now() + Math.floor(Math.random()*1000)
        filename = Utils.replaceBracketValues(filename, {rand: ts.toString().slice(-10)})
        filename = Utils.replaceBracketValues(filename, gc)
    }
    var a = document.createElement('a')
    a.href = value
    a.target = '_blank'
    a.download = filename || 'file'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

module.exports = new FileDownload()