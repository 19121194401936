import store from "./index";

function getRecommandFlowIds() {
    return[
        '610fbb5a0dbd3453d4dc6853', // OCR识别图片中的文字
        '610a6bcd0dbd3453d4d212bd', // 识别图片里的文字，并翻译成中文
        '610262641de21d3e072bfae0', // 将英文标点替换为中文
        '610fbc34cc3b443d8d33d2dd', // 翻译成中文
        '610fbc690dbd3453d4dc6b47', // 翻译成英文
        '610fbcf00dbd3453d4dc6c49', // 翻译成日文
        '610fbf3f0dbd3453d4dc7002', // 上传Notion
        '610fbf82cc3b443d8d33d982', // 上传至OneNote
        '610265bf1de21d3e072c03be', // 上传至OneDrive
        '610fc8eecc3b443d8d33eda1', // 上传只七牛云储存
        '61026ed21de21d3e072c1bf8', // 批量打开翻译网站
        '610fbd5acc3b443d8d33d55c', // 图片压缩
        '6102c9ef1de21d3e072ca58d', // 图片压缩并修改为JPEG格式后下载
        '610fd78d0dbd3453d4dca350', // base64 编码
        '610fd8c6cc3b443d8d340a99', // base64 解码
    ]
}

const clientDownloadModal = (client)=>{
    let cs = {
        win: {
            name: 'Windows（绿色免安装）',
            fileSize: '86.6',
            url: 'https://qimg1c.copyauto.cn/CopyAuto-0.1.0-win.zip'
        },
        mac: {
            name: 'Mac',
            fileSize: '84.7',
            url: 'https://qimg1c.copyauto.cn/CopyAuto-0.1.0.dmg'
        },
    }
    let v = cs[client || 'win']
    modal({
        title: `下载${v.name}客户端`,
        text: `客户端大小：${v.fileSize}M`,
        confirm: ()=>{
            window.open(v.url, '_blank')
        },
        confirmText: '下载',
    })
}

const db = store.state.vuex_db

const desk = store.state.vuex_desk

const vuex = (name, value) =>{
    store.commit('$mgbSet', {name, value})
}

const showLoading = (mask)=>{
    if (mask) {
        if (store.state.vuex_loadingMask) return
        vuex('vuex_loadingMask', true)
    } else {
        if (store.state.vuex_loading) return
        vuex('vuex_loading', true)
    }
}

const hideLoading = ()=>{
    vuex('vuex_loading', false)
    vuex('vuex_loadingMask', false)
}

const modal = (x)=>{
    let old = store.state.mgb_modals
    old.push(x)
    vuex('mgb_modals', old)
    if (!store.state.mgb_modalShow) vuex('mgb_modalShow', true)
}

const toast = (txt, configs)=>{
    if (typeof(txt) != 'string') {
        configs = txt.config
        txt = txt.text
    }
    let old = store.state.mgb_toasts
    old.push({
        text: txt,
        ...(configs || {})
    })
    vuex('mgb_toasts', old)
    if (!store.state.mgb_toastShow) vuex('mgb_toastShow', true)
}

export default {
    desk,
    db,
    vuex,
    state: store.state,
    getters: store.getters,
    showLoading,
    hideLoading,
    modal,
    toast,
    getRecommandFlowIds,
    clientDownloadModal,
}