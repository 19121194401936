<template>
    <div>
        <v-dialog
            persistent
            v-model="showStep"
            scrollable
            max-width="600px"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title>最后几项，待你完成...</v-card-title>
                <!-- <v-card-subtitle class="mt-n3 mb-n3">由于被设置为草稿，完成剩余内容后，方可使用。</v-card-subtitle> -->
                <v-divider />
                <v-card-text>
                    <div v-for="action, i in actionsLeft" :key="action.id">
                        <div class="sample-step-title mt-2 mb-2 primary--text title">第{{i + 1}}项</div>
                        <field-info :info="action" :editable="false" />
                        <field-args :info="action" :editable="false" />
                    </div>
                    <div>
                        <div class="sample-step-title mt-2 mb-3 primary--text title">
                            最后，给工作流起个新名字
                        </div>
                        <v-text-field
                            label="工作流名称"
                            outlined
                            dense
                            v-model="flow.name"
                            maxlength="50"
                            autocomplete="off"
                            :error-messages="invalidName && '* 新名称不能与原名称相同。' || ''"
                        />
                    </div>
                </v-card-text>
                <v-divider />
                <v-card-actions class="pb-4">
                    <v-spacer />
                    <v-btn text class="" @click="showStep = false">返回自定义编辑</v-btn>
                    <v-btn text class="primary" @click="tapSubmit">完成</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FieldArgs from '../../components/actions/field-args.vue'
import fieldInfo from '../../components/actions/field-info.vue'
export default {
  components: { fieldInfo, FieldArgs },
    props: {
        flow: Object,
        actions: Array,
    },
    data () {
        return {
            showStep: false,
            oldName: this.flow.name,
        }
    },
    inject: [
        'changeConfigSample',
        'checkStatusTp',
        'tapSaveAs',
    ],
    computed: {
        actionsLeft () {
            return this.actions.filter(x=>{
                return x.errors && x.errors.length > 0
            })
        },
        invalidName () {
            return !this.flow.name || (this.flow.name === this.oldName)
        },
    },
    mounted () {
        let f = this.flow
        if (f.statusTp !== 'sample') return
        if (f.creator_id && f.creator_id === this.vuex_login) return
        this.showStep = true
    },
    methods: {
        tapSubmit () {
            this.checkStatusTp()
            if (this.actionsLeft.length > 0) return
            if (this.invalidName) return
            this.changeConfigSample(false)
            this.showStep = false
            this.tapSaveAs()
        }
    }
}
</script>

<style lang="scss">
.sample-step-title {
    border-left: 10px solid var(--v-primary-base);
    padding-left: 10px;
}
</style>