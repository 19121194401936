<template>
  <div class="clip-list-local">
    <clip-list
      :items="clips"
      multi-select
      show-delete
      hide-empty
      :auto-load="!!clips.length"
      @loadMore="onLoadMore"
      :loading="vuex_clipsLoading"
      @tapIndex="tapIndex"
      @selectIndex="selectIndex"
      @deleteIndex="deleteIndex"
      @editDescIndex="editDescIndex"
      :set-active-index="vuex_clipsLocalSetActiveIndex"
    />

    <empty-intro v-if="clips.length === 0" />

    <!-- 底部多选时的按钮 -->
        <div
        v-if="vuex_clipsSelected.length > 0"
        class="clips-handlers pa-2 pb-3 white d-flex"
        >
        <v-btn @click="selectAllClips" text class="mr-3">
            <!-- 全选时变为取消全选 -->
            <v-icon v-if="vuex_clipsSelected.length != clips.length"
            >mdi-checkbox-multiple-marked-outline</v-icon
            >
            <v-icon v-else>mdi-minus-box-multiple-outline</v-icon>
        </v-btn>

        <v-btn
            @click="selectedClipsGoFlow"
            text
            class="mr-3 flex-grow-1 primary white--text"
            >选择工作流</v-btn
        >
        <v-btn @click="deleteSelects" text class="">
            <v-icon>mdi-delete-forever-outline</v-icon>
        </v-btn>
        </div>
  </div>
</template>

<script>
import ClipList from "../../components/ClipList.vue";
import EmptyIntro from "./Intro.vue";
import {
  deleteClips,
  nextClips,
  popupSelectClips,
  selectClip,
  unselectClip,
  toggleSelectAll,
  editClip,
} from "../../model/clip/common";

export default {
  components: { ClipList, EmptyIntro },
  name: "clipListLocal",
  computed: {
    clips() {
      return this.vuex_clips;
    },
  },
  methods: {
    onLoadMore() {
      nextClips();
    },
    //   点击打开某项
    tapIndex(i) {
      // console.log("tap:", i);
      popupSelectClips([this.clips[i].id]);
    },
    //   选择了某项
    selectIndex(i) {
      // console.log("select status: ", i, !!this.clips[i].selected);
      let clip = this.clips[i];
      if (this.vuex_clipsSelected.indexOf(clip.id) > -1) unselectClip(clip);
      else selectClip(clip);
    },
    //   删除某项
    deleteIndex(i) {
      deleteClips([this.clips[i]]);
    },
    editDescIndex ({index, desc}) {
      let clip = this.clips[index]
      editClip(clip, { desc })
    },
    //   选择的全部删除
    deleteSelects() {
      this.$fs.modal({
        title: "删除多条",
        text: "确认多条选中的内容吗？",
        confirm: () => {
          let cdelete = this.vuex_clipsSelected.map((x) => {
            return { id: x };
          });
          deleteClips(cdelete);
          this.$fs.toast("已成功删除多条。");
        },
      });
    },
    //   多选时打开选择工作流面板
    selectedClipsGoFlow() {
      popupSelectClips();
    },
    //   全选或取消全选
    selectAllClips() {
      toggleSelectAll();
    },
  },
};
</script>