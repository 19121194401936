let Base = require('./common/base')
let Utils = require('./common/utils')
import { addNote } from "../clip/common";

class WriteNoteImage extends Base.Action {
    constructor () {
        super(
            'writeNoteImage',
            '输出图片笔记',
            '将想要的内容输出为图片笔记',
        )
        this.inputTps = [Base.CONTENT_TP.IMAGE, Base.CONTENT_TP.ARRAY]
        this.outputTps = ['origin']
        this.tp = Base.TP.NOTE
    }
    async run ({input, originInput}) {
        let raw = Utils.inputToArray(input)
        for (const x of raw) runOne(x, originInput)
        return input
    }
}

function runOne (input, originInput) {
    let value = input.raw
    if (value.indexOf('data:image/') !== 0) throw `图片数据需要经过Base64编码，并以data:image/为开头。`
    addNote({
        targetClip: originInput,
        raw: value,
        contentTp: Base.CONTENT_TP.IMAGE
    })
}

module.exports = new WriteNoteImage()