<template>
    <div v-if="info && info.action.args.length > 0">
        <h3 class="mb-2">配置项:</h3>
        <template v-for="arg in info.action.args">   
        <div class="mb-4" :key="arg.key" v-if="!arg.hide && !isHide(arg)">
            <!-- 长文本框 -->
            <v-textarea
                v-if="arg.tp === 'textarea'"
                :label="arg.name || arg.key"
                :messages="arg.desc"
                v-model="info.args[arg.key]"
                aria-hidden=""
                outlined
                dense
                auto-grow
                rows="1"
                :placeholder="arg.placeholder || ''"
                :persistent-placeholder="!!arg.placeholder"
            >
                <template v-slot:prepend-inner v-if="arg.required || isPrivateKey(arg)">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on" color="error" small>mdi-hexagram</v-icon>
                        </template>
                        必填项
                    </v-tooltip>
                    <v-tooltip bottom v-if="isPrivateKey(arg)">
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on" class="ml-2 mr-2" color="error" small>mdi-lock</v-icon>
                        </template>
                        此数据被保护，仅创建者可获取可见。他人即使存为副本后也不可见。
                    </v-tooltip>
                </template>
            </v-textarea>
            <!-- 普通文本 -->
            <v-text-field
                v-else
                outlined
                dense
                autocomplete="off"
                :label="arg.name || arg.key"
                :messages="arg.desc"
                v-model="info.args[arg.key]"
                :placeholder="arg.placeholder || ''"
                :persistent-placeholder="!!arg.placeholder"
            >
                <template v-slot:prepend-inner v-if="arg.required || isPrivateKey(arg)">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on" color="error" small>mdi-hexagram</v-icon>
                        </template>
                        必填项
                    </v-tooltip>
                    <v-tooltip bottom v-if="isPrivateKey(arg)">
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on" class="ml-2 mr-2" color="error" small>mdi-lock</v-icon>
                        </template>
                        此数据被保护，仅创建者可获取可见。他人即使存为副本后也不可见。
                    </v-tooltip>
                </template>
            </v-text-field>
        </div>
        </template>
    </div>
</template>

<script>
import { ACTION_TP } from '../../model/constants'
export default {
    props: {
        info: Object,
        editable: Boolean,
    },
    methods: {
        isPrivateKey (arg) {
            let p = this.info.action.argsPrivateKey || {}
            return arg.key in p
        }, 
        isHide (arg) {
            // 不可编辑时，隐藏变量的Key
            if (this.editable) return false
            if (this.info.action.tp != ACTION_TP.VARIABLE) return false
            return arg.key == 'varKey'
        }
    }
}
</script>