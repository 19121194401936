<template>
    <v-container class="mb-5" style="max-width: 600px;" v-if="connectId">
        <h2 class="text-h6 font-weight-bold mb-2">连接：{{connectInfo.name}}</h2>
        <div v-if="connectInfo.descHtml" class="mb-3 text-caption" style="white-space: pre-line;" v-html="connectInfo.descHtml"></div>
        <template v-for="k in keys">
            <v-card :key="'comp-' + k" class="mb-5" outlined>
                <v-card-text>
                    <div class="d-flex align-center">
                        <v-btn v-if="!k" small outlined disabled>
                            默认
                        </v-btn>
                        <v-btn outlined small v-if="k && statusOn(k).id" class="primary--text" @click="tapSetDefault(k)">
                            设为默认
                        </v-btn>
                        <v-spacer />
                        <v-btn small v-if="statusOn(k).id" outlined class="error--text" @click="tapDelete({userkey: k})">
                            解除连接
                        </v-btn>
                    </div>
                     <div v-if="statusOn(k).id" class="mb-3 mt-2">
                        <div class="d-flex align-center flex-wrap">
                            <div>备注名称: {{statusOn(k).name || '未命名'}}</div>
                            <v-btn icon small class="ml-2" @click="tapRename(k)">
                                <v-icon color="cblack" small>mdi-pencil</v-icon>
                            </v-btn>
                            <v-spacer />
                        </div>
                        <div v-if="statusOn(k).created_at_ts">创建时间: {{statusOn(k).created_at_ts * 1000 | dateShow}}</div>
                    </div>
                    <component
                        :key="k"
                        v-if="connectComponent"
                        :is="connectComponent"
                        :user-key="k"
                        :connect-id="connectId"
                    />
                </v-card-text>
            </v-card>
        </template>
        <v-btn @click="addUserKey" class="primary--text" large block outlined>
            <v-icon left>mdi-plus</v-icon>
            添加
        </v-btn>

        <v-dialog
            v-model="showRename"
            persistent
            transition="dialog-transition"
            max-width="400px"
        >
            <v-card>
                <v-card-title primary-title>修改备注名称</v-card-title>
                <v-card-text class="mb-n2">
                    <v-text-field
                        outlined
                        dense
                        autocomplete="off"
                        v-model="renameText"
                        label="备注名称"
                        hide-details=""
                    />
                </v-card-text>
                <v-card-actions class="pb-5">
                    <v-spacer />
                    <v-btn text @click="showRename = false">取消</v-btn>
                    <v-btn color="primary" @click="doRename(renameKey, renameText)">保存</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Cmodel from "../../model/connect/index";
import { connectStatusAll, connectSetDefault, connectRename, connectDelete } from "../../store/query";
// 时间
import dayjs from "dayjs";

export default {
    data () {
        return {
            connectId: '',
            user_keys: [],
            showRename: false,
            renameKey: null,
            renameText: '',
        }
    },
    computed: {
        connectComponent () {
            if (!this.connectId) return null
            return ()=>import(`@/components/connects/${this.connectId}.vue`)
        },
        keys () {
            return [''].concat(this.user_keys)
        },
        connectInfo () {
            return Cmodel.on(this.connectId)
        }
    },
    filters: {
        dateShow (v) {
            return dayjs(v).format('YYYY-MM-DD HH:mm')
        }
    },
    mounted () {
        // 强制登录
        if (!this.vuex_login) return this.$router.replace('/user/login?force=connectPage')
        // 根据url获取页面的cid
        let cid = this.$route.query.id
        if (Cmodel.ids.indexOf(cid) == -1) return this.$fs.modal({
            title: '没有找到此服务',
            text: '确认后将返回之前页面。',
            confirm: ()=>{ this.$router.go(-1) },
            hideCancel: true,
        })
        this.getAllStatus(cid)
    },
    methods: {
        statusOn (k) {
            return this.vuex_connects[this.connectId + (k || '')] || {}
        },
        addUserKey () {
            if (!this.vuex_connects[this.connectId]) return this.$fs.toast('请先完成默认连接。')
            for (const uk of this.user_keys) {
                if (!this.vuex_connects[this.connectId + uk]) return this.$fs.toast('你还有未完成的连接。')
            }
            this.user_keys.push('new')
        },
        getAllStatus (cid) {
            connectStatusAll(cid, 2).then(userKeys=>{
                this.user_keys = userKeys
                this.connectId = cid
            }).catch(err=>{
                if (err) this.$fs.toast(err.toast)
                this.$fs.modal({
                    title: '获取连接详情失败',
                    text: '请稍后再试试。',
                    confirm: ()=>{ this.$router.go(-1) },
                    hideCancel: true,
                })
            })
        },
        tapSetDefault (userkey) {
            this.$fs.modal({
                title: '设置为默认连接',
                text: '工作流中使用此连接时，没有明确选择使用哪个时，使用此连接。',
                confirm: ()=>{this.doSetDefault(userkey)},
            })
        },
        tapRename (userkey) {
            this.renameKey = userkey
            this.showRename = true
            this.renameText = this.statusOn(userkey).name || ''
        },
        doSetDefault (userkey) {
            connectSetDefault(this.connectId, userkey).then(res=>{
                this.$fs.toast('已成功重设默认')
                this.$router.go(-1)
            }).catch(err=>{
                if (err) this.$fs.toast(err.toast)
            })
        },
        doRename (userkey, rename) {
            connectRename(this.connectId, userkey, rename).then(res=>{
                this.$fs.toast('名称修改成功')
                this.showRename = false
            }).catch(err=>{
                this.$fs.toast(err.toast)
                this.showRename = false
            })
        },
        tapDelete ({userkey, d}) {
            this.$fs.modal({
                title: '确认解除连接',
                text: '将取消授权并删除此相关连接的用户信息。',
                confirm: ()=>{
                    console.log(this.connectId, userkey, d)
                    connectDelete(this.connectId, userkey, d).then(res=>{
                        this.$fs.toast('已清除相关数据')
                        this.$router.go(-1)
                    }).catch(err=>{
                        this.$fs.toast(err.toast)
                    })
                }
            })
        }
    }
}
</script>