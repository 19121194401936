<template>
  <div>
    <drag-file :drop="vuex_clipsPopup.length === 0" v-if="!$vuetify.breakpoint.mobile"/>
    <v-container class="pa-0">
      <tool-btns
        @tabChange="onTabChange"
        @localSearch="onLocalSearch"
        @cloudSearch="onCloudSearch"
        @submitSearch="submitSearch"
      />

      <div class="clip-list-wrap">
        <!-- 云端搜索 -->
        <div v-if="queryCloud && cloudSearchTs">
            <keep-alive>
              <cloud-clip-search :query="queryCloud" :searchTs="cloudSearchTs"/>
            </keep-alive>
        </div>

        <!-- 云端列表 -->
        <div v-else-if="tabname === 'cloud'">
            <keep-alive>
              <cloud-clip-list />
            </keep-alive>
        </div>

        <!-- 本地搜索的列表 -->
        <div v-else-if="queryLocal">
            <keep-alive>
              <clip-list-search :query="queryLocal" />
            </keep-alive>
        </div>

        <!-- 本地历史记录的列表 -->
        <div v-else>
            <keep-alive>
              <clip-list-local />
            </keep-alive>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

import DragFile from "../../components/DragFile.vue";
import ToolBtns from "./Tools.vue";
import ClipListSearch from "./SearchLocal.vue";
import CloudClipList from "./Cloud.vue";
import CloudClipSearch from "./CloudSearch.vue";
import ClipListLocal from "./Local.vue";

export default {
  components: { DragFile, ToolBtns, ClipListSearch, CloudClipList, CloudClipSearch, ClipListLocal },
  data() {
    return {
      tabname: "local",
      queryLocal: "",
      queryCloud: "",
      cloudSearchTs: 0,
    };
  },
  watch: {
    tabname (v) {
      this.freshIsOnBaseBoard()
    },
    queryLocal (v) {
      this.freshIsOnBaseBoard()
    }
  },
  methods: {
    // 是否在首页，如果在搜索或云端页面执行完工作流后，toast提示让他去首页查看执行结果。
    freshIsOnBaseBoard () {
      this.$fs.vuex('vuex_clipsIsOnBaseBoard', !this.queryLocal && this.tabname == 'local')
    },
    onTabChange(v) {
      this.tabname = v;
      this.queryCloud = ''
      this.queryLocal = ''
      this.cloudSearchTs = 0
      // 切换标签
      let t = ''
      if (v === 'cloud') t = '云同步的记录'
      this.$fs.vuex('vuex_metaPageTitle', t)
    },
    onLocalSearch(q) {
      this.queryLocal = q;
    },
    onCloudSearch(q) {
      this.queryCloud = q
      if (!q) this.cloudSearchTs = 0
    },
    submitSearch (q) {
      if (this.tabname === 'cloud') {
        this.cloudSearchTs = Date.now()
      }
    },
  },
};
</script>

<style lang="scss">
.clip-list-wrap {
  padding-bottom: 60px;
}

.clips-handlers {
  z-index: 1;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}

.tab-btns {
  .v-btn {
    height: 36px !important;
    width: 36px !important;
    opacity: 1 !important;
  }

  .v-icon {
    color: var(--v-cblack-base) !important;
  }
}

.tab-btn-active {
  .v-icon {
    color: var(--v-primary-base) !important;
  }

  &::before {
    opacity: 0 !important;
  }
}
</style>