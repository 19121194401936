<template>
  <div>
    <clip-list
      :items="clips"
      :show-load="clips.length > 0"
      show-delete
      hide-cloud
      @loadMore="doLoadMore"
      :loading="loading"
      @tapIndex="tapIndex"
      @deleteIndex="deleteIndex"
      @editDescIndex="editDescIndex"
    />
  </div>
</template>

<script>
import ClipList from "../../components/ClipList.vue";
import _ from "lodash";
import ClipCloud from "../../model/clip/cloud";
import { popupSelectClips } from "../../model/clip/common";

export default {
  components: { ClipList },
  name: "CloudClipSearch",
  props: {
    query: String,
    searchTs: Number,
  },
  data() {
    return {
      clips: [],
      loading: false,
    };
  },
  watch: {
    searchTs (v) {
      this.doLoadMore('new')
    },
    loading(v) {
      this.$emit("showLoading", !!v);
    },
  },
  activated() {
    this.clips.splice(0);
    if (this.searchTs) this.doLoadMore('new')
  },
  deactivated() {
    this.$emit("showLoading", false);
    this.clips.splice(0);
  },
  methods: {
    // 登录提示
    checkLogin () {
        if (this.vuex_login) return
        this.$fs.toast('请先登录', {color: 'error'})
        return 'not login'
    },
    // 点击
    tapIndex(i) {
      popupSelectClips([this.clips[i]]);
    },
    // 删除
    deleteIndex(i) {
      if (this.checkLogin()) return
      ClipCloud.deleteClip(this.clips[i]).then((res) => {
        this.clips.splice(i, 1);
      });
    },
    editDescIndex ({index, desc}) {
      if (this.checkLogin()) return
        let clip = this.clips[index]
        ClipCloud.changeDesc(clip, desc).then(res=>{
            clip.desc = desc
            this.clips.splice(index, 1, clip)
        })
    },
    doLoadMore (renew) {
      if (this.checkLogin()) return
      if (this.loading) return;
      this.loading = true
      this.loadMore(renew)
    },
    loadMore: _.throttle(function (renew) {
      let cs = this.clips;
      ClipCloud.queryClips({
        query: this.query,
        last: !renew && cs.length > 0 && cs.slice(-1)[0],
      }).then((res) => {
        this.loading = false;
        let base = renew ? [] : this.clips
        this.clips = [].concat(base, res);
      })
    }, 1000, { trailing: false}),
  },
};
</script>