let Base = require('./common/base')
let Utils = require('./common/utils')

class OpenUrl extends Base.Action {
    constructor () {
        super(
            'openUrl',
            '打开链接',
            '在浏览器的新窗口打开链接',
        )
        this.inputTps = [Base.CONTENT_TP.TEXT, Base.CONTENT_TP.HTML, Base.CONTENT_TP.ARRAY]
        this.outputTps = [Base.CONTENT_TP.ORIGIN]
    }
    async run({input}) {
        let arr = Utils.inputToArray(input)
        for (const x of arr) runOne(x)
        return input
    }
}

function runOne (input) {
    let value = input.raw
    let contentTp = input.contentTp
    if (contentTp == Base.CONTENT_TP.HTML) value = Utils.html2text(value)
    if ((value.indexOf('http://') != 0) && (value.indexOf('https://') != 0)) value = 'http://' + value
    value = encodeURI(value)
    var a = document.createElement('a')
    a.href = value
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

module.exports = new OpenUrl()