let Base = require('./common/base')
import { sendAction } from "../../store/query";
import { CONTENT_TP, USER_LEVEL } from "../constants";
let Utils = require('./common/utils')

class FanyiBaidu extends Base.Action {
    constructor () {
        super(
            'fanyiBaidu',
            '翻译文本',
            '将文本翻译为其他语言',
        )
        this.inputTps = [CONTENT_TP.TEXT, CONTENT_TP.HTML]
        this.outputTps = [CONTENT_TP.TEXT]
        this.args = [{
            key: 'fanyiToLanguage',
            required: true,
            name: '翻译为目标语言'
        }]
        this.userLevel = USER_LEVEL.LOGIN
    }
    async run({info, input}) {
        let text = input.raw
        let tolang = info.args.fanyiToLanguage
        if (input.contentTp == CONTENT_TP.HTML) text = Utils.html2text(text)
        let res = await sendAction('fanyiBaidu', {
            text,
            to: tolang,
        }, -1)
        return {
            contentTp: CONTENT_TP.TEXT,
            raw: res.text,
            text: res.text,
        }
    }
}

module.exports = new FanyiBaidu()