<template>
    <div class="text-center">
        <v-overlay :value="vuex_runFlowsCountLeft !== 0"
            transition="fade-transition"
        >
            <div class="d-flex flex-column align-center">
                <v-progress-circular
                    indeterminate
                    size="32"
                />
                <div class="mt-3 white--text">
                    <span v-if="vuex_runFlowsCountLeft >= 0">还剩{{vuex_runFlowsCountLeft}}个</span>
                    <span v-else>当前工作流结束后将停止...</span>
                </div>
                <v-btn class="primary mt-3" v-if="vuex_runFlowsCountLeft > 1" @click="tapStop">
                    中断工作流
                </v-btn>
            </div>
        </v-overlay>
    </div>
</template>

<script>
export default {
    methods: {
        tapStop () {
            this.$fs.vuex('vuex_runFlowsCountLeft', -1)
        }
    }
}
</script>