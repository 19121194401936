<template>
    <div v-hotkey="clipListHotkeyKeymap">
        <draggable v-model="dragItems" handle=".draghandle" :sort="!!enableDrag">
        
        <v-card v-for="item, i in items" :key="item.id"
            :ripple="!item.showFull"
            @click.stop="tapIndex(i)"
            style="overflow: hidden;"
            elevation="0"
            tile
            :class="{
                'clip-show-full': item.showFull,
                'note-card': item.tp === 'note',
                'log-success-card': item.tp === 'log' && !item.logTp,
                'log-error-card': item.logTp === 'error',
                'log-warning-card': item.logTp === 'warning',
                'clip-list-active-card': i === activeIndex,
            }"
        >   
            <div v-intersect="activeCardOnScreen" v-if="i === activeIndex"></div>
            <v-card-text class="clip-content d-flex flex-row align-start">
                <!-- MultiSelect Checkbox -->
                <div class="lefter flex-shrink-0 mr-3" @click.stop v-if="enableDrag || multiSelect">
                    <div v-if="enableDrag" class="draghandle pl-1 pr-1">
                        <v-icon>mdi-drag</v-icon>
                    </div>

                    <v-checkbox class="mt-0 pt-0" hide-details v-if="multiSelect" :ripple="false" @click.native.stop :value="vuex_clipsSelected.indexOf(item.id) > -1" @change="selectIndex(i)"></v-checkbox>
                </div>

                <div class="mainer flex-grow-1">
                    <tag-list :tags="tags(item)" class="mb-2"/>
                    <!-- HTML -->
                    <div class="content-html rounded overflow-hidden"
                        v-if="item.contentTp == 'html'" v-html="cleanHtml(item.raw)" @click="clickhtml($event, i)"></div>
                    <!-- Image -->
                    <div v-else-if="item.contentTp == 'image'">
                        <img :src="item.raw" width="50%" class="rounded" />
                    </div>
                    <div v-else-if="item.contentTp === 'array'" class="content-text text-body-1">
                        {{item.text}}
                    </div>
                    <div class="content-text text-caption" v-else-if="item.tp == 'log'">{{item.raw | tooLong}}</div>
                    <!-- Other like text -->
                    <div class="content-text text-body-1" v-else>{{item.raw | tooLong}}</div>
                </div>
                
                <v-hover v-slot="{ hover }" v-if="!hideCloud || !hideCopy || !hideEditDesc">
                <div class="top-btns" >
                    <v-tooltip left nudge-left="-20" color="cblack" open-delay="300" :disabled="$vuetify.breakpoint.mobile">
                    <template v-slot:activator="{ on, attrs }">
                        <div class="top-btns-mask" v-bind="attrs" v-on="on" :class="{'mask-hover': hover}" @click.stop="copyIndex(i)"></div>
                    </template>
                    <span>选择 [↑↓]<br/>复制 [Enter]</span>
                    </v-tooltip>

                    <clip-list-item-btns :item="item" :index="i"
                        @copyIndex="copyIndex"
                        @cloudIndex="cloudIndex"
                        @deleteIndex="deleteIndex"
                        @editDescIndex="editDescIndex"
                    />
                </div>
                </v-hover>

            </v-card-text>

            <!-- 更多来源链接 和 介绍信息 -->
            <div class="clip-infos mb-4 ml-4" v-if="item.sourceUrl || item.desc" :class="{
                    'ml-13' : enableDrag || multiSelect,
                }">
                <!-- 来源url -->
                <v-btn class="mb-2 rounded-pill" small text v-if="item.sourceUrl" @click.stop="tapSourceUrl(item.sourceUrl)">
                    <v-icon class="mr-1" small>mdi-web</v-icon>
                    {{item.sourceUrl | sourceUrlShow}}</v-btn>
                <!-- 备注 -->
                <v-btn class="mb-2 rounded-pill" small text v-if="item.desc" @click.stop="tapDesc(i)">
                    <v-icon class="mr-1" small>mdi-pound-box</v-icon>
                    {{item.desc | descShow}}</v-btn>
            </div>

            <v-divider v-if="(activeIndex !== i) && (activeIndex !== i + 1)"/>
        </v-card>
        </draggable>

        <!-- 空列表并在加载状态 -->
        <v-fade-transition leave-absolute hide-on-leave>
        <div v-if="!hideEmpty && items.length === 0 && innerLoading" >
            <div v-for="i in 3" :key="'loading-ske-' + i">
                <v-skeleton-loader
                    class="pl-4 pr-4"
                    type="table-cell, paragraph"
                />
                <v-divider class="mt-3"/>
            </div>
        </div>
        </v-fade-transition>

        <v-fade-transition leave-absolute hide-on-leave>
        <div v-if="!hideEmpty && items.length === 0 && !innerLoading" class="text-center pt-10 pb-5">   
            <div><v-icon>mdi-format-quote-open</v-icon>
                <v-icon size="36" class="mt-6">mdi-help</v-icon>
                <v-icon>mdi-format-quote-close</v-icon></div>
            <div class="mt-3 text-body-2" style="opacity: .7">空</div>
        </div>
        </v-fade-transition>

        <!-- 滚动时自动加载 -->
        <div v-if="showLoad || autoLoad" class="ma-3">
            <v-btn text v-intersect="onLoadMore" elevation="0" block class="grey--text" @click="$emit('loadMore')">
                <div class="d-flex justify-center">
                    <div v-if="innerLoading">
                        <v-progress-circular size="16" width="3" class="mr-2" indeterminate />
                        <!-- 加载中... -->
                    </div>
                    <div v-else :style="{
                        'opacity': autoLoad ? 0.2 : 0.5 
                    }">
                        <v-icon size="16" class="mr-2">mdi-cursor-default-click-outline</v-icon>
                        加载更多
                    </div>
                </div>
            </v-btn>
        </div>
        
    </div>
</template>

<script>
import sanitizeHtml from "sanitize-html";
import draggable from "vuedraggable";
import TagList from './TagList.vue';
import { LOG_TP, CLIP_TP, CONTENT_TP } from "../model/constants";
import { writeClipboard } from "../model/clip/common";
import ClipCloud from "../model/clip/cloud";
import ClipListHotkey from "../mixins/clipListHotkey";
// 时间
import dayjs from "dayjs";
import ClipListItemBtns from './ClipListItemBtns.vue';
// require('dayjs/locale/zh-cn')
// var RelativeTime = require('dayjs/plugin/relativeTime')
// dayjs.extend(RelativeTime)

export default {
    name: 'ClipList',
    components: {
        draggable,
        TagList,
        ClipListItemBtns,
    },
    mixins: [ClipListHotkey],
    computed: {
        dragItems: {
            get () {
                return this.items
            },
            set (value) {
                this.$emit('dragItems', value)
            }
        },
    },
    props: {
        /*
            oid: id
            tp: 类型，默认clip为空，note
            contentTp: 默认text为空，image，html
            logTp: 默认空，error
            data: [base64]的列表，用于转成ClipboardItem
            raw: base64,
            disabled: 是否已被锁定
            showFull: 是否展开显示
            createdTs: 创建的时间
        */
        items: Array,
        multiSelect: Boolean,  // 是否允许多选
        showDelete: Boolean,  // 是否显示删除
        hideCloud: Boolean, // 是否展现上传到云端
        enableDrag: Boolean,  // 是否允许拖拽
        hideEditDesc: Boolean, // 是否显示编辑按钮
        loading: Boolean,  // 正在加载
        autoLoad: Boolean,  // 显示自动加载
        showLoad: Boolean,  // 显示加载条
        hideCopy: Boolean, // 隐藏复制按钮
        hideEmpty: Boolean, // 不显示空状态
        setActiveIndex: Number,  // 手动设置ActiveIndex
    },
    provide () {
        return {
            hideCloud: this.hideCloud,
            hideCopy: this.hideCopy,
            showDelete: this.showDelete,
            hideEditDesc: this.hideEditDesc,
        }
    },
    data () {
        return {
            innerLoading: false,
            // activeIndex的相关内容在mixin：ClipListHotkey里面
            lastActiveIndex: -1,
        }
    },
    watch: {
        loading (v) {
            if (v) this.innerLoading = true
            else setTimeout(() => {
                this.innerLoading = false
            }, 300);
        },
        setActiveIndex (v) {
            if (v < -1) return
            this.activeIndex = v
        }
    },
    filters: {
        tooLong (v) {
            if (!v) return ''
            if (v.length < 5000) return v
            return v.substr(0, 5000) + '...(最多显示5000字，点击右上角复制获取全部)'
        },
        sourceUrlShow (v) {
            let nv = v
            if (v.indexOf('http://') === 0) nv = v.substr(7)
            else if (v.indexOf('https://') === 0) nv = v.substr(8)
            if (nv.length > 38) nv = nv.substr(0, 38) + '...'
            return nv
        },
        descShow (v) {
            if (v.length > 38) return v.substr(0, 38) + '...'
            return v
        }
    },
    methods: {
        activeCardOnScreen (entries, observer, isIntersecting) {
            if (!isIntersecting && this.activeIndex != this.lastActiveIndex) {
                try {
                    this.lastActiveIndex = this.activeIndex
                    this.$vuetify.goTo('.clip-list-active-card')
                } catch (error) {/* */}
            }
        },
        tapSourceUrl (url) {
            window.open(url, '_blank')
        },
        onLoadMore (entries, observer, isIntersecting) {
            if (!this.loading && this.autoLoad && isIntersecting) {
                this.$emit('loadMore')
            }
        },
        tags (item) {
            let chips = []
            // note
            if (item.tp == CLIP_TP.NOTE) chips.push(CLIP_TP.NOTE)
            // log
            if (item.logTp == LOG_TP.ERROR) chips.push('logError')
            else if (item.logTp == LOG_TP.WARNING) chips.push('logWarning')
            else if (item.tp == CLIP_TP.LOG) chips.push('logSuccess')
            // contentTp
            if (item.contentTp == CONTENT_TP.HTML) chips.push(CONTENT_TP.HTML)
            else if (item.contentTp == CONTENT_TP.IMAGE) chips.push(CONTENT_TP.IMAGE)
            else if (item.contentTp === CONTENT_TP.ARRAY) chips.push(CONTENT_TP.ARRAY)
            if (item.tp == CLIP_TP.LOG || item.tp == CLIP_TP.NOTE) chips.push({
                title: dayjs(item.createdTs).format('HH:mm:ss'),
                icon: 'mdi-clock-outline',
                iconColor: 'cblack',
                tip: '创建时间'
            })
            return chips
        },
        cleanHtml (x) {
            let attrs = sanitizeHtml.defaults.allowedAttributes
            attrs['*'] = ['style']
            return sanitizeHtml(x, {
                allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
                allowedAttributes: attrs
            })
        },
        copyIndex (i) {
            this.resetActiveCard()
            writeClipboard(this.items[i])
        },
        cloudIndex (i) {
            // this.resetActiveCard()
            let clip = this.items[i]
            ClipCloud.uploadClip(clip)
        },
        tapIndex (i) {
            this.resetActiveCard()
            this.$emit('tapIndex', i)
        },
        selectIndex (i) {
            this.$emit('selectIndex', i)
        },
        deleteIndex (i) {
            this.resetActiveCard()
            this.$emit('deleteIndex', i)
        },
        tapDesc (i) {
            let clip = this.items[i]
        },
        editDescIndex ({index, desc}) {
            this.$emit('editDescIndex', {index, desc})
        },
        clickhtml (e, i) {
            this.resetActiveCard()
            if (!this.items[i].showFull) return e.preventDefault()
            if (e.target.href) {
                window.open(e.target.href, '_blank')
                return e.preventDefault()
            }
        }
    }
}
</script>


<style lang="scss">
.note-card {
    border-left: 12px solid var(--v-info-base) !important;
}

.log-success-card {
    border-left: 12px solid var(--v-primary-base) !important;
}

.log-error-card {
    border-left: 12px solid var(--v-error-base) !important;
}

.log-warning-card {
    border-left: 12px solid var(--v-warning-base) !important;
}

.clip-content {
    position: relative;
    max-height: 200px;
    -webkit-mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,.2)));

    .draghandle {
        height: 100%;
        // background: #fc3159;
        padding: 10px 0;
        margin: -10px 0;
    }

    .mainer {
        overflow: hidden;
    }

    .content-text {
        word-wrap: break-word;
        word-break: normal;
        overflow: hidden;
        white-space: pre-line;
    }

    // 选择框右边的默认去掉
    .v-input--selection-controls__input {
        margin-right: 0 !important;

        .theme--light.v-icon {
            color: rgba(0,0,0, .3);
            // background: #fc3159;
            margin: -500px -16px;
            padding: 0 26px;
        }
    }

    .top-btns {
        position: absolute;
        top: 0;
        right: 0px;
        bottom: 0;

        .top-btns-mask {
            position: absolute;
            background: white;
            width: 100%;
            height: 100%;
            opacity: .3;
            border-left: 1px dashed #ddd;
        }

        .mask-hover {
            // opacity: .6 !important;
            background: #ddd !important;
        }
    }

    
}

.clip-infos {
    .v-btn {
        display: block !important;
        font-weight: normal !important;
        color: var(--v-grey-darken2) !important;
        text-transform: none !important;
        background: linear-gradient(90deg, var(--v-grey-lighten3), white);
    }
}

.clip-show-full {
    cursor: default;

    .clip-content {
        max-height: none;
        -webkit-mask-image: none;
    }
}

.clip-list-active-card {
    border: 3px dashed var(--v-primary-base) !important;
}
</style>