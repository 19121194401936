const { CONTENT_TP } = require('../constants')
let Base = require('./common/base')
let Utils = require('./common/utils')
let replaceAll = require('replaceall')

class TextReplace extends Base.Action {
    constructor () {
        super('textReplace', '替换文字', 'Replace text with another one.')
        this.inputTps = ['text', 'html']
        this.outputTps = ['text']
        this.args = [{
            key: 'textReplaceFrom',
            required: true,
            name: '被替换的文字',
        }, {
            key: 'textReplaceTo',
            name: '替换为什么文字'
        }]
    }
    async run ({info, input, gc}) {
        console.log('text replace: ', info, input, gc)
        let raw = input.raw
        if (input.contentTp == CONTENT_TP.HTML) raw = Utils.html2text(raw)
        let tfrom = info.args.textReplaceFrom
        let tto = info.args.textReplaceTo
        tfrom = Utils.replaceBracketValues(tfrom, gc)
        tto = Utils.replaceBracketValues(tto, gc)
        if (tfrom == '\\n')  tfrom = /\n/g
        if (tfrom == '\\r')  tfrom = /\r/g
        // let output = raw.replaceAll(tfrom, tto)
        let output = replaceAll(tfrom, tto, raw)
        return {
            raw: output,
            text: output,
            contentTp: CONTENT_TP.TEXT,
        }
    }
}

module.exports = new TextReplace()