import { get } from "lodash";
import { CONTENT_TP } from "../../constants";

// 获取Action的详情
function actionOn (aid) {
    try {
        return require(`../${aid}.js`)
    } catch (error) {
        console.log('action on id not found: ', aid, error)
        return require(`../undefinedAction.js`)
    }
}

function inputToArray(input) {
    let raw = input.raw
    if (input.contentTp == CONTENT_TP.ARRAY) return raw
    if (Array.isArray(raw)) return raw
    return [input]
}

// 将双花括号里的内容进行替换
function replaceBracketValues(str, kvs, json) {
    return str.replace(/{{(.+?)}}/g, (_,g1) => {
        let v = get(kvs, g1)
        if (json) v = JSON.stringify(v)
        return v === undefined ? `{{${g1}}}` : v
    })
}

// {{rand}} 随机数替换
function replaceBracketRand(filename) {
    let ts = Date.now() + Math.floor(Math.random()*1000)
    return replaceBracketValues(filename, {rand: ts.toString().slice(-10)})
}

// HTML转TEXT
function html2text(txt) {
    return txt.replace(/<[^>]+>/gm, '')
}


module.exports = {
    actionOn,
    replaceBracketValues,
    replaceBracketRand,
    html2text,
    inputToArray,
}