<template>
    <div class="drag-file-btn" :class="{
        hide: !btn
    }">
        <drag-file
            ref="drag"
            v-model="files"
            @input-file="inputFile"
            @input-filter="inputFilter"
            :drop="drop && '#app'"
            :multiple="false"
            :drop-directory="false"
        >
            <!-- todo支持多选和文件夹，防止一次加入太多导致重名的问题，将insertClip做成队列-->
            <div v-if="btn" class="mt-1 font-weight-regular">
                <v-icon small class="mr-1">mdi-file-outline</v-icon>
                {{uploadText}}
            </div>
        </drag-file>

        <div class="drag-mask d-flex align-center justify-center" v-if="drop && $refs.drag && $refs.drag.dropActive">
            <div class="text-h3">放置文件</div>
        </div>
    </div>
</template>

<script>
import DragFile from "vue-upload-component";
import { addLog, addNote } from "../model/clip/common";
import { readBlobToBase64, readImageMeta } from "../model/clip/base";
import { LOG_TP } from "../model/constants";

export default {
    components: {
        DragFile
    },
    props: {
        drop: Boolean,
        btn: Boolean,
    },
    data () {
        return {
            files: []
        }
    },
    computed: {
        uploadText () {
            // switch (this.$vuetify.breakpoint.name) {
            //     case 'xs': return '选择文件'
            //     default: return '选择或拖拽文件'
            // }
            return this.$vuetify.breakpoint.mobile ? '选择文件' : '拖拽文件'
        }
    },
    methods: {
        inputFile (newFile) {
            if (!newFile) return
            console.log('file: ', newFile)
            if (newFile.type.indexOf('image/') === 0) {
                readBlobToBase64(newFile.file).then(b64=>{
                    readImageMeta(b64).then(meta=>{
                        addNote({
                            raw: b64,
                            contentTp: 'image',
                            meta: meta,
                            text: newFile.name
                        })
                    })
                }).catch(err=>{
                    this.logErrorOther(newFile.name, err)
                })
            }
            else this.logErrorOther(newFile.name)
        },
        inputFilter (newFile, oldFile, prevent) {
            if (!/\.(jpeg|jpg|gif|png|webp)$/i.test(newFile.name)) {
                this.logErrorFileType(newFile.name)
                return prevent()
            }
            if (newFile.size > 10 * 1024 * 1024) {
                this.logErrorFileSize(newFile.name)
                return prevent()
            }
        },
        logErrorFileType (name) {
            let msg = `目前仅支持上传图片类型的文件。错误的文件：${name}`
            addLog({msg, logTp: LOG_TP.ERROR})
        },
        logErrorFileSize (name) {
            let msg = `最大支持上传10M的文件。错误的文件：${name}`
            addLog({msg, logTp: LOG_TP.ERROR})
        },
        logErrorOther (name, err) {
            let msg = `读取文件出错。错误的文件：${name}，错误信息：${err}`
            addLog({msg, logTp: LOG_TP.ERROR})
        }
    }
}
</script>

<style lang="scss">
.drag-mask {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba($color: #000000, $alpha: .3);
    color: white;
}

.hide {
    height: 0;
}

.drag-file-btn {

    .file-uploads {
        padding: 4px 8px 6px;
        margin-bottom: -2px;
    }
}
</style>