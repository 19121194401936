const { CONTENT_TP } = require('../constants')
let Base = require('./common/base')


class SkipFlow extends Base.Action {
    constructor () {
        super(
            'skipFlow',
            '跳过后续动作',
            '如果传入的内容为空，跳过本工作流的后续动作执行。',
        )
        this.descHtml = `仅跳过此工作流的后续动作，不影响其他如父工作流的运行。`
        this.outputTps = [CONTENT_TP.ORIGIN]
    }
    async run({input}) {
        if (!input.raw && (input.raw !== 0)) throw 'skip'
        return input
    }
}

module.exports = new SkipFlow()