let Utils = require('./common/utils')

let connectIds = [
    'notion',
    'onenote',
    'onedrive',
    'qiniu',
]

export default {
    ids: connectIds,
    on: Utils.connectOn,
}