import $fs from "@/store/fast";
import { Clip, readBase64ToBlob, readBlobToBase64, readImageMeta } from "./base";
import { CLIP_TP, CONTENT_TP, LOG_TP } from "../constants";
import ClipDB from "./db";
let DeskApi = $fs.desk

export function changeClips(clips) {
    $fs.vuex('vuex_clips', clips)
    let ids = clips.map(x=>x.id)
    let selects = $fs.state.vuex_clipsSelected
    $fs.vuex('vuex_clipsSelected', selects.filter(x=>ids.indexOf(x) > -1))
}

export async function queryClips({query, skip}) {
    return ClipDB.queryClips({query, skip})
}

// 添加笔记（前端修改，并插入数据库）
export function insertClip({clip, targetClip}) {
    let clips = $fs.state.vuex_clips
    let clipIds = clips.map(x=>x.id)
    let targetIndex = targetClip && targetClip.id && clipIds.indexOf(targetClip.id)
    let idx = 0
    if (targetClip && targetIndex > -1) {
        clip.id = clip.createId(targetClip)
        idx = parseInt(targetIndex) + 1
        clips.splice(idx, 0, clip)
    } else {
        clips.unshift(clip)
    }
    changeClips(clips)
    ClipDB.addClips([clip])
    // 手动设置默认列表对准的内容
    $fs.vuex('vuex_clipsLocalSetActiveIndex', -1)
    let notTpClip = clip.tp && clip.tp !== CLIP_TP.CLIP
    if (notTpClip || idx > 0) setTimeout(() => {
        $fs.vuex('vuex_clipsLocalSetActiveIndex', idx)
    }, 10);
    return clip
}

// 编辑本地的clip
export function editClip(clip, d) {
    let clips = $fs.state.vuex_clips
    for (const i in clips) {
        let c = clips[i]
        if (c.id !== clip.id) continue
        for (const k in d) c[k] = d[k]
        clips.splice(i, 1, c)
        changeClips(clips)
        ClipDB.editClip(clip, d)
        break
    }
}

// 从数据库删除
export function deleteClips(clips) {
    let oc = $fs.state.vuex_clips
    let deleteIds = clips.map(x=>x.id)
    let cleft = oc.filter(x=>deleteIds.indexOf(x.id) == -1)
    changeClips(cleft)
    ClipDB.deleteClips(clips)
}

// 置顶
export function topClips(clips) {
    let deleteIds = []
    let deleteClips = []
    let newIds = []
    for (let index = clips.length - 1; index > -1; index--) {
        let c = clips[index]
        deleteClips.unshift(c)
        deleteIds.unshift(c.id)
        let newId = c.createId(null, clips.length - 1 - index)
        newIds.unshift(newId)
    }
    // 剩下的
    let leftClips = $fs.state.vuex_clips.filter(x=>deleteIds.indexOf(x.id) == -1)
    // 要被删掉的
    let realDeleteClips = deleteClips.map((x)=>{
        return {
            id: x.id,
            raw: x.raw,
        }
    })
    // 将旧的id替换为新的
    let newClips = deleteClips.map((x, i)=>{
        x.id = newIds[i]
        return x
    })
    // 拼成结果保存到前端
    let resClips = newClips.concat(leftClips)
    changeClips(resClips)
    // db删除旧的id后插入新的
    ClipDB.deleteClips(realDeleteClips).then(()=>{
        ClipDB.addClips(newClips)
    })
}

export function deleteAllClips() {
    ClipDB.deleteAllClips()
    changeClips([])
}

// 选择clip
export function selectClip(clip) {
    let ids = $fs.state.vuex_clipsSelected
    ids.push(clip.id)
    $fs.vuex('vuex_clipsSelected', _.uniq(ids))
}

// 取消选择
export function unselectClip(clip) {
    let ids = $fs.state.vuex_clipsSelected
    $fs.vuex('vuex_clipsSelected', ids.filter(x=>x != clip.id))
}

// 全选或取消
export function toggleSelectAll() {
    let ids = $fs.state.vuex_clipsSelected
    if (ids.length === $fs.state.vuex_clips.length) ids = []
    else ids = $fs.state.vuex_clips.map(x=>x.id)
    $fs.vuex('vuex_clipsSelected', ids)
}

// 弹出选中
export function popupSelectClips(ids) {
    ids = ids || $fs.state.vuex_clipsSelected
    let clips
    if (ids && typeof(ids[0]) !== 'string') clips = ids
    else clips = ids.length > 0 ? $fs.state.vuex_clips.filter(x=>ids.indexOf(x.id) > -1) : []
    $fs.vuex('vuex_clipsPopup', clips)
}

// 首页向下翻页
export async function nextClips() {
    if ($fs.state.vuex_clipsLoading) return
    let ocs = $fs.state.vuex_clips
    let lastClip = ocs.length > 0 && ocs.slice(-1)[0]
    $fs.vuex('vuex_clipsLoading', true)
    let raw = await ClipDB.loadClips(lastClip)
    let clips = raw.map(x=>new Clip(x))
    changeClips(ocs.concat(clips))
    setTimeout(() => {
        $fs.vuex('vuex_clipsLoading', false)
    }, 300);
}

export function addLog({targetClip, msg, html, arr, logTp}) {
    let clip = null
    if (arr) {
        clip = new Clip({
            raw: arr,
            contentTp: CONTENT_TP.ARRAY,
            tp: CLIP_TP.LOG,
            logTp: '',
            text: `成功合并为数组（长度为：${arr.length}）。暂存在此，点击使用。`
        })
    } else {
        let raw = html || msg
        clip = new Clip({
            raw: raw,
            contentTp: html && CONTENT_TP.HTML || CONTENT_TP.TEXT,
            tp: CLIP_TP.LOG,
            logTp,
            text: msg,
        })
    }
    insertClip({targetClip, clip})
}

export function addNote({targetClip, raw, text, contentTp, meta}) {
    let clip = new Clip({
        raw,
        contentTp,
        text,
        tp: CLIP_TP.NOTE,
        meta,
    })
    insertClip({targetClip, clip})
}

export function logSuccess(flow, inputs) {
    // $fs.toast(`工作流运行结束：${flow.name}`, {
    //     color: 'info'
    // })
}

export function logError(flow, err) {
    let errors = []
    let nowerr = err
    // 子工作流error套error的情况
    while (nowerr) {
        errors.push(nowerr)
        let nexterr = nowerr.error
        if (!nexterr || !nexterr.flow) break
        nowerr = nexterr
    }
    let msg = ''
    for (const index in errors) {
        if (index > 0) msg += '------>\n子'
        let e = errors[index]
        msg += `工作流：${e.flow.name}，\n`
        if (e.actionIndex > -1) msg += `动作：${e.action.name}（序号: ${e.actionIndex + 1}）, \n`
        if (!e.error.flow) msg += `错误信息：${e.error}`
    }
    return addLog({
        targetClip: err.input,
        msg: msg,
        logTp: LOG_TP.ERROR,
    })
}

export async function writeClipboard(clip) {
    if ($fs.state.vuex_clipboardDenied) {
        doWriteClipboard(clip)
    }
    else if ((clip.contentTp === CONTENT_TP.IMAGE) &&
        (clip.raw.length > 4000000)) {
        $fs.modal({
            title: '可能导致页面顿卡',
            text: '复制的图片很大，可能会导致页面顿卡几秒。若仍要复制请点击确认。',
            confirm: ()=>{
                doWriteClipboard(clip)
            }
        })
    } else {
        doWriteClipboard(clip)
    }
}

async function doWriteClipboard(clip) {
    if ($fs.state.vuex_clipboardDenied) {
        if (!clip.text || (clip.contentTp == CONTENT_TP.IMAGE)) return $fs.toast('由于浏览器限制，无法复制此格式的内容。', {color: 'error'})
        let input = document.createElement('input')
        input.value = clip.text
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999)
        document.execCommand('copy');
        document.body.removeChild(input);
        return $fs.toast('已将内容置入剪贴板')
    }
    try {
        let items = {}
        let changedType = false
        for (const item of clip.data) {
            let ritem = item
            if (clip.contentTp == CONTENT_TP.IMAGE) {
                // 如果是图片，只写入图片
                if (ritem.indexOf('image/') === -1) continue
                // 如果是图片的话，强制转换为png才能写入剪贴板
                if (ritem.indexOf('image/png') === -1) {
                    ritem = ritem.replace(/image\/(gif|jpg|jpeg|webp)/g, 'image/png')
                    $fs.toast('由于浏览器限制，复制到剪贴板的图片，格式固定为PNG。')
                    changedType = true
                }
            }
            let blob = await readBase64ToBlob(ritem)
            items[blob.type] = blob
        }
        let citem = new ClipboardItem(items)
        await navigator.clipboard.write([citem])
        // 如果是云端的则添加过来
        if (clip.id && clip.id.indexOf('clip-') === -1) {
            await clipboardChecker()
        // 如果是有客户端，则下一次强行不读取
        } else if (DeskApi) {
            DeskApi.setClipboardLastRaw()
            // 下次的内容不读取
            $fs.vuex('vuex_clipLastRaw', -1)
        } else {
            // 读取新的复制并设为已有数据，防止出现在顶部
            let newclip = await clipboardChecker((x)=>x)
            $fs.vuex('vuex_clipLastRaw', newclip.raw)
        }
        if (!changedType) $fs.toast('已将内容置入剪贴板。')
    } catch (error) {
        console.log('write clipboard error: ', error)
        $fs.toast('写入剪贴板错误。', {color: 'error'})
    }
}

// 检查剪贴板是否有新内容
export async function clipboardChecker(cb, showToast) {
    if ($fs.state.vuex_clipboardReadType == 'stop') return false
    let clip
    if (DeskApi) {
        clip = await DeskApi.readClipboard()
        if (clip) clip = new Clip({
            tp: CLIP_TP.CLIP,
            ...clip
        })
    } else {
        clip = await readClipboard(showToast)
    }
    if (!clip) return false
    if (cb) return cb(clip)
    return checkLastSame(clip)
}

// 读取剪贴板
export async function readClipboard(showToast) {
    try {
        if (!navigator.clipboard) {
            $fs.vuex('vuex_clipboardDenied', 'support')
            if (showToast) $fs.toast('当前浏览器不支持读取剪贴板，请升级或使用Chrome浏览器。', {
                color: 'error'
            })
            return null
        }
        // 未设置
        if ($fs.state.vuex_clipboardDenied === null) {
            try {
                let pread = await navigator.permissions.query({name: 'clipboard-read'})
                if (pread.state === 'prompt') $fs.vuex('vuex_clipboardDenied', 'prompt')
            } catch (error) {
                $fs.vuex('vuex_clipboardDenied', 'support')
                if (showToast) $fs.toast('当前浏览器不支持读取剪贴板，请升级或使用Chrome浏览器。', {
                    color: 'error'
                })
                return null
            }
        }
        let copydata = await navigator.clipboard.read()
        $fs.vuex('vuex_clipboardDenied', false)
        for (const item of copydata) {
            return await readClipboardItem(item)
        }
        return null
    } catch (err) {
        // 如果没有权限
        if (err.message.indexOf('denied') > -1) {
            $fs.vuex('vuex_clipboardDenied', 'denied')
            if (showToast) $fs.toast('剪贴板权限已被关闭，请打开后刷新页面。', {
                color: 'error'
            })
        }
        else if (err.message.indexOf('focus') > -1) {
            if (showToast) $fs.toast('请聚焦页面获取剪贴板。')
        }
        else if (err.message.indexOf('o valid data') > -1) {
            $fs.vuex('vuex_clipboardDenied', false)
        }
        console.log('read clipboard err: ', err)
        return null
    }
}

// 解析剪贴板内容成clip
async function readClipboardItem(item) {
    let mime2blob = {}
    let data = []
    let raw = ''
    let text = ''
    let contentTp = ''
    let meta = null
    for (const tp of item.types) {
        let blob = await item.getType(tp)
        let b64 = await readBlobToBase64(blob)
        data.push(b64)
        if (tp.indexOf('image/') > -1) mime2blob['image'] = blob
        else mime2blob[tp] = blob
    }
    text = mime2blob['text/plain']
    if (text) {
        contentTp = 'text'
        text = await text.text() || ''
        raw = text
    }
    let img = mime2blob.image
    let html = mime2blob['text/html']
    if (img) {
        contentTp = 'image'
        raw = await readBlobToBase64(img)
        meta = await readImageMeta(raw)
    }
    else if (html) {
        contentTp = 'html'
        raw = await html.text()
    }
    return new Clip({
        raw,
        text,
        tp: CLIP_TP.CLIP,
        contentTp,
        data,
        meta,
    })
}

// 检查内容是否和之前的相同
function checkLastSame(clip) {
    if (!clip.raw) return false
    let clips = $fs.state.vuex_clips
    // 手动设置了跳过
    if ($fs.state.vuex_clipLastRaw == -1) {
        $fs.vuex('vuex_clipLastRaw', clip.raw)
        return false
    }
    // 如果和最近的相同
    if (clip.raw === $fs.state.vuex_clipLastRaw) return false
    $fs.vuex('vuex_clipLastRaw', clip.raw)
    // 找到之前的有效项目
    let idxs = []
    for (const idx in clips) {
        let item = clips[idx]
        let tp = item.tp || CLIP_TP.CLIP
        if (idxs.length > 0) {
            if (tp !== CLIP_TP.CLIP) idxs.push(idx)
            else break
        }
        // 如果不是主内容，跳过
        if (tp !== CLIP_TP.CLIP) continue
        // 作为主内容
        if (clip.raw === item.raw) idxs.push(idx)
    }
    // 挪到最前面
    if (idxs.length > 0) {
        let toadds = []
        for (const idx of idxs) toadds.push(clips[idx])
        topClips(toadds)
        return true
    }
    // 如果没有找到，加到前面去
    clips.unshift(clip)
    ClipDB.addClips([clip])
    changeClips(clips)
    return true
}