<template>
    <!-- 作为popup中的内容 -->
    <v-card tile>
    <v-toolbar class="tool-bar" dense flat :class="{
        'tp-ondesktop': client_insetTitleBar
      }">
        <v-btn icon @click="closePopup()" class="flex-shrink-0">
            <v-progress-circular v-if="vuex_loading" size="24" width="3" indeterminate />
            <v-icon v-else large>mdi-chevron-down</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h6 text-truncate">记录</v-toolbar-title>
        <v-spacer />
        <div>
            <v-btn text @click="tapDownload" v-if="showDownload">
                下载
            </v-btn>
            <v-btn text @click="tapOpenUrl" v-if="showOpenUrl">
                打开链接
            </v-btn>
            <v-btn text @click="tapPlainText" v-if="showPlainText">
                转纯文本
            </v-btn>
            <v-btn text @click="tapSetClipboard" v-if="showSetClipboardButton">
                复制
            </v-btn>
            <v-menu close-on-content-click nudge-left="100" nudge-bottom="30" v-if="showMergeButton">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon 
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item dense>
                        <v-btn text @click="mergeClipsToArray">
                            合并为数组
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn text @click="mergeClips" v-if="showMergeButton">
                合并
            </v-btn>
        </div>
    </v-toolbar>
    <!-- 用来站位 -->
    <v-toolbar flat dense class="toolbar-placeholder" :class="{
        'mt-ondesktop': client_insetTitleBar
      }"></v-toolbar>
    <v-container class="pt-0">
        <flow-run-mask />
        <!-- 展示选中的clips -->
        <div class="clips-selected mb-6 ml-n3 mr-n3">
            <clip-list
                hide-cloud
                hide-copy
                hide-edit-desc
                disable-short-key
                :items="clips"
                :enable-drag="clips.length > 1"
                @tapIndex="tapClipIndex"
                @dragItems="dragClips"
            />
        </div>
        <!-- flows -->
        <div class="flows-list">
            <div class="flows-actions align-center ma-3 mb-0 d-flex flex-row-reverse">
               <v-btn @click="$router.push('/flow/edit'); closePopup(1)" text class="mr-n3">
                    创建
                </v-btn>
                <v-btn @click="$router.push('/flow/manage'); closePopup(1)" text>
                    管理
                </v-btn>
                <div class="flex-grow-1 text-h6">运行：</div>
           </div>
           <!-- 搜索框 -->
           <div class="pl-3 pr-3 pt-3">
               <v-text-field
                solo
                flat
                dense
                placeholder="搜索工作流"
                autocomplete="off"
                persistent-placeholder
                hide-details=""
                clearable
                @click:clear="tapClearSearching"
                v-model="searching"
                prepend-inner-icon="mdi-magnify"
                class="ca-solo-input ca-search-bar"
               >
               </v-text-field>
           </div>
            <flow-list
                class="mt-2"
                show-edit
                :items="flows"
                @tapIndex="tapFlowIndex"
                @editIndex="editFlowIndex"
            />
        </div>
    </v-container>
    </v-card>
</template>

<script>
import ClipList from '../../components/ClipList.vue'
import FlowList from '../../components/FlowList.vue'
import FlowRunMask from '../../components/FlowRunMask.vue';
import AllAction from "../../model/action";
import { addLog, addNote, writeClipboard, popupSelectClips } from "../../model/clip/common";
import { getFlows, resetRecommandFlows } from "../../store/query";
import { CLIP_TP, CONTENT_TP, FLOW_STATUS_TP } from "../../model/constants";
import { Clip } from '../../model/clip/base';
let Utils = require("../../model/action/common/utils");

export default {
    name: 'clipSelectFlow',
    components: {
        ClipList,
        FlowList,
        FlowRunMask
    },
    data () {
        return {
            clips: [],
            flows: [],  // 当前的flow列表
            flowsAll: [],  // 全部的flow列表
            searching: '',  // 搜索
            backPressed: false, // 是否点了返回键来回退
        }
    },
    watch: {
        searching (val) {
            if (val === '') return this.tapClearSearching()
            val && this.doSearch(val)
        }
    },
    computed: {
        //   是否显示合并的按钮，只能合并文字。
      showMergeButton () {
        let validContentTp = [CONTENT_TP.TEXT, CONTENT_TP.HTML]
        let validTp = [CLIP_TP.CLIP, CLIP_TP.NOTE]
        if (this.clips.length <= 1) return false
          for (const clip of this.clips) {
              let tp = clip.tp || CLIP_TP.CLIP
              let ctp = clip.contentTp || CONTENT_TP.TEXT
              if (tp && validTp.indexOf(tp) == -1) return false
              if (ctp && validContentTp.indexOf(ctp) == -1) return false
          }
          return true
      },
      showSetClipboardButton () {
          if (this.clips.length !== 1) return false
          if (this.clips[0].contentTp === CONTENT_TP.ARRAY) return false
          return true
      },
      showOpenUrl () {
          if (!this.showSetClipboardButton) return false
          let t = this.clips[0].text || ''
          return (t.indexOf('http://') === 0) || (t.indexOf('https://') === 0)
      },
      showDownload () {
          if (!this.showSetClipboardButton) return false
          return this.clips[0].contentTp == CONTENT_TP.IMAGE
      },
      showPlainText () {
          if (!this.showSetClipboardButton) return false
          return this.clips[0].contentTp == CONTENT_TP.HTML
      }
    },
    mounted () {
        window.onpopstate = this.onBackPressed
        this.clips = this.resetClips()
        this.reloadMyFlows()
    },
    activated () {
        this.clips = this.resetClips()
        this.reloadMyFlows()
        this.backPressed = false
        history.pushState(null, null, location.href)
    },
    deactivated () {

    },
    methods: {
        onBackPressed() {
            if (this.vuex_runFlowsCountLeft === 0) {
                this.backPressed = true;
                popupSelectClips([]);
            } else {
                // 工作流进行中 不让后退
                history.pushState(null, null, location.href);
            }
        },
        resetClips () {
            let res = JSON.parse(JSON.stringify(this.vuex_clipsPopup))
            return res.map(x=>{
                if (res.length == 1 && // 如果只有1个项目
                    x.contentTp !== 'html' ||  // 不是html
                    x.raw.indexOf('<img ') === -1  // 或者不含img标签
                    ) { 
                        x.showFull = true
                    }
                return x
            })
        },
        // 点击项目展开
        tapClipIndex (i) {
            this.$set(this.clips[i], 'showFull', true)
        },
        // 拖拽换位置
        dragClips (clips) {
            this.clips = [].concat(clips)
        },
        // 添加到剪贴板
        tapSetClipboard () {
            writeClipboard(this.clips[0])
            this.closePopup()
        },
        tapPlainText () {
            let c = this.clips[0]
            let t = c.text || Utils.html2text(c.raw)
            let nc = new Clip({
                raw: t,
                text: t,
            })
            writeClipboard(nc)
            this.closePopup()
        },
        closePopup (back) {
            // console.log(!back, !this.backPressed)
            if (!back && !this.backPressed) this.$router.go(-1)
            this.$fs.vuex('vuex_clipsPopup', [])
        },
        tapOpenUrl () {
            let t = this.clips[0].text
            if (t.indexOf('http') != 0) return window.open('http://' + t, '_blank')
            window.open(t, '_blank')
        },
        tapDownload () {
            var a = document.createElement('a')
            a.href = this.clips[0].raw
            a.target = '_blank'
            a.download = 'file_from_copyauto'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },
        //   合并多选的项目
      mergeClips () {
          let ctp = ''
          let text = ''
          let raw = ''
          // 如果都是html类型才是html
          let contentTps = this.clips.map(x=>x.contentTp)
          contentTps = Array.from(new Set(contentTps))
          if (contentTps.length == 1 && contentTps[0] == 'html') ctp = 'html'
          // 
          text = this.clips.map(x=>x.text).join('\n\n')
          raw = text
          if (ctp == 'html') {
            raw = this.clips.map(x=>x.raw).join('<br/>')
          }
          addNote({
            text: text,
            contentTp: ctp,
            raw: raw,
          })
          this.closePopup()
      },
      mergeClipsToArray () {
          addLog({
              arr: JSON.parse(JSON.stringify(this.clips))
          })
          this.closePopup()
      },
        // 读取我的最近使用列表
        reloadMyFlows () {
            getFlows(this.vuex_flowHistory).then((flows)=>{
                this.flows = [].concat(flows)
                this.flowsAll = [].concat(flows)
                resetRecommandFlows()
            })
        },
        // 点击运行flow
        tapFlowIndex (i) {
            let flow = this.flows[i]
            let umengEvent = ['_trackEvent', '工作流', '运行', flow.name]
            if (flow.statusTp === FLOW_STATUS_TP.SAMPLE) {
                umengEvent.push(-2, flow.id)
                this.$umeng.push(umengEvent)
                this.$fs.modal({
                    title: '状态：草稿',
                    text: '请关闭草稿设置，或另存为副本后，方可运行。',
                    confirm: ()=>{
                        this.editFlowIndex(i)
                    },
                    confirmText: '去修改',
                })
            } else if (flow.statusTp === FLOW_STATUS_TP.ERROR || flow.statusTp === 'ERROR') {
                umengEvent.push(-1, flow.id)
                this.$umeng.push(umengEvent)
                this.$fs.modal({
                    title: '错误工作流',
                    text: '请按说明修改，其状态为正常后，方可运行',
                    confirm: ()=>{
                        this.editFlowIndex(i)
                    },
                    confirmText: '去修改',
                })
            } else {
                umengEvent.push(1, flow.id)
                this.$umeng.push(umengEvent)
                AllAction.runsFlow(flow, this.clips).then(res=>{
                    this.closePopup()
                    // 如果不在首页，弹出提示回到首页查看
                    if (!this.vuex_clipsIsOnBaseBoard) {
                        this.$fs.toast('请返回首页查看运行结果。')
                    }
                    // 重新设置顺序
                    let flowIds = this.vuex_flowHistory.filter(x=>{
                        return x !== flow.id
                    })
                    flowIds.unshift(flow.id)
                    this.$fs.vuex('vuex_flowHistory', flowIds)
                })
            }
        },
        // 点击编辑flow
        editFlowIndex (i) {
            this.closePopup(1)
            this.$router.push('/flow/edit?id=' + this.flows[i].id)
        },
        // 搜索
        doSearch (val) {
            this.flows = this.flowsAll.filter(e=>{
                let txt = e.name
                return txt.toLowerCase().indexOf((val || '').toLowerCase()) > -1
            })
        },
        tapClearSearching () {
            this.flows = [].concat(this.flowsAll)
        },
    }
}
</script>

<style lang="scss">
.toolbar-placeholder {
    flex-grow: 0;
}

.clips-selected {
    position: relative;
}

.clip-btns {
    position: absolute;
    top: -48px;
    right: 0;
}
</style>