import Vue from 'vue'
import $fs from "../store/fast";
import VueRouter from 'vue-router'
import ClipBoard from "../views/clip/Board.vue";
import FlowEdit from "../views/flow/Edit.vue";
import Signup from "../views/user/Signup.vue";
import Login from "../views/user/Login";
import ResetPassword from "../views/user/ResetPassword";
import FlowManage from "../views/flow/Manage";
import FlowStore from "../views/flow/Store";
import ConnectManage from "../views/connect/Manage";
import ConnectPage from "../views/connect/Page";
import UserSettings from "../views/user/Settings";
import PlanPage from "../views/user/PlanPage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ClipBoard',
    component: ClipBoard,
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/flow/edit',
    name: 'FlowEdit',
    component: FlowEdit,
    meta: {
      pageTitle: '编辑工作流'
    }
  },
  {
    path: '/flow/manage',
    name: 'FlowManage',
    component: FlowManage,
    meta: {
      pageTitle: '管理我的工作流'
    }
  },
  {
    path: '/flow/store',
    name: 'FlowStore',
    component: FlowStore,
    meta: {
      pageTitle: '工作流广场'
    }
  },
  {
    path: '/user/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      pageTitle: '注册'
    }
  },
  {
    path: '/integration/manage',
    name: 'ConnectManange',
    component: ConnectManage,
    meta: {
      pageTitle: '连接管理'
    }
  },
  {
    path: '/integration/page',
    name: 'ConnectPage',
    component: ConnectPage,
    meta: {
      pageTitle: '连接详情'
    }
  },
  {
    path: '/user/login',
    name: 'Login',
    component: Login,
    meta: {
      pageTitle: '登录'
    }
  },
  {
    path: '/user/forgot',
    name: 'Forgot',
    component: ResetPassword,
    meta: {
      pageTitle: '忘记密码'
    }
  },
  {
    path: '/user/settings',
    name: 'Settings',
    component: UserSettings,
    meta: {
      pageTitle: '设置'
    }
  },
  {
    path: '/user/plan',
    name: 'Plan',
    component: PlanPage,
    meta: {
      pageTitle: '会员计划',
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, frm, next)=>{
  // 必须登录
  let requireLogin = [
    'Settings',
    'ConnectManange',
    'ConnectPage',
    'Plan',
  ]
  let pageTitle = to.meta.pageTitle || ''
  $fs.vuex('vuex_metaPageTitle', pageTitle)
  if (requireLogin.indexOf(to.name) >= 0 && !$fs.getters.vuex_login) next(({
    path: '/user/login?force=101'
  }))
  else next()
})

// umeng统计
router.afterEach((to, frm) =>{
  if (window._czc) {
    window._czc.push(['_trackPageview', to.fullPath, frm.fullPath])
  }
})

export default router
