<template>
    <div>
        <clip-list
            :items="clips"
            :show-load="clips.length > 0"
            show-delete
            hide-cloud
            @loadMore="doLoadMore"
            :loading="loading"
            @tapIndex="tapIndex"
            @deleteIndex="deleteIndex"
            @editDescIndex="editDescIndex"
        />
    </div>
</template>

<script>
import ClipList from '../../components/ClipList.vue';
import _ from "lodash";
import ClipCloud from "../../model/clip/cloud";
import { popupSelectClips } from "../../model/clip/common";

export default {
  components: { ClipList },
    name: 'CloudClipList',
    data () {
        return {
            clips: [],
            loading: false,
        }
    },
    activated () {
        this.focusLoadNew()
        window.addEventListener('focus', this.focusLoadNew)
    },
    deactivated () {
        window.removeEventListener('focus', this.focusLoadNew)
    },
    watch: {
        // 登出时，清除所有记录
        vuex_login (v) {
            if (!v) this.clips.splice(0)
        }
    },
    methods: {
        focusLoadNew () {
            if (this.checkLogin()) return
            this.loadNew()
        },
        // 登录提示
        checkLogin (notoast) {
            if (this.vuex_login) return
            if (!notoast) this.$fs.toast('请先登录', {color: 'error'})
            return 'not login'
        },
        // 点击
        tapIndex(i) {
            popupSelectClips([this.clips[i]]);
        },
        // 删除
        deleteIndex(i) {
            if (this.checkLogin()) return
            ClipCloud.deleteClip(this.clips[i]).then(res=>{
                this.clips.splice(i, 1)
            })
        },
        editDescIndex ({index, desc}) {
            if (this.checkLogin()) return
            let clip = this.clips[index]
            ClipCloud.changeDesc(clip, desc).then(res=>{
                clip.desc = desc
                this.clips.splice(index, 1, clip)
            })
        },
        // 翻页
        doLoadMore () {
            if (this.checkLogin()) return
            if (this.loading) return
            this.loading = true
            this.loadMore()
        },
        loadMore: _.throttle(function() {
            let cs = this.clips
            ClipCloud.nextClips(cs.length > 0 && cs.slice(-1)[0]).then(res=>{
                // 如果检查添加时出现了重复，则报错，然后重新刷新页面 todo
                this.loading = false
                this.clips = this.clips.concat(res)
            })
        }, 1000, { trailing: false }),
        // 检查最新
        loadNew: _.throttle(function () {
            if (this.loading) return
            this.loading = true
            let cs = this.clips
            ClipCloud.prevClips(cs.length > 0 && cs[0]).then(res=>{
                // 如果获得了全部12个，则说明可能超过12个，就去掉过去的项目。
                if (res.length >= 12) this.clips = [].concat(res)
                else this.clips = res.concat(this.clips)
                this.loading = false
            })
        }, 5000),
    }
}
</script>