let Base = require('./common/base')
import { getFlows } from "../../store/query";
import { runFlow } from "./common/common";
import { FLOW_STATUS_TP } from "../constants";

class FlowRun extends Base.Action {
    constructor () {
        super(
            'flowRun',
            '运行子工作流',
            '引用你的另一个工作流，此工作流的一个步骤（子工作流）。',
        )
        this.descHtml = `· 使用此动作后，高级设置中的【隐藏详细】将被强制选中。
        · 注意不要循环引用。`
        this.component = true
        this.args = [{
            key: 'flowId',
            required: true,
            name: '运行flow的ID',
            hide: true,
        }]
    }
    async run ({info, input, originInput, parentFlowIds}) {
        let flow = info.args.flowId
        flow = await getFlows([flow])
        flow = flow && flow[0]
        if (!flow || !flow.id) throw '没有找到对应的工作流。'
        if (flow.statusTp == FLOW_STATUS_TP.ERROR) throw `子工作流[${flow.name}]状态为错误，无法运行。`
        if (flow.statusTp == FLOW_STATUS_TP.SAMPLE) throw `子工作流[${flow.name}]被设置为草稿，不能直接运行。`
        let res = await runFlow(flow, input, {}, originInput, parentFlowIds)
        return res
    }
}

module.exports = new FlowRun()