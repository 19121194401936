<template>
    <v-container style="max-width: 500px;">
        <form>
            <v-text-field
                v-model="email"
                :error-messages="emailErr"
                label="Email"
                required
                outlined
                placeholder=" "
                persistent-placeholder
                @blur="$v.email.$touch()"
                autocomplete="off"
            />
            <v-btn
                class="primary mr-2 mt-2"
                @click="submit"
            >提交</v-btn>
        </form>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { userForgotEmail } from "../../store/query";

export default {
    mixins: [validationMixin],
    validations: {
        email: {required, email},
    },
    data () {
        return {
            email: '',
        }
    },
    computed: {
        emailErr () {
            const err = []
            if (!this.$v.email.$dirty) return err
            !this.$v.email.email && err.push('Must be valid email.')
            !this.$v.email.required && err.push('Email is required.')
            return err
        },
    },
    methods: {
        submit () {
            this.$v.$touch()
            for (const err of [this.emailErr]) {
                if (err.length > 0) return
            }
            userForgotEmail({
                email: this.email,
            }).then((res)=>{
                this.$fs.toast(res.data.success)
            }).catch(err=>{
                this.$fs.toast(err.toast)
            })
        }
    }
}
</script>