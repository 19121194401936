import Vue from "vue";
import { reportException } from "@/store/query";

export default {
    mounted () {
        // 添加统计代码
        const div = document.createElement('div')
        div.className = 'umeng-stats'
        const script = document.createElement("script");
        script.src = 'https://s4.cnzz.com/z_stat.php?id=1280142840&web_id=1280142840'
        script.type = 'text/javascript'
        div.appendChild(script)
        document.body.appendChild(div);
        // $umeng初始化
        Vue.prototype.$umeng = window._czc || {push: (x)=>{}, empty: true}
        script.onload = ()=>{
            if (window._czc) Vue.prototype.$umeng = window._czc
        }
    },
    created () {
        Vue.config.errorHandler = (err, vm, info) =>{
            reportException({
                path: vm.$route.path,
                error: {
                    name: err.name,
                    message: err.message,
                },
                info,
            })
            throw err
        }
    }
}

