<template>
    <div>
            <v-data-table
                :headers="headers"
                :items="plans"
                hide-default-footer
                fixed-header
                disable-sort
                :items-per-page="99"
            >
                <template v-slot:item.f="{ item }">
                    <plan-board-item :item="item" item-key="f" @enterPlan="enterPlan"/>
                </template>
                <template v-slot:item.b="{ item }">
                    <plan-board-item :item="item" item-key="b" @enterPlan="enterPlan"/>
                </template>
                <template v-slot:item.p="{ item }">
                    <plan-board-item :item="item" item-key="p" @enterPlan="enterPlan"/>
                </template>
                <template v-slot:item.name="{ item }">
                    <div class="d-flex flex-row align-center">
                        <v-icon v-if="item.icon" class="d-block mr-2 grey--text text--darken-3">{{item.icon}}</v-icon>
                        <b class="d-block">{{item.name}}</b>
                    </div>
                    <div v-if="item.subname" class="grey--text text--darken-2 text-caption">{{item.subname}}</div>
                </template>
            </v-data-table>

        <v-dialog
            :value="enterPlanPopup"
            persistent
            scrollable
            max-width="500px"
        >
            <v-card v-if="enterPlanPopup">
                <v-card-title>感谢你的支持</v-card-title>
                <v-card-text>
                    当前为Beta测试阶段，<b class="primary--text">赞助任选金额</b>即可获得会员权限，直至测试阶段结束。<br/>
                    正式版上线时，你的赞助金额可兑换为<b class="error--text">双倍</b>的会员时长。
                    <div class="d-flex flex-wrap">
                        <v-btn class="mt-3 mr-3 primary flex-grow-1"
                            v-for="planNum in betaPlans" :key="'bp' + planNum"
                            @click="enterBetaPlan = planNum; enterPlanPopup = false;"
                        >{{planNum}}元</v-btn>
                        <v-btn text class="grey lighten-3 mt-3 mr-3" @click.stop="enterPlanPopup = false">返回</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            :value="enterBetaPlan"
            persistent
            scrollable
            max-width="300px"
        >
            <v-card v-if="enterBetaPlan">
                <v-card-title>微信扫码支付</v-card-title>
                <v-card-text>
                    <v-img max-width="300px" v-if="enterBetaPlanQrcode" :src="enterBetaPlanQrcode" />
                    <div v-else>正在加载支付二维码...</div>
                    <div class="mt-2 text-caption">* 若支付二维码没有正常加载，请关闭弹窗重新点击。</div>
                </v-card-text>
                <v-card-actions>
                    <v-btn text class="primary" @click="tapDonePayPlan">完成支付后点此刷新</v-btn>
                    <v-spacer/>
                    <v-btn text class="grey lighten-3" @click.stop="enterBetaPlan = false">关闭</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PlanBoardItem from "./PlanBoardItem.vue";
import { getPlanQrcode, getUserInfo } from "../../store/query";

export default {
    components: {
        PlanBoardItem,
    },
    methods: {
        enterPlan (plan) {
            this.enterPlanPopup = true
        },
        tapDonePayPlan () {
            this.enterBetaPlan = false
            getUserInfo(2)
        }
    },
    watch: {
        enterBetaPlan (v) {
            this.enterBetaPlanQrcode = ''
            if (!v) return
            getPlanQrcode({
                plan: `beta_basic_${v}`,
                source: 'wxpay',
            }).then(imgb64=>{
                this.enterBetaPlanQrcode = imgb64
                console.log('wxpay qrcode:', imgb64)
            }).catch(err=>{
                this.$fs.toast(err.toast)
            })
        }
    },
    data () {
        return {
            // 赞助页面
            enterPlanPopup: false,
            // 价格计划
            betaPlans: [
                // 1,
            9, 19, 49, 99, 199],
            // 展示价格的二维码
            enterBetaPlan: null,
            enterBetaPlanQrcode: null,
            headers: [{
                text: '功能',
                align: 'start',
                value: 'name',
            }, {
                text: '免费',
                align: 'center',
                value: 'f',
                width: '23%',
                class: 'text-button black--text',
            }, {
                text: '会员',
                align: 'center',
                value: 'b',
                width: '23%',
                class: 'text-button black--text',
            }, {
                text: '高级会员',
                align: 'center',
                value: 'p',
                width: '23%',
                class: 'text-button black--text',
            }],
            plans: [{
                name: '价格',
                // icon: 'mdi-currency-usd',
                // FREE
                f: '免费',
                f_plan: {
                    value: 0,
                },
                // BASIC
                b: '会员',
                // b_sub: '3-day free trial',
                b_plan: {
                    value: 1,
                    // priceMonth: 3.99,
                    // priceYear: 48,
                    priceText: '赞助任选费用',
                    // subtitle: '* 获得会员权限直至正式上线。正式上线时，按届时计划，此支付的费用可转化为 双倍 的会员时长。',
                },
                // PRO
                p: '敬请期待',
                // p_sub: '3-day free trial',
                // p_plan: {
                //     value: 2,
                //     // priceMonth: 4.99,
                //     // priceYear: 99,
                //     priceText: '敬请期待',
                //     // subtitle: '敬请期待'
                // },
            }, {
                name: '可拥有工作流的数量',
                // icon: 'mdi-frequently-asked-questions',
                f: '10',
                b: '30',
                p: '?'
            }, {
                name: '基础动作',
                subname: '如文字替换，运行脚本等',
                // icon: 'mdi-folder-outline',
                f: true,
                b: true,
                p: true,
            }, {
                name: '基础连接',
                subname: '如发送文本到Notion笔记等',
                // icon: 'mdi-web',
                f: false,
                b: true,
                p: true,
            }, {
                name: '去广告',
                // icon: 'mdi-palette',
                f: false,
                b: true,
                p: true
            }, {
                name: '高级动作',
                // icon: 'mdi-clipboard-text',
                subname: '如OCR、翻译等使用第三方付费服务的动作',
                f: false,
                b: 'warning',
                p: true,
                b_sub: '频次用量较少',
            }, {
                name: '高级连接',
                subname: '如发送到邮箱等使用第三方付费服务的连接',
                // icon: 'mdi-magnify',
                f: false,
                b: 'warning',
                b_sub: '频次用量较少',
                p: true
            }, {
                name: '各连接可配置多个账户',
                // icon: 'mdi-iframe-outline',
                f: false,
                b: 'warning',
                b_sub: '仅在测试阶段允许',
                p: true,
            }]
        }
    },
}
</script>