let Base = require('./common/base')
import { sendAction } from "../../store/query";
import FlowHelper from "../../views/flow/helper";

class UrlPost extends Base.Action {
    constructor () {
        super(
            'urlPost',
            '请求接口',
            '通过POST请求，请求自定义接口'
        )
        this.descHtml = `· 请求方法为POST，body格式为JSON，内容是输入的字典。
        · 请注意输入和输出的格式。
        · 由于发送用户数据有隐私风险，非自己创建或者官方认证用户创建的脚本，首次运行时会出现风险提示。<b>备注内容将出现在风险提示中，请在其中说明用途。</b>
        `
        this.inputTps = [Base.CONTENT_TP.OBJECT]
        this.outputTps = [Base.CONTENT_TP.OBJECT]
        this.argsPrivateKey = {
            targetURL: 'http://example.com',
            targetToken: ''
        }
        this.args = [{
            key: 'targetURL',
            required: true,
            name: '接收请求的URL',
        }, {
            key: 'targetToken',
            name: '验证token',
            desc: '可选。每次发送请求时都会headers中都会带上Catoken:{{token}}，接口可以验证。'
        }]
        this.userLevel = Base.USER_LEVEL.LOGIN
    }
    async run({info, input, parentFlowIds, indexInFlow}) {
        await FlowHelper.dangerCheck(parentFlowIds.slice(-1)[0])
        let url = info.args.targetURL
        let res = await sendAction('urlPost', {
            url,
            raw: input.raw,
            flowId: parentFlowIds.slice(-1)[0],
            indexInFlow,
        }, -1)
        return {
            contentTp: Base.CONTENT_TP.OBJECT,
            raw: res,
        }
    }
}

module.exports = new UrlPost()