function connectOn (cid) {
    try {
        return require(`../${cid}.js`)
    } catch (error) {
        console.log('connect on id not found: ', cid)
        return require(`../undefinedConnect.js`)
    }
}

module.exports = {
    connectOn,
}