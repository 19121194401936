let Base = require('./common/base')

class Onenote extends Base.Connect {
    constructor () {
        super(
            'onenote',
            'OneNote',
            '连接Microsoft OneNote，授权以便使用相关工作流。'
        )
    }
}

module.exports = new Onenote()