<template>
    <v-container class="pb-8">
      <div class="text-body-2">
        <div v-if="vuex_login" class="mb-2">账户名称：<b>{{vuex_userinfo.email}}</b></div>
        <div class="mb-2">当前权限：<b>{{userLevel}}</b>
          <v-btn small icon class="mt-n1 ml-3 grey lighten-4" @click="tapCurrentPlan">
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
        </div>
        <div v-if="expireAt">到期时间：<b>{{expireAt}}</b></div>
        <div class="pl-3 pa-2 mt-4 mb-4" style="border-left: 8px solid var(--v-primary-base);">
          <div class="mb-1">当前为Beta测试阶段，<b class="primary--text">赞助任选金额</b>即可获得会员权限，直至测试阶段结束。</div>
          <div class="mb-1">正式版上线后，将使用订阅制的价格计划。</div>
          <div class="mb-1">为感谢你的支持，你在测试阶段赞助的金额，在正式版时按届时计划，可兑换为<b class="primary--text">双倍</b>的会员时长。</div>
        </div>
      </div>
        <plan-board class="mt-5"/>
    </v-container>
</template>

<script>
import PlanBoard from './PlanBoard.vue'
// import dayjs from "dayjs";
import { getUserInfo } from "../../store/query";
import { USER_LEVEL } from "../../model/constants";

export default {
  components: { PlanBoard },
  computed: {
    pro () {
      let info = this.vuex_userinfo 
      let pro = info && info.pro
      let level = pro && pro.level
      if (!level) return null
      return pro
    },
    expireAt () {
      let e = this.pro && this.pro.expire_at_ts
      if (!e) return null
      return 'Beta测试结束'
      // return dayjs(e * 1000).format('YYYY-MM-DD')
    },
    userLevel () {
      switch (this.vuex_userLevel) {
        case USER_LEVEL.GUEST:
          return '游客'
        case USER_LEVEL.LOGIN:
          return '免费'
        case USER_LEVEL.BASIC:
          return '会员'
        case USER_LEVEL.PRO:
          return '高级会员'
        default:
          return '特殊会员'
      }
    },
  },
  methods: {
    tapCurrentPlan () {
        this.$fs.modal({
            title: '更新会员状态',
            text: '如果你购买的会员，但没有正确显示，请点击重新获取状态。',
            confirm: ()=>{
                getUserInfo(2)
            },
            confirmText: '重新获取'
        })
    },
  }
}
</script>