<template>
    <div class="mgb-modal" style="height: 0; width: 0;">
        <input style="height: 0px; width: 0px;" ref="doBlur">
        <v-dialog
            v-model="mgb_modalShow"
            scrollable
            persistent
            max-width="400px"
            transition="dialog-transition"
        >
            <v-card>
                <v-card-title primary-title>
                    {{x.title || '咦？'}}
                </v-card-title>
                <v-card-text v-if="x.text">
                    {{x.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text :loading="confirmLoading" :color="x.color || 'primary'" @click.native="tapConfirm">{{x.confirmText || '确认'}}</v-btn>
                    <v-btn text v-if="!x.hideCancel" @click.native="tapCancel">取消</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data () {
        return {
            confirmLoading: false
        }
    },
    watch: {
        mgb_modalShow () {
            this.$refs.doBlur.focus()
            this.confirmLoading = false
            this.$nextTick(()=>{
                this.$refs.doBlur.blur()
            })
        }
    },
    computed: {
        /*
        title
        text
        hideCancel
        confirm
        cancel
        */
        x () {
            if (this.mgb_modals.length > 0) return this.mgb_modals[0]
            return {}
        }
    },
    methods: {
        async tapConfirm () {
            if (this.x.confirm) {
                this.confirmLoading = true
                await this.x.confirm()
                this.confirmLoading = false
            }
            this.checkNext()
        },
        tapCancel () {
            if (this.confirmLoading) return
            if (this.x.cancel) this.x.cancel()
            this.checkNext()
        },
        checkNext () {
            this.$fs.vuex('mgb_modalShow', false)
            setTimeout(() => {
                let old = this.mgb_modals
                old.splice(0, 1)
                this.$fs.vuex('mgb_modals', old)
                if (old.length > 0) this.$fs.vuex('mgb_modalShow', true)
            }, 500);
        }
    }
}
</script>