import $fs from "../store/fast";

class DeskApi {
    constructor () {
        this.api = window.CaDeskApi
    }
    async loadClientInfo () {
        try {
            let info = await this.api.loadClientInfo()
            $fs.vuex('client_info', info)
        } catch (error) {
            console.log('load client info error:', error)
        }
    }

    async readClipboard () {
        return await this.api.readClipboard()
    }

    setClipboardLastRaw () {
        this.api.setClipboardLastRaw()
    }

    aliveInterval () {
        return this.api.startAliveTsInterval()
    }

    async writeClipFile (filename, b64data) {
        return await this.api.writeClipFile(filename, b64data)
    }

    async readClipFile (filename) {
        return await this.api.readClipFile(filename)
    }

    deleteClipFile (filename) {
        return this.api.deleteClipFile(filename)
    }

    clearClipFiles () {
        return this.api.clearClipFiles()
    }
}

module.exports = window && window.CaDeskApi && new DeskApi()