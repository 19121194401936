<template>
    <div>
    
    <v-card v-if="!this.client_info.platform" outlined style="border-color: red;" class="ma-2 mb-5 mt-3" @click="$vuetify.goTo(9999)">
        <!-- <v-card-title class="font-weight-bold error--text mb-n3 text-body-1">
            <v-icon small color="error" left>mdi-clipboard-off-outline</v-icon>建议下载客户端，享完整体验。
        </v-card-title> -->
        <v-card-text class="error--text font-weight-bold">
            <v-icon small color="error" left>mdi-clipboard-off-outline</v-icon>建议使用客户端，享完整功能体验。<span class="font-weight-regular" style="border-bottom: 1px dashed">下方下载</span>
        </v-card-text>
    </v-card>

    <div class="empty-intros d-flex flex-wrap">
        <div class="empty-intro-item">
            <v-icon size="64" color="primary">mdi-clipboard-text-outline</v-icon>
            <div class="title">剪贴板管理</div>
            <div class="text">本地历史记录、标签备注、快速搜索、快捷键操作、排序合并等</div>
        </div>
        <div class="empty-intro-item">
            <v-icon size="64" color="info">mdi-cloud-print-outline</v-icon>
            <div class="title" >跨平台云同步</div>
            <div class="text">同步本地记录到云端。打开网页就能用，使用客户端能获得更完整体验。</div>
        </div>
        <div class="empty-intro-item">
            <v-icon size="64" color="warning">mdi-hammer-wrench</v-icon>
            <div class="title">内容处理工具箱</div>
            <div class="text">自定义工作流、文字处理、图片处理、文件处理、压缩图片、上传图床等</div>
        </div>
    </div>

    <v-divider style="opacity: .4;" class="mt-5"/>
    <div class="text-center title mt-10">客户端下载</div>
    <div class="empty-extras d-flex flex-wrap justify-center">
        <div class="empty-extra-item">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn x-large icon v-bind="attrs" v-on="on" @click="tapWindows">
                    <v-icon size="36" color="info">mdi-microsoft-windows</v-icon>
                </v-btn>
            </template>
            <span>Windows</span>
            </v-tooltip>
        </div>

        <div class="empty-extra-item">
            <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn x-large icon v-bind="attrs" v-on="on" @click="tapMac">
                    <v-icon size="36" color="info">mdi-apple-finder</v-icon>
                </v-btn>
            </template>
            <span>Mac</span>
            </v-tooltip>
        </div>

        <div class="empty-extra-item">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn x-large icon v-bind="attrs" v-on="on" @click="showContactPopup = true">
                    <v-icon size="36" color="primary">mdi-wechat</v-icon>
                </v-btn>
            </template>
            <span>微信</span>
            </v-tooltip>
        </div>

        <!-- <div class="empty-extra-item">
            <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn x-large icon v-bind="attrs" v-on="on" @click="tapPwa">
                    <v-icon size="36" color="info">mdi-application-export</v-icon>
                </v-btn>
            </template>
            <span>PWA</span>
            </v-tooltip>
        </div> -->

        <div class="empty-extra-item">
            <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn x-large icon v-bind="attrs" v-on="on" disabled>
                    <v-icon size="36" color="cblack">mdi-apple</v-icon>
                </v-btn>
            </template>
            <span>iOS</span>
            </v-tooltip>
        </div>
        
        <div class="empty-extra-item">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn x-large icon v-bind="attrs" v-on="on" disabled>
                    <v-icon size="36" color="primary">mdi-android-debug-bridge</v-icon>
                </v-btn>
            </template>
            <span>Android</span>
            </v-tooltip>
        </div>
    </div>

    <v-dialog
        v-model="showContactPopup"
        max-width="500px"
    >
        <v-card>
            <v-card-title>
                公众号发布更新信息、更多玩法
            </v-card-title>
            <v-card-subtitle class="mt-n3 pb-2">
                * 小程序客户端敬请期待
            </v-card-subtitle>
            <v-card-text>
                <img :src="qrWeixinImage" width="100%"/>
            </v-card-text>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import QRweixinImage from "../../assets/qrweixinlogin.png";

export default {
    data () {
        return {
            showContactPopup: false,
        }
    },
    computed: {
        qrWeixinImage () {
            return QRweixinImage
        },
        clipboardDenied () {
            let denied = this.vuex_clipboardDenied
            let res = denied && denied != 'prompt'
            if (res) this.$fs.vuex('vuex_clipEditMode', true)
            return res
        }
    },
    methods: {
        tapWindows () {
            this.$fs.clientDownloadModal('win')
        },
        tapMac () {
            this.$fs.clientDownloadModal('mac')
        },
        tapPwa () {
            this.$fs.modal({
                title: '本网站支持PWA',
                text: '可直接发送或保存到桌面，快速使用。',
                showCancel: false
            })
        }
    }
}
</script>

<style lang="scss">
.empty-intros {

    .empty-intro-item {
        width: 290px;
        padding: 20px 30px;
        flex-grow: 10;
        text-align: center;

        .title {
            margin-top: 5px;
        }

        .text {
            max-width: 300px;
            margin: 5px auto;
        }
    }
}

.empty-extras {
    // margin-top: 20px;

    .empty-extra-item {
        margin: 8px;
    }
}
</style>