import { connectStatus } from "../../../store/query";
import $fs from '../../../store/fast'
import {ACTION_TP, USER_LEVEL, ARG_TP, CONTENT_TP} from "../../constants";

class Action {
    constructor (id, name, desc) {
        this.id = id
        // 名称
        this.name = name
        // 其他说明
        this.desc = desc
        // html说明
        this.descHtml = ''
        // 是否在添加列表中隐藏
        this.hide = false
        // 动作类型
        this.tp = ACTION_TP.ACTION
        // 输入类型
        this.inputTps = []
        // 输出类型
        this.outputTps = []
        // 是否有对应的特殊组件展示
        this.component = false
        // 使用需要的用户等级
        this.userLevel = USER_LEVEL.GUEST
        // 参数中需要隐藏不传输到前端的参数 key(args中的key) - value(placeholder如果不传真实值的代替值)
        this.argsPrivateKey = {}
        // 参数
        this.args = []
        /*
            key：使用参数时的key
            required：是否必填
            name: 参数的名称
            desc：参数的说明文字
            tp：类型限制
            maxlength: 最长长度
            minlength: 最短长度
            // value: 用户输入的值，默认没有要用户填的
            hide: 是否在field-args里展示，用于需要通过特殊组件来搞的字段，将默认的隐藏。
        */
    }
    importConnect (name) {
        this.component = 'connectBaseSelect'
        this.connectId = name
        this.args.push({
            key: 'connectUserKey',
            hide: true,
        })
        this.argsPrivateKey.connectUserKey = ''
    }
    async loadConnectStatus (info) {
        let cname = this.connectId
        let userKey = info.args.connectUserKey || ''
        await connectStatus(cname, userKey)
        let status = $fs.state.vuex_connects[cname + userKey]
        if (!status) throw '还没有授权完成连接，点击左上角菜单->管理连接'
        return status
    }
    importComponent () {
        let aid = this.component
        if (typeof(aid) === 'function') return aid
        if (aid === true) aid = this.id
        if (aid) return ()=>import(`@/components/actions/${aid}.vue`)
        return null
    }
    validArgs (args, inputTps=[]) {
        let errors = []
        // 输入验证
        if (this.inputTps.length > 0 && inputTps.length > 0) {
            let combined = this.inputTps.concat(inputTps)
            if (Array.from(new Set(combined)).length == combined.length) {
                errors.push('Input Content Type from previous action does not fit.')
            }
        }
        // 参数验证
        for (const vd of this.args) {
            let arg = args[vd.key]
            // 必填项
            if ((vd.required || vd.require) && (arg === undefined || arg === '' || arg === null)) errors.push(`Field: ${vd.name} is required.`)
            // 最长长度，最短长度 todo
        }
        return errors
    }
}

module.exports = {
    Action,
    TP: ACTION_TP,
    CONTENT_TP,
    ARG_TP,
    USER_LEVEL,
}