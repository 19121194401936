export default {
    beforeRouteLeave (to, from, next) {
        // If the form is dirty and the user did not confirm leave,
        // prevent losing unsaved changes by canceling navigation
        if (this.confirmStayInDirtyForm()){
          // next(false)
          return false
        } else {
          next()
        }
      },
      data() {
        return {
            mgbUnsavedGuard: false,
        }
      },
      created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload)
      },
      beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
      },
      methods: {
        confirmLeave() {
          return window.confirm('有未保存的内容，确认离开?')
        },
      
        confirmStayInDirtyForm() {
          return this.mgbUnsavedGuard && !this.confirmLeave()
        },
      
        beforeWindowUnload(e) {
          if (this.confirmStayInDirtyForm()) {
            // Cancel the event
            e.preventDefault()
            // Chrome requires returnValue to be set
            e.returnValue = ''
          }   
        },
      },
}