let Base = require('./common/base')
import { connectMake } from "../../store/query";
import axios from "axios";
import { readBase64ToBlob } from "../../model/clip/base";

class ConnectQiniu extends Base.Action {
    constructor () {
        super(
            'connectQiniu',
            '发送到七牛云储存',
            '将图片或文件上传至七牛云储存。'
        )
        this.tp = Base.TP.CONNECT
        this.inputTps = [Base.CONTENT_TP.IMAGE]
        this.outputTps = [Base.CONTENT_TP.OBJECT]
        this.userLevel = Base.USER_LEVEL.LOGIN
        this.importConnect('qiniu')
    }
    async run({info, input, gc}) {
        let access = await this.loadConnectStatus(info)
        let accessData = access.access_data
        // 获取文件
        let blob = await readBase64ToBlob(input.raw)
        let qiniuBaseUrl = accessData.regionUrl
        // 请求上传token
        let uploadToken
        try {
            let tokenRes = await connectMake(this.connectId, info.args.connectUserKey, 'uploadToken')
            uploadToken = tokenRes.uploadToken
        } catch (error) {
            throw error.error
        }
        let http = axios.create({timeout: 60 * 1000})
        let fd = new FormData()
        fd.append('token', uploadToken)
        fd.append('file', blob, input.text)
        try {
            let uploadRes = await http.post(qiniuBaseUrl, fd, {
                headers: { 'Content-Type': 'multipart/form-data;' }
            })
            // 域名
            let dres = uploadRes.data
            let domain = accessData.domain || ''
            if (domain) {
                if (domain.slice(-1) != '/') domain = domain + '/'
                if ((domain.indexOf('https://') == -1) && (domain.indexOf('http://') == -1)) domain = 'http://' + domain    
            }
            dres.downloadUrl = domain + dres.key
            return {
                contentTp: Base.CONTENT_TP.OBJECT,
                raw: dres,
            }
        } catch (error) {
            throw error.response.data.error
        }
    }
}

module.exports = new ConnectQiniu()