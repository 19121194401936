var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('main-header'),_c('v-main',{class:{
      'mt-ondesktop': _vm.client_insetTitleBar,
    }},[(_vm.client_insetTitleBar)?_c('div',{staticClass:"desk-app-title-bar"},[_c('div',{staticClass:"bg"})]):_vm._e(),_c('mgb-loading-mask'),_c('mgb-modal'),_c('mgb-toast'),_c('v-dialog',{attrs:{"value":_vm.vuex_clipsPopup.length > 0,"fullscreen":"","persistent":"","transition":'dialog-bottom-transition'}},[_c('keep-alive',[(_vm.vuex_clipsPopup.length > 0)?_c('clip-select-popup'):_vm._e()],1)],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1)],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1)],1),_c('flow-danger-modal'),_c('v-footer',{staticStyle:{"z-index":"0","opacity":"0.01"},attrs:{"padless":""}},[_c('v-col',{staticClass:"text-caption grey--text",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"opacity":"0"}},[_vm._v("CopyAuto")])])],1),_c('v-footer',{staticClass:"white",staticStyle:{"z-index":"0"},attrs:{"absolute":"","padless":""}},[_c('v-col',{staticClass:"text-caption grey--text font-weight-light",class:{
        'text-right': _vm.$vuetify.breakpoint.name != 'sm',
        'text-center':
          _vm.$vuetify.breakpoint.name == 'sm' ||
          _vm.$vuetify.breakpoint.name == 'xs',
      },attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © CopyAuto "),_c('a',{staticClass:"grey--text",staticStyle:{"text-decoration":"none","border-bottom":"1px dashed !important"},attrs:{"href":"http://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("鄂ICP备16020550号-7")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }