let Base = require('./common/base')
let Utils = require('./common/utils')
import { addLog } from "../clip/common";

class WriteLog extends Base.Action {
    constructor () {
        super(
            'writeLog',
            '输出内容为日志',
            '打印出输入的内容，以便进行调试。'
        )
        this.tp = Base.TP.ACTION
        this.outputTps = [Base.CONTENT_TP.ORIGIN]
    }
    async run({input, originInput}) {
        let arr = Utils.inputToArray(input)
        for (const x of arr) runOne(x, originInput)
        return input
    }
}

function runOne(input, originInput) {
    let raw = input.raw || ''
    if (typeof(raw) != 'string') raw = JSON.stringify(raw)
    addLog({
        targetClip: originInput,
        msg: raw,
    })
}

module.exports = new WriteLog()