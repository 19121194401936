let Base = require('./common/base')

class Onedrive extends Base.Connect {
    constructor () {
        super(
            'onedrive',
            'OneDrive',
            '连接Microsoft OneDrive，授权以便使用文件上传或管理的相关工作流。'
        )
    }
}

module.exports = new Onedrive()