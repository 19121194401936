<template>
    <v-container class="pb-5" style="max-width: 600px;">
        <!-- 顶部 -->
        <div class="mb-3">
            <!-- <v-btn icon :style="{
                backgroundColor: flow.color
            }" @click.stop="tapChangeColor">
            </v-btn> -->
            
            <v-text-field
                label="名称"
                v-model="flow.name"
                counter
                outlined
                dense
                hide-details=""
                maxlength="50"
                autocomplete="off"
            />
        </div>
        <!-- 信息 -->
        <div>
            <p class="d-flex align-start mb-2">
                <v-icon left dense class="d-inline">mdi-check-circle-outline</v-icon>
                <span class="font-weight-bold">状态：</span><tag-list :tags="[flow.statusTp]" /></p>
            <p class="d-flex align-start mb-2">
                <v-icon left dense class="d-inline">mdi-import</v-icon>
                <span class="font-weight-bold">输入：</span><tag-list any :tags="flow.inputTps" /></p>
            <p class="d-flex align-start mb-2">
                <v-icon left dense class="d-inline">mdi-export</v-icon>
                <span class="font-weight-bold">输出：</span><tag-list any :tags="flow.outputTps" /></p>
            <p class="d-flex align-start mb-2" v-if="flow.userLevel">
                <v-icon left dense class="d-inline">mdi-badge-account-outline</v-icon>
                <span class="font-weight-bold">权限：</span><tag-list :tags="[`userLevel_${flow.userLevel}`]" /></p>
            <p class="d-flex align-start mb-2">
                <v-icon left dense class="d-inline">mdi-account-outline</v-icon>
                <span class="font-weight-bold">创建者：</span>{{authorName}}
            </p>
            <p class="d-flex align-start mb-2 error--text" v-if="flow.id && !flow.creator_id">
                <v-icon left dense color="error" class="d-inline">mdi-cloud-off-outline</v-icon>
                未登录时创建，没有保存在云端，清除浏览记录后会永远消失。请登录后再次保存，即可云端同步。
            </p>
            <v-textarea
                prepend-icon="mdi-image-text"
                aria-hidden=""
                label="工作流说明"
                auto-grow
                rows="1"
                v-model="flow.desc"
            />
        </div>

        <!-- actions -->
        <div class="mt-2 mb-2">
            <p class="d-flex align-start mb-2">
                <v-icon left dense class="d-inline">mdi-sitemap</v-icon>
                <span class="font-weight-bold">动作列表：</span>
            </p>
            <action-list
                :items="actionsInfo" 
                :enable-drag="enableEdit"
                :show-delete="enableEdit"
                show-arrow
                flow
                @deleteIndex="deleteActionIndex"
                @dragItems="dragActions"
                @tapIndex="tapActionIndex"
            />
        </div>

        <!-- add-button -->
        <v-btn outlined x-large block class="primary--text" :disabled="!isMine || !enableEdit" @click="tapAddAction">
            <v-icon left>mdi-plus</v-icon>
            添加动作</v-btn>

        <!-- 高级设置 -->
        <p class="d-flex align-start mb-2 mt-5">
            <v-icon left dense class="d-inline">mdi-cog</v-icon>
            <span class="font-weight-bold">高级设置</span>
        </p>
        <v-card elevation="1">
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>设为草稿</v-list-item-title>
                        <div class="list-subtitle">草稿不可直接运行。请另存为副本后运行。</div>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch inset :value="flow.configs.isSample" :disabled="!vuex_login || !isMine" @change="changeConfigSample"/>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>设为私密</v-list-item-title>
                        <div class="list-subtitle">仅作者可进入此页面，使他人无法再制作副本。</div>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch inset v-model="statusLock" :disabled="!vuex_login || !isMine" />
                    </v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>隐藏动作</v-list-item-title>
                        <div class="list-subtitle">除了原作者，他人只能查看和修改最前面的变量动作（即使另存为副本）。包含某些动作时（如请求接口）强制设为隐藏。</div>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch inset v-model="configPrivate" :disabled="!vuex_login || !isMine || isForcePrivateActions" />
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>

        <!-- 底部保存栏站位 -->
        <div style="padding-bottom: 60px"></div>
        <!-- 保存栏 -->
        <v-toolbar dense class="action-panel white">
            <v-btn v-if="isMine" class="primary mr-3" @click.stop="tapSave">保存</v-btn>
            <v-btn v-if="flow.id" class="primary mr-3" @click.stop="tapSaveAs">另存为副本</v-btn>
            <v-spacer />
            <v-btn class="error ml-3" @click="tapDeleteFlow" v-if="flow.id && vuex_flowHistory.indexOf(flow.id) > -1">移除</v-btn>
        </v-toolbar>

        <!-- action edit popup -->
        <v-dialog
            v-model="showActionEditPopup"
            fullscreen
            persistent
            :transition="false"
        >
            <action-edit :info="actionSelectInfo" :editable="enableEdit" @closePopup="showActionEditPopup = false" />
        </v-dialog>

        <!-- action select popup -->
        <v-dialog
            v-model="showActionAddPopup"
            fullscreen
            persistent
            :transition="false"
        >
            <action-select :open="showActionAddPopup" @addAction="doActionAdd" @closePopup="showActionAddPopup = false"/>
        </v-dialog>
        
        <!-- 如果是sample，进行设置的帮助 -->
        <sample-step v-if="flow.id" :flow="flow" :actions="actionsInfo" />
    </v-container>
</template>

<script>
import ActionList from '../../components/ActionList.vue'
import ActionSelect from "./Select";
import AllAction from "../../model/action";
import { editFlow, deleteFlow } from "../../store/query";
import TagList from '../../components/TagList.vue';
import ActionEdit from './ActionEdit.vue';
import { ACTION_TP, CONTENT_TP, FLOW_STATUS_TP } from "../../model/constants";
import Fhelper from "./helper";
import MixUnsavedGuard from "../../mixins/mgbUnsavedGuard";
import SampleStep from "./SampleStep";

export default {
  components: {
    ActionList,
    ActionSelect,
    TagList,
    ActionEdit,
    SampleStep,
  },
  mixins: [MixUnsavedGuard],
    data () {
        return {
            // 展现编辑action的弹窗
            showActionEditPopup: false,
            // 展现添加action的弹窗
            showActionAddPopup: false,
            // 选中编辑的action
            actionSelectIndex: -1,

            flow: {
                id: '',
                // 用户自定义的名称
                name: '',
                // 用户自定义的颜色
                color: '',
                // ok, error, sample
                statusTp: 'ok', 
                // 状态，0仅自己可见，1公开，-1删除
                status: 1,
                // 简介
                desc: '',
                // 创建者
                creator_id: null,
                author: {
                    nickname: '',
                },
                // 设置
                configs: {
                //  isSample // 是否是模版
                //  isPrivate // 是否隐藏actions
                },
                // 动作列表
                actions: [],
            }
        }
    },
    computed: {
        isMine () {
            return Fhelper.isMine(this.flow)
        },
        statusLock: {
            get () { return this.flow.status == 0},
            set (v) { this.$set(this.flow, 'status', v ? 0 : 1)}
        },
        authorName () {
            if (this.isMine) return '我'
            return this.flow.author && this.flow.author.nickname || '匿名用户'
        },
        enableEdit () {
            return Fhelper.isEnableEdit(this.flow)
        },
        // 所有action的完整信息
        actionsInfo () {
            let actions = this.flow.actions.map((x)=>{
                let a = AllAction.actionOn(x.aid)
                if (a.component) a.component = a.importComponent()
                x.action = a
                return x
            })
            // 如果创建者隐藏动作时，只显示前面的vars，隐藏后面的actions，增加一个假action
            if (!this.enableEdit) {
                let vars = []
                for (const item of actions) {
                    if (item.action.tp != ACTION_TP.VARIABLE) break
                    vars.push(item)
                }
                actions = vars
                let hiddenAction = AllAction.actionOn('hiddenAction')
                actions.push({
                    aid: hiddenAction.id,
                    desc: hiddenAction.desc,
                    action: hiddenAction,
                })
            }
            return actions
        },
        // 选中的action的完整信息
        actionSelectInfo () {
            if (this.actionSelectIndex < 0) return null
            let info = this.actionsInfo[this.actionSelectIndex]
            return info
        },
        // 是否是私密的
        configPrivate: {
            get () {
                let p = this.flow.configs.isPrivate
                if (p) {
                    // 如果只有usero没有flowo，则设置flowo，因为创建的时候还没有id
                    if (!p.flowo && this.flow.id) {
                        p.flowo = this.flow.id
                        this.$set(this.flow.configs, 'isPrivate', p)
                    }
                    return p
                }
                if (this.isForcePrivateActions) this.changeConfigPrivate(true)
                return this.flow.configs.isPrivate
            },
            set (v) {
                if (this.isForcePrivateActions) v = true
                this.changeConfigPrivate(v)
            },
        },
        isForcePrivateActions () {
            let fids = [
                'flowRun',
                'urlPost',
                'runEval',
            ]
            for (const a of this.flow.actions) {
                if (fids.indexOf(a.aid) > -1) return true
            }
            return false
        },
    },
    async mounted () {
        let flowId = this.$route.query.id
        let flow = flowId && await Fhelper.isAccess(flowId)
        this.mgbUnsavedGuard = true
        if (flow) {
            this.flow = flow
            return this.checkStatusTp()
        }
    },
    watch: {
        'flow.actions' (v) {
            this.checkStatusTp()
        },
        showActionEditPopup (v) {
            if (!v) this.checkStatusTp()
        },
        showActionAddPopup (v) {
            if (!v) this.checkStatusTp()
        }
    },
    provide () {
        return {
            changeConfigSample: this.changeConfigSample,
            checkStatusTp: this.checkStatusTp,
            tapSaveAs: this.tapSaveAs,
        }
    },
    methods: {
        // 修改隐私设置
        changeConfigPrivate (v) {
            if (!v) return this.$set(this.flow.configs, 'isPrivate', null)
            this.$set(this.flow.configs, 'isPrivate', {
                usero: this.flow.creator_id || (this.vuex_userinfo && this.vuex_userinfo.id),
            })
        },
        // 修改草稿设置
        changeConfigSample (v) {
            this.$set(this.flow.configs, 'isSample', v)
            this.checkStatusTp()
        },
        // 检查actions
        checkStatusTp () {
            let inputTps = []
            let outputTps = []
            let userLevel = 0
            // 现将状态设置为OK
            this.$set(this.flow, 'statusTp', FLOW_STATUS_TP.OK)
            for (const index in this.actionsInfo) {
                let ainfo = this.actionsInfo[index]
                let action = ainfo.action
                // 检查是否有更高的权限需求
                if (action.userLevel > userLevel) userLevel = action.userLevel
                // 检查输入类型和输入参数是否有错误
                let argsErrs = action.validArgs(ainfo.args, outputTps)
                this.$set(this.flow.actions[index], 'errors', argsErrs || [])
                // 如果有错误，则设置为错误
                if (argsErrs.length > 0) this.$set(this.flow, 'statusTp', FLOW_STATUS_TP.ERROR)
                // 将当前的输出保存，用于下一个检查的输入
                // 如果输出是origin则直接从input而不是output
                let isOriginOutputs = action.outputTps.length === 1 && action.outputTps[0] === CONTENT_TP.ORIGIN
                outputTps = isOriginOutputs ? action.inputTps : action.outputTps
                if (inputTps.length == 0 && action.inputTps.length > 0) inputTps = action.inputTps
            }
            // 如果是sample，则强制设置为sample
            if (this.flow.configs.isSample) this.$set(this.flow, 'statusTp', FLOW_STATUS_TP.SAMPLE)
            // 如果不能编辑，就直接读取保存的状态
            if (this.enableEdit) {
                this.$set(this.flow, 'inputTps', inputTps)
                this.$set(this.flow, 'outputTps', outputTps)
                this.$set(this.flow, 'userLevel', userLevel)
            }
        },
        // 点击修改边框颜色
        tapChangeColor () {

        },
        // 点击保存
        async tapSave () {
            this.checkStatusTp()
            let isValid = await Fhelper.isEnableSave(this.flow)
            if (!isValid) return
            editFlow(this.flow).then(res=>{
                this.mgbUnsavedGuard = false
                this.$fs.toast('已保存')
                this.$router.go(-1)
            }).catch(err=>{
                this.$fs.toast(err.toast)
            })
        },
        // 点击另存为
        async tapSaveAs () {
            let isValid = await Fhelper.isEnableSave(this.flow, 'saveAs')
            if (!isValid) return
            try {
                await Fhelper.dangerCheck(this.flow.id)
            } catch (error) {return}
            let flow = JSON.parse(JSON.stringify(this.flow))
            // 如果是sample，就强制取消再保存
            if (this.flow.configs.isSample) {
                this.changeConfigSample(false)
                flow = JSON.parse(JSON.stringify(this.flow))
                this.changeConfigSample(true)
            }
            flow.id = ''
            editFlow(flow).then(res=>{
                this.$fs.toast(`已另存为：${flow.name}`)
                this.mgbUnsavedGuard = false
                this.$router.go(-1)
            }).catch(err=>{
                this.$fs.toast(err.toast)
            })
        },
        // 点击按钮添加更多动作
        tapAddAction () {
            // 统计
            this.$umeng.push(['_trackPageview', '/actionSelectPopup', this.$route.fullPath])
            this.showActionAddPopup = true
        },
        // 点击按钮删除这个flow
        tapDeleteFlow () {
            deleteFlow(this.flow).then(()=>{
                this.mgbUnsavedGuard = false
                this.$router.go(-1)
                this.$fs.toast('已删除。')
            }).catch(err=>{
                this.$fs.toast(err.toast)
            })
        },
        // 删除某个Action
        deleteActionIndex (i) {
            this.flow.actions.splice(i, 1)
        },
        // 点击Action进入编辑
        tapActionIndex (i, add) {
            console.log('tap: ', i, this.flow.actions)
            this.actionSelectIndex = i
            let action = this.actionSelectInfo.action
            // 统计
            let urlfrm = add ? '/actionSelectPopup' : this.$route.fullPath
            this.$umeng.push(['_trackPageview', '/actionEditPopup?id=' + action.aid, urlfrm])
            // 
            if (action.id == 'undefinedAction') return this.$fs.toast('找不到此动作或已被禁用，请删除后重新添加。', {color: 'error'})
            if (action.id == 'hiddenAction') return this.$fs.toast('已被创建者设置为私密，无法查看和修改。', {color: 'error'})
            this.showActionEditPopup = true
        },
        // 重新给actions排序
        dragActions (actions) {
            this.flow.actions = [].concat(actions)
        },
        // 在select中选择了action并添加到actions中，同时打开编辑页面。
        doActionAdd (action) {
            this.flow.actions.push(action)
            this.showActionAddPopup = false
            this.$nextTick(()=>{
                this.tapActionIndex(this.actionsInfo.length - 1, true)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.action-panel {
    z-index: 1;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
}

.list-subtitle {
    font-size: 14px;
    color: var(--v-cblack-lighten4);
}
</style>