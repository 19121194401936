let Base = require('./common/base')

class Notion extends Base.Connect {
    constructor () {
        super(
            'notion',
            'Notion',
            '连接Notion，授权以便工作流添加新的block。'
        )
        this.descHtml = `· 由于Notion官方API目前是Beta版，时有错误发生导致无法授权，如遇到，请点击左上角菜单我们联系。
        · 由于Notion不是国内服务，时有发生不可抗力的请求超时。`
    }
}

module.exports = new Notion()