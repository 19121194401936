<template>
  <div class="ml-2 mt-2 mb-2">
    <div class="d-flex">

    <v-tooltip bottom color="cblack" open-delay="300">
      <template v-slot:activator="{ on, attrs }">
      <v-btn icon small v-bind="attrs" v-on="on"
        class="rounded grey lighten-4 mr-2"
        style="height: 36px; width: 36px"
        text
        @click="showReadType = true"
      >
        <v-icon small :color="readTypeNow.iconColor || 'cblack'">{{
          readTypeNow.icon
        }}</v-icon>
      </v-btn>
      </template>
      <span>剪贴板读取设置</span>
    </v-tooltip>

    <v-tooltip bottom color="cblack" open-delay="300">
      <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon small class="rounded grey lighten-4 mr-2"
        style="height: 36px; width: 36px"
        text
        @click="toggleClipEditBoard"
      >
        <v-icon small :color="vuex_clipEditMode ? 'primary' : 'cblack'">mdi-form-textbox</v-icon>
      </v-btn>
      </template>
      <span>手动输入</span>
    </v-tooltip>

    <v-btn-toggle
      v-model="tabname"
      mandatory
      borderless
      class="tab-btns mr-2"
      active-class="tab-btn-active"
    >
      <v-tooltip bottom color="cblack" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
        <v-btn icon small value="local" class="grey lighten-4" style="margin-right: 1px" v-bind="attrs" v-on="on">
          <v-icon small>mdi-database</v-icon>
        </v-btn>
        </template>
        <span>本地记录 [Tab]</span>
      </v-tooltip>

      <v-tooltip bottom color="cblack" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
        <v-btn icon small value="cloud" class="grey lighten-4" v-bind="attrs" v-on="on">
          <v-icon small>mdi-cloud</v-icon>
        </v-btn>
        </template>
        <span>云同步的记录 [Tab]</span>
      </v-tooltip>
    </v-btn-toggle>

    <v-form class="flex-grow-1" @submit.prevent="submitSearch">
      <v-text-field
        class="ca-solo-input ca-search-bar"
        dense
        autocomplete="off"
        solo
        flat
        persistent-placeholder
        hide-details=""
        clearable
        :prepend-inner-icon="tabname == 'cloud' ? 'mdi-cloud-search' : 'mdi-database-search'"
        :placeholder="searchPlaceholder"
        v-model="searchQuery"
        :loading="searchLoading"
        v-hotkey="keymap"
        ref="searchBar"
      />
    </v-form>

        <v-dialog
      v-model="showReadType"
      max-width="400px"
    >
      <v-card>
        <v-card-title>剪贴板自动读取</v-card-title>

      <v-card-text class="pb-3">
      
      <v-list class="pa-0">
        <v-list-item v-for="(item, idx) in readTypes" :key="item.value" @click="readTypeSelected = idx; showReadType = false" :class="{
          'read-type-selected': readTypeSelected == idx
        }" :disabled="item.disabled && item.disabled()">
            <v-list-item-icon>
              <v-icon color="cblack">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{item.label}}</v-list-item-title>
              <v-list-item-subtitle v-if="item.disabled && item.disabled()" class="text-caption">{{item.subtitle}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
      </v-list>
      </v-card-text>

      <!-- <v-card-actions class="primary">
        <v-spacer/>
        <v-btn text color="white">
          <v-icon small class="mr-2">mdi-arrow-right</v-icon>
          使用客户端，体验更完整
          <v-icon small class="ml-2">mdi-microsoft-windows</v-icon>
          <v-icon small class="ml-2">mdi-apple</v-icon>
          <v-icon small class="ml-2">mdi-cellphone</v-icon>
        </v-btn>
      </v-card-actions> -->

      </v-card>
    </v-dialog>
  </div>

  <div class="mt-2 mb-3">
      <v-form v-if="vuex_clipEditMode && tabname !== 'cloud'" @submit.prevent="tapInputCopyBtn">
          <v-textarea
            solo
            flat
            dense
            hide-details=""
            persistent-placeholder
            placeholder="请手动粘贴或输入..."
            auto-grow
            clearable
            rows="2"
            class="ca-solo-input ca-solo-textarea"
            v-model="clipInput"
            ref="inputCopyTextarea"
            @keydown.ctrl.enter="tapInputCopyBtn"
            @keydown.meta.enter="tapInputCopyBtn"
          >
          </v-textarea>
          <div class="rounded grey lighten-4 mt-n2 pt-3 pb-2 pl-2 pr-2 d-flex" style="margin-right: 6px;">
              <v-btn small elevation="0" class="primary mr-2" @click="tapInputCopyBtn">
                  <v-icon small left>mdi-content-cut</v-icon>
                  复制到剪贴板
              </v-btn>
              <v-btn small elevation="0" class="" @click="tapInputCopyCloud">
                  <v-icon small left>mdi-cloud-upload-outline</v-icon>
                  云同步
              </v-btn>
              <v-btn small v-if="!false" class="pa-0" elevation="0">
                  <drag-file btn/>
              </v-btn>
          </div>
      </v-form>
  </div>
  </div>
</template>

<script>
import DragFile from '../../components/DragFile.vue';
import { Clip } from '../../model/clip/base';
import { insertClip, writeClipboard } from '../../model/clip/common';
import { CLIP_TP, CONTENT_TP } from '../../model/constants';
import ClipCloud from "../../model/clip/cloud";

export default {
  components: { DragFile },
  props: {
    searchLoading: Boolean,
  },
  data() {
    return {
        tabname: 'local',
        showReadType: false,
        searchQuery: '',
        clipInput: '',
      readTypes: [
        {
          value: "stop",
          label: "停止自动读取",
          iconColor: "error",
          icon: "mdi-clipboard-off-outline",
        },
        {
          value: "focus",
          label: "仅聚焦页面时读取",
          icon: "mdi-target",
          disabled: ()=>!!this.vuex_clipboardDenied,
          subtitle: '需要客户端，或最新版Chrome或Edge浏览器',
        },
        {
          value: "forever",
          icon: "mdi-laptop-windows",
          label: "不聚焦页面时也读取",
          disabled: ()=>!this.$fs.desk,
          subtitle: "需要桌面客户端",
        },
      ],
    };
  },
  watch: {
    tabname (v) {
      this.$emit('tabChange', v)
      this.searchQuery = ''
      this.$emit('cloudSearch', '')
      this.$emit('localSearch', '')
    },
    searchQuery (v) {
      if (this.tabname == 'cloud') this.$emit('cloudSearch', v)
      else this.$emit('localSearch', v)
    },
    vuex_clipsBoardToLocal (v) {
      this.tabname = 'local'
      this.searchQuery = ''
    },
    vuex_clipboardDenied (v) {
      let value = 'focus'
      if (v) value = 'stop'
      else if (this.client_info.platform) value = 'forever'
      this.$fs.vuex('vuex_clipboardReadType', value)
    },
  },
  activated () {
    this.tabname = 'local'
  },
    computed: {
      keymap () {
        return {
          'f': this.shortKeySearch,
          'tab': this.shortKeySwitchLocalCloud,
          'esc': this.shortKeyEsc,
        }
      },
    readTypeIndex () {
      let vi = {}
      for (const index in this.readTypes) {
        let item = this.readTypes[index]
        vi[item.value] = index
      }
      let idx = vi[this.vuex_clipboardReadType]
      return idx >= 0 ? idx : 1
    },
    readTypeSelected: {
      get () {
        return this.readTypeIndex
      },
      set (v) {
        this.$fs.vuex('vuex_clipboardReadType', this.readTypes[v].value)
      }
    },
      readTypeNow () {
        return this.readTypes[this.readTypeIndex]
      },
      searchPlaceholder () {
        let v = this.tabname == 'cloud' ? '搜索云端记录' : '搜索本地记录'
        if (this.$vuetify.breakpoint.mdAndUp) {
          v += ' [F]'
        }
        return v
      },
  },
  methods: {
    shortKeySearch () {
      if (this.$route.path !== '/') return
      this.$refs.searchBar.$el.querySelector('input').focus()
    },
    shortKeySwitchLocalCloud () {
      if (this.$route.path !== '/') return
      if (this.tabname === 'cloud') {
        this.tabname = 'local'
      } else {
        this.tabname = 'cloud'
      }
    },
    shortKeyEsc () {
      if (this.$route.path !== '/') return
      this.searchQuery = ''
      this.$refs.searchBar.$el.querySelector('input').blur()
    },
    submitSearch (e) {
      this.$refs.searchBar.$el.querySelector('input').blur()
      this.$emit('submitSearch', this.searchQuery)
    },
      toggleClipEditBoard () {
          let v = this.$fs.state.vuex_clipEditMode
          this.$fs.vuex('vuex_clipEditMode', !v)
      },
      tapInputCopyBtn () {
        if (!this.clipInput) return
        this.$fs.vuex('vuex_clipLastRaw', this.clipInput)
        let clip = new Clip({
          raw: this.clipInput,
          text: this.clipInput,
          tp: CLIP_TP.CLIP,
          contentTp: CONTENT_TP.TEXT
        })
        let newclip = insertClip({clip: clip})
        writeClipboard(clip)
        this.clipInput = ''
        return newclip
      },
      tapInputCopyCloud () {
        let clip = this.tapInputCopyBtn()
        if (clip) ClipCloud.uploadClip(clip)
      }
  }
};
</script>

<style lang="scss">
.read-type-selected {
  background-color: #e5e5e5;
}


</style>