let Base = require('./common/base')

class VarRead extends Base.Action {
    constructor() {
        super(
            'varRead',
            '读取变量',
            '弃用传入的内容，读取之前储存的变量，然后向下输出。'
        )
        this.tp = Base.TP.ACTION
        this.args = [{
            key: 'varKey',
            required: true,
            name: '变量的Key',
        }]
    }
    async run({info, gc}) {
        let key = info.args.varKey
        return {
            raw: gc[key]
        }
    }
}

module.exports = new VarRead()