var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{class:{
      'mt-ondesktop': _vm.client_insetTitleBar,
    },attrs:{"app":"","color":"white","elevate-on-scroll":"","dense":""}},[_c('v-btn',{staticClass:"rounded grey mr-2 lighten-4 ml-n2",staticStyle:{"height":"36px","width":"36px"},attrs:{"icon":"","small":"","text":""},on:{"click":function($event){_vm.showNavMenu = true}}},[(_vm.vuex_loading)?_c('v-progress-circular',{staticClass:"black--text",attrs:{"size":"24","width":"3","indeterminate":""}}):_c('v-icon',{staticClass:"black--text",attrs:{"small":""}},[_vm._v("mdi-menu")])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"cblack","open-delay":"300","disabled":!_vm.shortKeyTip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-4 pl-3 pr-3",attrs:{"text":""},on:{"click":_vm.tapHome}},'v-btn',attrs,false),on),[_c('v-avatar',{staticClass:"ml-1 mr-1",attrs:{"tile":"","size":"16"}},[_c('v-img',{attrs:{"src":_vm.imageLogo}})],1),_c('h1',{staticClass:"font-weight-bold text-body-1 d-flex align-center",staticStyle:{"text-transform":"none"}},[_vm._v(" CopyAuto ")]),_c('div',{staticClass:"text-caption grey--text text--lighten-1",staticStyle:{"position":"absolute","top":"-11px","right":"-11px","text-transform":"none"}},[_vm._v(" beta ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.shortKeyTip))])]),(_vm.pageTitle)?_c('h2',{staticClass:"ml-2 text-truncate font-weight-bold text-h6"},[_c('span',{staticClass:"mr-1"},[_vm._v("/")]),_vm._v(_vm._s(_vm.pageTitle)+" ")]):_vm._e(),_c('v-spacer')],1),_c('v-navigation-drawer',{class:{
      'mt-ondesktop': _vm.client_insetTitleBar,
    },attrs:{"app":"","left":"","temporary":""},model:{value:(_vm.showNavMenu),callback:function ($$v) {_vm.showNavMenu=$$v},expression:"showNavMenu"}},[_c('right-menu',{on:{"close":function($event){_vm.showNavMenu = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }