<template>
    <div class="pt-3 pb-3">
        <!-- 价格 -->
        <div v-if="plan && currentPlan != plan.value" class="d-flex flex-column">
            <v-btn v-if="plan.value" class="primary" @click.native="$emit('enterPlan', plan)">{{plan.priceText}}</v-btn>
            <v-btn v-else text>免费</v-btn>
            <!-- <v-btn class="primary mt-2" @click.native="enterYear('year')">${{plan.priceYear}} / year</v-btn> -->
            <div v-if="plan.subtitle" class="mt-2 grey--text text--darken-2 text-caption">{{plan.subtitle}}</div>
        </div>

        <!-- 默认 -->
        <template v-else>
            <v-icon v-if="item[itemKey] === true" color="primary">mdi-check-circle</v-icon>
            <v-icon v-else-if="item[itemKey] === 'warning'" color="warning">mdi-check-circle</v-icon>
            <v-icon v-else-if="item[itemKey] === false" color="error">mdi-close-circle</v-icon>
            <div v-else>{{item[itemKey]}}</div>
            <div v-if="subtitle"
                class="grey--text text--darken-2 text-caption"
            >{{subtitle}}</div>
        </template>

        <!-- 当前方案标志 -->
        <div v-if="plan && currentPlan == plan.value"
            class="pa-1 pl-2 pr-2 rounded-pill grey lighten-4 text-center mt-1"
            @click="tapCurrentPlan"
        >
            当前权限
            <v-icon small class="mt-n1">mdi-help-circle-outline</v-icon>
        </div>
    </div>
</template>

<script>
import { getUserInfo } from "../../store/query";

export default {
    props: {
        item: Object,
        itemKey: String
    },
    computed: {
        subtitle () {
            return this.item[this.itemKey + '_sub']
        },
        plan () {
            return this.item[this.itemKey + '_plan']
        },
        currentPlan () {
            let info = this.vuex_userinfo
            return info && info.pro && info.pro.level || 0
        },
    },
    methods: {
        tapCurrentPlan () {
            this.$fs.modal({
                title: '更新会员状态',
                text: '如果你购买的会员，但没有正确显示，请点击重新获取状态。',
                confirm: ()=>{
                    getUserInfo(2)
                },
                confirmText: '重新获取'
            })
        },
    }
}
</script>