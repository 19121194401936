let Base = require('./common/base')

class UndefinedConnect extends Base.Connect {
    constructor () {
        super(
            'undefinedConnect',
            '未知的连接',
            '错误，没有找到这个连接。'
        )
        this.hide = true
    }
}

module.exports = new UndefinedConnect()