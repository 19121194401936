<template>
    <v-card
        :ripple="true"
        outlined
        class="mb-5 action-card d-flex flex-column"
    >
        <v-icon v-if="showArrow" small class="arrow-input d-block pl-1 pr-1" color="rgba(0, 0, 0, 0.42)">mdi-arrow-down</v-icon>
        <v-icon v-if="showArrow" small class="arrow-output d-block pl-1 pr-1" color="rgba(0, 0, 0, 0.42)">mdi-arrow-down</v-icon>
        <v-card-text class="d-flex flex-column flex-grow-1" style="height: 100%;">
            <!-- 顶部 -->
            <div class="d-flex align-start mb-2">
                <tag-list :tags="tagsOn(item)"/>
                <v-spacer />
                <div class="ml-2 d-flex align-start">
                    <div class="mr-2 flex-shrink-0">输入:</div><tag-list any :tags="item.action.inputTps" />
                </div>
            </div>

            <div>    
                <span class="text-h6 font-weight-black grey--text text--darken-4">{{item.action.name}}</span>
            </div>
            <div>{{item.desc || item.action.desc | descFilter}}</div>

            <div v-if="item.errors && item.errors.length > 0" class="font-weight-bold error--text mt-2">
                <div class="d-flex align-center">
                    <v-icon small color="error">mdi-alert-circle-outline</v-icon>
                    <span class="ml-1">错误:</span>
                </div>
                <div v-for="err in item.errors" :key="err">{{err}}</div>
            </div>
            
            <v-spacer />

            <div class="d-flex align-end mt-3">
                <v-btn small elevation="0" v-if="!!enableDrag" class="draghandle flex-shrink-0 mr-2">
                    <v-icon>mdi-drag</v-icon>
                </v-btn>

                <v-btn small elevation="0" v-if="showDelete" @click.stop="$emit('delete', item)" class="mr-2">
                    <v-icon color="grey darken-2">mdi-delete-forever-outline</v-icon>
                </v-btn>

                <v-spacer />

                <div class="ml-2 d-flex align-start">
                    <div class="mr-2 flex-shrink-0">输出:</div><tag-list any :tags="item.action.outputTps" />
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import TagList from './TagList.vue';

export default {
    components: {
        TagList,
    },
    props: {
        item: Object,
        showArrow: Boolean,
        showDelete: Boolean,
        enableDrag: Boolean,
    },
    filters: {
        descFilter (x) {
            let value = (x || '').substr(0, 82)
            if (value.length >= 81) value += '...'
            return value
        }
    },
    methods: {
        tagsOn(item) {
            let res = [{type: item.action.tp}]
            // let userLevel = item.action.userLevel
            // if (userLevel) res.push({type: `userLevel_${userLevel}`})
            return res
        }
    }
}
</script>

<style lang="scss" scope>
.action-card {
    position: relative;
    border: 1px solid rgba($color: #000000, $alpha: 0.42) !important;
}

.arrow-input {
    background: white;
    position: absolute !important;
    top: -10px;
    right: 18px;
}

.arrow-output {
    background: white;
    position: absolute !important;
    right: 18px;
    bottom: -8px;
}
</style>