<template>
  <div>
    <v-app-bar
      app
      color="white"
      elevate-on-scroll
      dense
      :class="{
        'mt-ondesktop': client_insetTitleBar,
      }"
    >
      <v-btn
        icon
        small
        class="rounded grey mr-2 lighten-4 ml-n2"
        style="height: 36px; width: 36px"
        text
        @click="showNavMenu = true"
      >
        <v-progress-circular
          class="black--text"
          v-if="vuex_loading"
          size="24"
          width="3"
          indeterminate
        />
        <v-icon v-else small class="black--text">mdi-menu</v-icon>
      </v-btn>

      <v-tooltip bottom color="cblack" open-delay="300" :disabled="!shortKeyTip">
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="grey lighten-4 pl-3 pr-3"
        text
        @click="tapHome"
        v-bind="attrs" v-on="on"
      >
        <v-avatar tile size="16" class="ml-1 mr-1">
          <v-img :src="imageLogo" />
        </v-avatar>
        <h1
          class="font-weight-bold text-body-1 d-flex align-center"
          style="text-transform: none"
        >
          CopyAuto
        </h1>
        <div
          style="
            position: absolute;
            top: -11px;
            right: -11px;
            text-transform: none;
          "
          class="text-caption grey--text text--lighten-1"
        >
          beta
        </div>
      </v-btn>
      </template>
      <span>{{shortKeyTip}}</span>
    </v-tooltip>

      <h2
        class="ml-2 text-truncate font-weight-bold text-h6"
        v-if="pageTitle"
      >
        <span class="mr-1">/</span>{{ pageTitle }}
      </h2>

      <v-spacer />

      <!-- <v-btn text v-if="$route.path == '/'" class="mr-n2 pa-0 ml-2">
        <drag-file btn />
      </v-btn> -->

    </v-app-bar>

    <v-navigation-drawer
      v-model="showNavMenu"
      app
      left
      temporary
      :class="{
        'mt-ondesktop': client_insetTitleBar,
      }"
    >
      <right-menu @close="showNavMenu = false" />
    </v-navigation-drawer>

  </div>
</template>

<script>
import ImageLogo from "../assets/favicon.png";
// import DragFile from "./DragFile.vue";
import RightMenu from "../views/user/RightMenu.vue";

export default {
  components: {
    // DragFile,
    RightMenu,
  },
  computed: {
    pageTitle () {
      return this.vuex_metaPageTitle || this.$route.meta.pageTitle
    },
    shortKeyTip () {
      let platform = this.client_info && this.client_info.platform
      if (platform == 'darwin') return '唤起窗口[⌘ + `][⌘+⇧+S]'
      if (platform == 'win32') return '唤起窗口[Ctrl+`][Ctrl+Shift+S]'
      return ''
    }
  },
  data() {
    return {
      showNavMenu: false,
      imageLogo: ImageLogo,
    };
  },
  methods: {
    tapHome () {
      if (this.$route.path !== '/') this.$router.replace('/')
      this.$fs.vuex('vuex_clipsBoardToLocal', Date.now())
      this.$vuetify.goTo(0)
    },
  },
};
</script>

