import Utils from './utils'
import $fs from "@/store/fast";
import Promise from 'bluebird'
import { CONTENT_TP } from "../../constants";
import { logError, logSuccess } from "../../clip/common";
Promise.config({warnings: false})


function runsFlow (flow, inputs) {
    return new Promise((resovle)=>{
        console.log('start runs flow: ', flow, inputs)
        $fs.vuex('vuex_runFlowsCountLeft', inputs.length)
        let globalConst = {}
        Promise.each(inputs, (x)=>{
            return runFlow(flow, x, globalConst).catch(err=>{
                logError(flow, err)
            })
        }).then(()=>{
            // logSuccess(flow, inputs)
            return resovle()
        })
    })
}

function runFlow (flow, input, globalConst, originInput, parentFlowIds) {
    return new Promise((resovle, reject)=>{
        let actions = flow.actions || []
        // parentFlowIds, 父子关系链中不能出现2次相同的，放置死循环。
        parentFlowIds = parentFlowIds || []
        if (parentFlowIds.indexOf(flow.id) > -1) return reject({
            actionIndex: -1,
            error: `同一工作流不能在子工作流中多次出现，否则会造成死循环。多次出现的工作流：${flow.name}。`,
            input: input,
            flow: flow,
        })
        parentFlowIds.push(flow.id)
        // originInput，用于运行子工作流时，仍然可以对应到最初的项目。
        // 如果不是子工作流，如果剩余的数字为0，表示强行停止
        if (!originInput) {
            let countLeft = $fs.state.vuex_runFlowsCountLeft
            if (countLeft <= 0) {
                $fs.vuex('vuex_runFlowsCountLeft', 0)
                return reject({
                    actionIndex: -1,
                    error: 'You stop workflows manually.',
                    input: input,
                    flow: flow,
                })
            }
        }
        // 检查validInput
        try {
            validInput(input, {
                inputTps: flow.inputTps,
                userLevel: flow.userLevel,
            })
        } catch (validErr) {
            if (!originInput) {
                let count = $fs.state.vuex_runFlowsCountLeft
                $fs.vuex('vuex_runFlowsCountLeft', count - 1)
            }
            return reject({
                actionIndex: -1,
                error: validErr,
                input: input,
                flow: flow,
            })
        }
        // 有最短执行时间
        let minTs = Date.now() + 1000
        // 输入时只输入RAW
        let output = JSON.parse(JSON.stringify(input))
        // 当前执行的action的序号
        let index = 0
        Promise.mapSeries(actions, (x, idx)=>{
            index = idx
            return runAction(x, output, globalConst, originInput || input, parentFlowIds, idx).then(res=>{
                output = res
                console.log('output: ', output)
            }).catch(err=>{
                throw err
            })
        }).then(()=>{
            setTimeout(() => {
                console.log('success run flow output: ', output, flow, input)
                if (!originInput) {
                    let count = $fs.state.vuex_runFlowsCountLeft
                    $fs.vuex('vuex_runFlowsCountLeft', count - 1)
                }
                return resovle(output)
            }, Math.max(minTs - Date.now(), 0));
        }).catch(err=>{
            console.log('err run flow: ', err)
            let aid = actions[index].aid
            let erraction = Utils.actionOn(aid)
            setTimeout(() => {
                if (!originInput) {
                    let count = $fs.state.vuex_runFlowsCountLeft
                    $fs.vuex('vuex_runFlowsCountLeft', count - 1)
                }
                // 如果是skipFlow，则正常结束
                if (aid === 'skipFlow') return resovle(output)
                return reject({
                    actionIndex: index,
                    action: erraction,
                    error: err,
                    input: input,
                    flow: flow
                })
            }, Math.max(minTs - Date.now(), 0));
        })
    })
}

function runAction (info, input, globalConst, targetClip, parentFlowIds, indexInFlow) {
    return new Promise((resovle, reject)=>{
        let model = Utils.actionOn(info.aid)
        console.log('action id: ', info.aid)
        validInput(input, {
            inputTps: model.inputTps,
            userLevel: model.userLevel,
        })
        model.run({
            info: JSON.parse(JSON.stringify(info)),
            input: JSON.parse(JSON.stringify(input)),
            gc: globalConst,
            originInput: JSON.parse(JSON.stringify(targetClip)),
            parentFlowIds: parentFlowIds,
            indexInFlow,
        }).then(output=>{
            return resovle(output)
        }).catch(err=>{
            return reject(err)
        })
    })
}

// 验证输入的内容，抛出错误
function validInput (input, {inputTps, userLevel}) {
    // 用户等级
    let level = userLevel
    if (level && level > $fs.getters.vuex_userLevel) {
        if (level === 1) throw '此动作需要登录。'
        if (level === 10) throw '仅会员可运行此动作。'
        throw '仅相关会员可以使用此动作。'
    }
    // 输入类型
    let ctp = input.contentTp || ''
    inputTps = inputTps || []
    if (inputTps.length > 0) {
        let raw = input.raw
        try {
            if (!ctp) {
                if (Array.isArray(raw)) ctp = CONTENT_TP.ARRAY
                else if (Object.prototype.toString.call(raw) === '[object Object]') ctp = CONTENT_TP.OBJECT
                else if (raw.indexOf('data:image/') === 0) ctp = CONTENT_TP.IMAGE
                else ctp = CONTENT_TP.TEXT
            }
            if (inputTps.indexOf(ctp) == -1) throw 'error'
        } catch (error) {
            // todo 把它格式化的错误内容
            let validstrs = inputTps.join(', ')
            let validctp = ctp || CONTENT_TP.TEXT
            throw `错误的输入类型：${validctp}。允许的输入类型：${validstrs}。`
        }
    }
}

export {
    runAction,
    runFlow,
    runsFlow,
}