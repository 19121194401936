let Base = require('./common/base')
import FlowHelper from "../../views/flow/helper";


class RunEval extends Base.Action {
    constructor () {
        super(
            'runEval',
            '自定义JavaScript脚本',
            '执行自定义的JS脚本',
        )
        this.descHtml = `· 可以通过变量名input访问输入内容，object格式为：
        {
            contentTp: text | html | image | object | array
            raw: 原始内容（图片类型数据为base64编码，data:image/png;base64,XXX...）
            text: 纯文本的内容，特殊类型时可为空。
        }
        · 请保证输出内容与上方输入内容格式相同。
        · 可以通过变量名globalVars访问和操作所有已保存的变量。
        · 由于直接运行脚本有隐私风险，非自己创建或者官方认证用户创建的脚本，首次运行时会出现风险提示。<b>备注内容将出现在风险提示中，请在其中说明用途。</b>
        `
        this.args = [{
            key: 'jsEvalCode',
            required: true,
            name: 'JavaScript code',
            tp: 'textarea'
        }]
        this.userLevel = Base.USER_LEVEL.LOGIN
    }
    async run({info, input, gc, parentFlowIds}) {
        await FlowHelper.dangerCheck(parentFlowIds.slice(-1)[0])
        console.log('run eval: ', info, input, parentFlowIds)
        let code = info.args.jsEvalCode
        let evalInput = JSON.parse(JSON.stringify({
            raw: input.raw,
            text: input.text,
            contentTp: input.contentTp || Base.CONTENT_TP.TEXT,
        }))
        let evalGc = JSON.parse(JSON.stringify(gc))
        let func = new Function('input, globalVars', code)
        let res = func(evalInput, evalGc)
        if (!res || !res.raw) throw '脚本的返回值错误。请进入编辑页面查看返回值格式。'
        return {
            contentTp: res.contentTp || Base.CONTENT_TP.TEXT,
            raw: res.raw,
            text: res.text || ''
        }
    }
}

module.exports = new RunEval()