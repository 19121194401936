import $http from '../../utils/http'
import $fs from '../../store/fast'
import { Clip, readBase64ToBlob } from './base'
import { CONTENT_TP } from '../constants'
import axios from "axios";

// 查看是否有更新
async function prevClips (latest) {
    let res = await $http.cloudClipPrevs({
        id: latest && latest.id || '',
    }, {
        loading: -1,
    })
    return res.data.items.map(x=> new Clip(x))
}

// 翻页
async function nextClips (last) {
    let res = await $http.cloudClipNexts({
        last_ts: last && last.updatedTs || 0
    }, {
        loading: -1,
    })
    return res.data.items.map(x=> new Clip(x))
}

function uploadClip (clip) {
    let cid = clip.id
    let uploaded = $fs.state.vuex_clipsUploaded.indexOf(cid) > -1
    let uploading = $fs.state.vuex_clipsUploading.indexOf(cid) > -1
    // todo 上传成功或者错误后给出提示
    if (uploaded || uploading) return
    // 移入loading
    let v = $fs.state.vuex_clipsUploading
    v.push(cid)
    $fs.vuex('vuex_clipsUploading', v)
    // 上传
    doUploadClip(clip).then(res=>{
        // 上传成功，移入loaded
        let vd = $fs.state.vuex_clipsUploaded
        vd.push(cid)
        $fs.vuex('vuex_clipsUploaded', vd)
        // $fs.toast('已成功云同步')
    }).catch(err=>{
        $fs.toast(err, {color: 'error'})
    }).finally(()=>{
        // 移出loading
        let vi = $fs.state.vuex_clipsUploading
        let vidx = vi.indexOf(cid)
        if (vidx > -1) {
            vi.splice(vidx, 1)
            $fs.vuex('vuex_clipsUploading', vi)
        }
    })
}

// 
async function doUploadClip (clip) {
    // 检查是否登录
    if (!$fs.getters.vuex_login) throw '请先登录'
    // 检查clip是否可以上传
    if (clip.contentTp && [
        CONTENT_TP.TEXT,
        CONTENT_TP.IMAGE,
        CONTENT_TP.HTML,
    ].indexOf(clip.contentTp) == -1) throw '此类型的内容无法云同步。'
    // 转换
    let c = JSON.parse(JSON.stringify(clip))
    // 上传后获取链接 todo
    if (c.contentTp == CONTENT_TP.IMAGE) {
        c.url = await uploadFileToQiniu(c.raw, c.text)
        delete c.raw
    }
    delete c.data
    let res = await $http.cloudClipAdd({
        clip: c
    }, {
        loading: -1,
    })
}

async function uploadFileToQiniu (raw, text) {
    let blob = await readBase64ToBlob(raw)
    if (blob.size > 4200000) throw '文件大小需要小于4M，请先压缩。'
    let tokenRes = await $http.cloudClipUploadToken()
    let qiniuBaseUrl = tokenRes.data.uploadUrl
    let uploadToken = tokenRes.data.uploadToken
    let domain = tokenRes.data.domain
    // console.log('tokenRes: ', tokenRes)
    let http = axios.create({timeout: 30 * 1000})
    let fd = new FormData()
    fd.append('token', uploadToken)
    fd.append('file', blob, text || '')
    // console.log('formData', uploadToken)
    try {
        let uploadRes = await http.post(qiniuBaseUrl, fd, {
            headers: { 'Content-Type': 'multipart/form-data;' }
        })
        let dres = uploadRes.data
        let downloadUrl = domain + dres.key
        // console.log('uploadRes: ', uploadRes, downloadUrl)
        return downloadUrl
    } catch (error) {
        throw error.response.data.error
    }
}

async function deleteClip (clip) {
    let res = await $http.cloudClipDelete({
        id: clip.id,
    }, {
        loading: 2
    })
}

async function queryClips ({query, last}) {
    if (query.length < 2) return []
    let res = await $http.cloudClipSearch({
        query,
        last_ts: last && last.updatedTs || last.updated_at_ts || 0,
    }, {
        loading: -1,
    })
    return res.data.items.map(x=>new Clip(x))
}

async function changeDesc (clip, desc) {
    let res = await $http.cloudClipEdit({
        id: clip.id,
        desc: desc
    }, {
        loading: 2,
    })
}

export default {
    prevClips,
    nextClips,
    uploadClip,
    deleteClip,
    queryClips,
    changeDesc,
}