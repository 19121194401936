import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import fstore from "./store/fast.js";
import storeMixin from "./mixins/store";
import vuetify from './plugins/vuetify'
import VueHotKey from 'v-hotkey'

Vue.use(VueHotKey, {
  'upArrow': 38,
  'downArrow': 40,
  'leftArrow': 37,
  'rightArrow': 39,
  'backSpace': 8,
  'delete': 46,
})

Vue.config.productionTip = false

Vue.mixin(storeMixin)

Vue.prototype.$fs = fstore

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
