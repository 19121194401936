import { Base64 } from "js-base64";
import { CONTENT_TP } from "../constants";
let Base = require('./common/base')

class Base64Decode extends Base.Action {
    constructor () {
        super(
            'base64Decode',
            'Base解码',
            '将Base64编码的内容解码'
        )
        this.inputTps = [CONTENT_TP.TEXT]
        this.outputTps = [CONTENT_TP.TEXT]
    }
    async run({input}) {
        let res = Base64.decode(input.raw)
        return {
            contentTp: CONTENT_TP.TEXT,
            raw: res,
            text: res,
        }
    }
}

module.exports = new Base64Decode()