let Base = require('./common/base')
let Utils = require('./common/utils')
import { addNote } from "../clip/common";

class WriteNoteText extends Base.Action {
    constructor () {
        super(
            'writeNoteText',
            '输出文字笔记',
            '将你想要的内容输出为文字笔记。'
        )
        this.inputTps = [Base.CONTENT_TP.TEXT, Base.CONTENT_TP.HTML, Base.CONTENT_TP.ARRAY]
        this.outputTps = [Base.CONTENT_TP.ORIGIN]
        this.tp = Base.TP.NOTE
    }
    async run ({input, originInput}) {
        let arr = Utils.inputToArray(input)
        for (const x of arr) runOne(x, originInput)
        return input
    }
}

function runOne(input, originInput) {
    let raw = input.text || input.raw || ''
    if (typeof(raw) !== 'string') raw = JSON.stringify(raw)
    addNote({
        targetClip: originInput,
        raw: raw,
        contentTp: Base.CONTENT_TP.TEXT,
        text: raw,
    })
}

module.exports = new WriteNoteText()